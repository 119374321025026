import React from 'react';
import { number, node, bool, string, object } from 'prop-types';
import { Card, Spin } from 'antd';
import ErrorFiller from './ErrorFiller';

const WidgetCard = ({ title, children, extra, actions, isLoading, httpStatus, style, bodyStyle }) => {
  return (
    <Card
      data-cy={'widgetcard-' + title.toLowerCase().replace(/ /g, '-')}
      title={<span className="typography-h700">{title}</span>}
      style={{ marginTop: '15px', marginBottom: '15px', ...style }}
      bodyStyle={bodyStyle}
      extra={extra}
      actions={actions}>
      <Content isLoading={isLoading} httpStatus={httpStatus}>
        {children}
      </Content>
    </Card>
  );
};

const Content = ({ isLoading, httpStatus, children }) => {
  let status = [];

  if (httpStatus && httpStatus.length > 1) {
    status = httpStatus.filter((status) => status > 399);
  } else if (httpStatus && httpStatus > 399) {
    status = [httpStatus];
  }

  if (status.length >= 1) {
    return <ErrorFiller httpStatusCode={status} />;
  }

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return <div>{children}</div>;
};

Content.propTypes = {
  httpStatus: number,
  children: node,
  isLoading: bool,
};

Content.defaultProps = {
  httpStatus: 0,
  children: <></>,
  isLoading: false,
};

WidgetCard.propTypes = {
  title: string,
  children: node,
  extra: node,
  isLoading: bool,
  httpStatus: number,
  style: object,
  bodyStyle: object,
  actions: object,
};

WidgetCard.defaultProps = {
  title: '',
  children: <></>,
  extra: <></>,
  isLoading: false,
  httpStatus: 0,
  style: {},
  bodyStyle: {},
};

export default WidgetCard;
