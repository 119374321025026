import {
  ROUTE_ROOT,
  ROUTE_CUSTOMERS,
  ROUTE_CUSTOMER,
  ROUTE_NEW_CUSTOMER,
  ROUTE_EDIT_QUOTE,
  ROUTE_NEW_QUOTE,
  ROUTE_EDIT_CUSTOMER,
  ROUTE_VIEW_POLICY,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_CALLBACK,
  ROUTE_EDIT_OPPORTUNITY,
  ROUTE_NEW_OPPORTUNITY,
  ROUTE_CRM,
  ROUTE_PAYMENTS,
  ROUTE_CLAIMS,
  ROUTE_CUSTOM_WIDGET,
} from './routes';

export const ROLE_AGENT = 'ROLE_AGENT';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_ICP_ADMIN = 'ICP_ADMIN';
export const ROLE_ANONYMOUS = 'ANONYMOUS';

const ROLE_ACCESS_RIGHTS = {
  [ROLE_AGENT]: [
    ROUTE_ROOT,
    ROUTE_CLAIMS,
    ROUTE_CUSTOMERS,
    ROUTE_CUSTOMER,
    ROUTE_NEW_CUSTOMER,
    ROUTE_EDIT_QUOTE,
    ROUTE_NEW_QUOTE,
    ROUTE_NEW_OPPORTUNITY,
    ROUTE_EDIT_CUSTOMER,
    ROUTE_VIEW_POLICY,
    ROUTE_EDIT_OPPORTUNITY,
    ROUTE_CRM,
    ROUTE_PAYMENTS,
    ROUTE_CUSTOM_WIDGET,
  ],
  [ROLE_ADMIN]: [
    ROUTE_ROOT,
    ROUTE_CLAIMS,
    ROUTE_CUSTOMERS,
    ROUTE_CUSTOMER,
    ROUTE_NEW_CUSTOMER,
    ROUTE_EDIT_QUOTE,
    ROUTE_EDIT_OPPORTUNITY,
    ROUTE_NEW_QUOTE,
    ROUTE_NEW_OPPORTUNITY,
    ROUTE_EDIT_CUSTOMER,
    ROUTE_VIEW_POLICY,
    ROUTE_CRM,
    ROUTE_PAYMENTS,
    ROUTE_CUSTOM_WIDGET,

  ],
  [ROLE_ANONYMOUS]: [ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_CALLBACK],
};

export default ROLE_ACCESS_RIGHTS;
