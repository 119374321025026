import React, { createRef, useState, useEffect } from 'react';
import { useHistory, useParams, useLocation, generatePath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout, Row, Col } from 'antd';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { customerDetailsPath } from '../../router/paths';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { AgentSalesFlowWidget } from '../../components/AgentSalesFlowWidget/AgentSalesFlowWidget';
import getSearchParams from '../../utils/getSearchParams';
import { Documents } from '../../widgets/DocumentsWidget/Documents.widget';
import { SearchMode } from '@tia/agent-api-connector/dist/enums/SearchMode';
import { PATH_CUSTOMER } from '../../router/paths';

const EditOpportunity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'portal',
    'tia-agent-sales-flow-widget:widget',
    'tia-opportunity-basket-widget:widget',
  ]);
  const { push } = useHistory();
  const { search } = useLocation();
  const { customerid, opportunityid } = useParams();
  const query = new URLSearchParams(search);
  const mode = query.get('mode');
  const agentSalesOpportunityBasketWidget = createRef();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCustomWidget, setShowCustomWidget] = useState(false);
  const [searchQueryParams] = useState(getSearchParams());

  useEffect(() => {
    dispatch(setRecentlyViewedCustomer(customerid));

    agentSalesOpportunityBasketWidget.current.addEventListener('confirm-offer', (e) => {
      if (e.detail.status === 'successful') {
        push(customerDetailsPath(customerid));
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.messageConfirmOfferSuccess'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.messageConfirmOfferError'),
        });
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('duplicate-basket', (e) => {
      if (e.detail.status === 'successful') {
        push(customerDetailsPath(customerid));
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.messageDuplicationSuccesful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.messageDuplicationError'),
        });
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('cancel-basket', (e) => {
      if (e.detail.status === 'successful') {
        push(customerDetailsPath(customerid));
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.messageCancelSuccessful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.messageCancelError'),
        });
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('set-in-force-policy', (e) => {
      if (e.detail.status === 'successful') {
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.setInForceSuccesful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.setInForceFailed'),
        });
      }
    });
    agentSalesOpportunityBasketWidget.current.addEventListener('set-in-force-opportunity', (e) => {
      if (e.detail.status === 'successful') {
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.setInForceSuccesful'),
        });
        push(generatePath(PATH_CUSTOMER, { customerid }));
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.setInForceFailed'),
        });
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('success-widget-action', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('widget-error', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });

    agentSalesOpportunityBasketWidget.current.addEventListener('widget-message', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });
  }, [opportunityid, customerid]);

  const handleAddPaymentDetails = () => setShowPaymentModal(true);

  const handleShowCustomWidget = () => setShowCustomWidget(true);

  const closePaymentModal = () => {
    setShowPaymentModal(false);
    setShowCustomWidget(false);
  };

  const widgetLocalizationSalesFlow = JSON.stringify(t('tia-agent-sales-flow-widget:widget', { returnObjects: true }));

  const widgetLocalizationOpportunityBasket = JSON.stringify(
    t('tia-opportunity-basket-widget:widget', { returnObjects: true })
  );
  const basketDisablePrint = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.print);
  const basketDisablePrintAgent = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.printAgent);
  const basketDisablePrintAgentFirm = getFeatureFlagsConfiguration(
    featureFlags.customer.editOpportunity.basket.printAgentFirm
  );
  const basketDisablePrintCustomer = getFeatureFlagsConfiguration(
    featureFlags.customer.editOpportunity.basket.printCustomer
  );

  const basketDisableSetInForce = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.setInForce);

  const disableBundleDiscount = getFeatureFlagsConfiguration(
    featureFlags.customer.editOpportunity.basket.bundleDiscount
  );

  const disableSetBasketInForce = getFeatureFlagsConfiguration(
    featureFlags.customer.editOpportunity.basket.setBasketInForce
  );

  const disableConfirmOffer = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.confirmOffer);

  const disableDuplicateBasket = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.duplicate);

  const disableCancelBasket = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.cancel);

  const documentFeature = getFeatureFlagsConfiguration('customer.documentsWidget.basket.edit');

  const enableClauseSearch = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.searchClauses);
  const enableRiskToggleOnLeft = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.riskToggleOnLeft);
  const enableDatepicker = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.datepicker);

  return (
    <Layout.Content>
      <Row gutter={40} style={{ marginTop: '10px' }}>
        <Col xs={24} lg={8}>
          <FeatureFlag featureFlag={featureFlags.customer.editOpportunity.opportunityBasket}>
            <tia-opportunity-basket-widget
              style={{
                margin: '0 0 10px 0',
                display: 'block',
              }}
              opportunityId={opportunityid}
              ref={agentSalesOpportunityBasketWidget}
              localization={widgetLocalizationOpportunityBasket}
              partyid={customerid}
              confirmOfferButton={!disableConfirmOffer}
              isSelectable
              disablePrintSelection={!basketDisablePrint}
              disablePrintAgentSelection={!basketDisablePrintAgent}
              disablePrintAgentFirmSelection={!basketDisablePrintAgentFirm}
              disablePrintCustomerSelection={!basketDisablePrintCustomer}
              disableSetInForceAction={!basketDisableSetInForce}
              disableBundleDiscount={!disableBundleDiscount}
              disableSetBasketInForce={!disableSetBasketInForce}
              disableDuplicateBasket={!disableDuplicateBasket}
              disableCancelBasket={!disableCancelBasket}
            />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.editOpportunity.customerDetail}>
            <CustomerDetail addPaymentDetails={handleAddPaymentDetails} />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.editOpportunity.createPaymentDetail}>
            <CreatePaymentDetail
              partyId={customerid}
              showPaymentModal={showPaymentModal}
              showCustomWidget={showCustomWidget}
              handleShowCustomWidget={handleShowCustomWidget}
              closePaymentModal={closePaymentModal}
            />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.documentsWidget.basket.edit.widget}>
            <Documents
              config={getFeatureFlagsConfiguration('customer.documentsWidget.basket.edit')}
              partyId={customerid}
              opportunityId={opportunityid}
              searchMode={SearchMode.Opportunity}
              featureFlag={documentFeature}
            />
          </FeatureFlag>
        </Col>
        <Col xs={24} lg={16}>
          <div className="agent-sales-flow-widget">
            <FeatureFlag featureFlag={featureFlags.customer.editOpportunity.agentSalesFlowWidget}>
              <AgentSalesFlowWidget
                queryParameters={JSON.stringify(searchQueryParams)}
                partyId={customerid}
                localization={widgetLocalizationSalesFlow}
                mode={mode || ''}
                enableClauseSearch={enableClauseSearch}
                enableRiskToggleOnLeft={enableRiskToggleOnLeft}
                enableDatepicker={enableDatepicker}
              />
            </FeatureFlag>
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
};

export default EditOpportunity;
