"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPartyUsingGET = exports.getPartiesUsingGET = void 0;
var API_URL = 'v1/parties';
var getPartiesUsingGET = function (params) {
    return {
        url: "".concat(API_URL, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getPartiesUsingGET = getPartiesUsingGET;
var getPartyUsingGET = function (partyId) {
    return {
        url: "".concat(API_URL, "/").concat(partyId),
        method: 'GET',
    };
};
exports.getPartyUsingGET = getPartyUsingGET;
