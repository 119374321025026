import { modalAtom, modalDataAtom } from '../components/Modal/atoms';

import { useAtom } from 'jotai';
import { useCallback } from 'react';

export const useModal = (modalName: string) => {
  const [activeModal, setActiveModal] = useAtom(modalAtom);
  const [modalData, setModalData] = useAtom(modalDataAtom);

  const openModal = useCallback(
    (data: null | string | boolean | number | object = null) => {
      setActiveModal(modalName);
      setModalData(data);
    },
    []
  );
  const closeModal = useCallback(() => {
    setActiveModal(null);
    setModalData(null);
  }, []);

  const data = modalName === activeModal ? modalData : undefined;

  return { isOpen: activeModal === modalName, data, openModal, closeModal };
};
