"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var Party_1 = require("../api/Party");
var UrlParam_1 = require("../helpers/UrlParam");
var PartyConnector = /** @class */ (function () {
    function PartyConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Get a list of parties for a specific agent
     * @param partyId
     */
    PartyConnector.prototype.getParties = function (agentCustomers, params) {
        if (agentCustomers) {
            (0, UrlParam_1.appendParam)(params, 'agentCustomers', 'true');
        }
        return (0, fetchRequest_1.fetchRequestWithPagination)((0, Party_1.getPartiesUsingGET)(params), this.baseUrl);
    };
    PartyConnector.prototype.getParty = function (partyId) {
        return (0, fetchRequest_1.fetchRequestWithPagination)((0, Party_1.getPartyUsingGET)(partyId), this.baseUrl);
    };
    return PartyConnector;
}());
exports.default = PartyConnector;
