import { createSelector } from 'reselect';
import { getPartiesEntities } from '../../api/parties/selectors';
import { EMPTY_ARRAY } from '../../emptyState';

const getPartyIds = (state) => state.pages.customers.partyIds;

const getRecentlyViewedCustomers = (state) => state.pages.customers.recentlyViewedCustomers;

export const getPartiesPagination = (state) => state.pages.customers.pagination;

export const getPartiesSearchQuery = (state) => state.pages.customers.searchedValue;

export const getPartiesSelector = createSelector(
  getPartyIds,
  getPartiesEntities,
  (partyIds = EMPTY_ARRAY, entities = EMPTY_ARRAY) => partyIds.map((partyId) => entities[partyId])
);

export const getRecentlyViewedCustomerList = createSelector(
  getRecentlyViewedCustomers,
  getPartiesEntities,
  (customers = EMPTY_ARRAY, entities = EMPTY_ARRAY) =>
    customers.reduce((acc, customer) => {
      if (entities[customer.id]) {
        acc.push({
          ...customer,
          name: entities[customer.id].name,
        });
      }

      return acc;
    }, [])
);
