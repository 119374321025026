import React, { createRef, useEffect } from 'react';
import { agentSalesFlowToastMessageQuotes } from '../../utils/toastMessages';
import { openNotification, showMultipleToasts } from '../../utils/apiSetup';
import showToastMessage from '../../utils/showToastMessage';
import { useTranslation } from 'react-i18next';
import { customerDetailsPath, editQuotePath } from '../../router/paths';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const AgentSalesFlowWidget = ({
  policyUuid,
  partyId,
  localization,
  mode = '',
  setPolicyStatus,
  queryParameters,
  disableProductSelection,
  enableClauseSearch,
  enableRiskToggleOnLeft,
  enableDatepicker,
  history,
  match,
  policyUuidVersionDate,
  featureFlags,
}) => {
  const widgetRef = createRef();
  const { t } = useTranslation();
  const routerHistory = useHistory();

    useEffect(() => {
    const handleOnSuccessAction = (event) => {
      const toastMessage = agentSalesFlowToastMessageQuotes(event, t);
      const flowMode = queryParameters ? JSON.parse(queryParameters)?.mode : null;

      if (flowMode && flowMode === 'setinforce') {
        history.push(customerDetailsPath(match.params.customerid));
      }

      if (toastMessage) {
        showToastMessage({ message: toastMessage.messageText, type: toastMessage.messageType });
      }

      if (event.detail.payload?.message) {
        event.detail.payload.message.forEach((message) => openNotification({ title: message.title, message: message.message, severityLevel: message.severityLevel, messageCode: message.messageCode }));
      }

      if (event.detail.payload?.messages) {
        event.detail.payload.messages.forEach((messages) => openNotification({ title: messages.title, message: messages.message, severityLevel: messages.severityLevel, messageCode: messages.messageCode }));
      }
    };

    const handleMessage = (event) => showMultipleToasts(event.detail);

    const handleError = (event) => {
        const toastMessage = agentSalesFlowToastMessageQuotes(event, t);

        if (toastMessage) {
            showToastMessage({ message: toastMessage.messageText, type: toastMessage.messageType });
        }

        if (event.detail?.length) {
            showMultipleToasts(event.detail)
        }

        if (event.detail?.payload?.messages?.length) {
            showMultipleToasts(event.detail.payload.messages)
        }

        if (event.detail?.payload?.errors?.length) {
            showMultipleToasts(event.detail.payload.errors)
        }
    };

    const handlePolicyLoad = (event) => {
      if (setPolicyStatus) {
        try {
          setPolicyStatus(event.detail.salesPolicy.salesProductHeader.policyStatus);
        } catch (exception) {
          console.error('Policy load failed');
        }
      }
    };

    const redirectToEditQuote = () => {
      routerHistory.push(editQuotePath(partyId, policyUuid))
    }

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('success-widget-action', handleOnSuccessAction);
      widgetRef.current.addEventListener('widget-message', handleMessage);
      widgetRef.current.addEventListener('widget-error', handleError);
      widgetRef.current.addEventListener('widget-policy-load', handlePolicyLoad);
      widgetRef.current.addEventListener('widget-policy-edit', redirectToEditQuote);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('success-widget-action', handleOnSuccessAction);
        widgetRef.current.removeEventListener('widget-message', handleMessage);
        widgetRef.current.removeEventListener('widget-error', handleError);
        widgetRef.current.removeEventListener('widget-policy-load', handlePolicyLoad);
        widgetRef.current.removeEventListener('widget-policy-edit', redirectToEditQuote);
      }
    };
  }, [t, policyUuidVersionDate]);

return (
    <tia-agent-sales-flow-widget
      policyUuid={policyUuid}
      partyId={partyId}
      ref={widgetRef}
      mode={mode}
      localization={localization}
      queryParameters={queryParameters}
      disableProductSelection={disableProductSelection}
      enableClauseSearch={enableClauseSearch}
      enableRiskToggleOnLeft={enableRiskToggleOnLeft}
      enableDatepicker={enableDatepicker}
      policyUuidVersionDate={policyUuidVersionDate && policyUuidVersionDate.split('T')[0]}
      featureFlags={featureFlags}
    />
  );
};

AgentSalesFlowWidget.propTypes = {
  policyUuid: PropTypes.string,
  partyId: PropTypes.string,
  localization: PropTypes.string,
  mode: PropTypes.string,
  setPolicyStatus: PropTypes.func,
  queryParameters: PropTypes.string,
  disableProductSelection: PropTypes.bool,
  enableClauseSearch: PropTypes.bool,
  enableRiskToggleOnLeft: PropTypes.bool,
  enableDatepicker: PropTypes.bool,
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  match: PropTypes.object,
  policyUuidVersionDate: PropTypes.string,
  featureFlags: PropTypes.object,
  changeTimelineAfterUpdate: PropTypes.func,
};
