import { getEntities } from '../../entities/selectors';
import { POLICIES_ENTITIES, CALCULATED_PRICE_ENTITIES, QUOTES_ENTITIES } from './consts';

/**
 * Get all policies entities saved in entities
 */
export const getPoliciesEntities = (state) => getEntities(state, POLICIES_ENTITIES);

/**
 * Get all calculated product entities
 */
export const getCalculatedProductsEntities = (state) => getEntities(state, CALCULATED_PRICE_ENTITIES);

/**
 * Get all quotes entities
 */
export const getQuotesEntities = (state) => getEntities(state, QUOTES_ENTITIES);
