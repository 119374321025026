import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Col, Layout, Row } from 'antd';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import Policies from '../../widgets/Policies/Policies.widget';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import getSearchParams from '../../utils/getSearchParams';
import { AgentSalesFlowWidget } from '../../components/AgentSalesFlowWidget/AgentSalesFlowWidget';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';

class CreateQuote extends PureComponent {
  state = {
    toastMessageVisibility: false,
    toastMessageText: '',
    toastMessageType: '',
    searchQueryParams: {},
    showPaymentModal: false,
    showCustomWidget: false,
  };

  constructor(props) {
    super(props);
    this.fetchAssets();
  }

  componentDidMount() {
    const searchQueryParams = getSearchParams();

    this.setState({ searchQueryParams });
  }

  handleAddPaymentDetails = () => {
    this.setState({
      showPaymentModal: true,
    });
  };

  handleShowCustomWidget = () => {
    this.setState({
      showCustomWidget: true,
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      showCustomWidget: false,
    });
  };

  async fetchAssets() {
    const { match, setRecentlyViewedCustomer } = this.props;
    const { customerid } = match.params;

    setRecentlyViewedCustomer(customerid);
  }

  render() {
    const { t, match } = this.props;
    const { customerid } = match.params;

    const widgetLocalizationSalesFlow = JSON.stringify(
      t('tia-agent-sales-flow-widget-quote-view:widget', { returnObjects: true })
    );

    const enableClauseSearch = getFeatureFlagsConfiguration(featureFlags.customer.createQuote.searchClauses);
    const enableRiskToggleOnLeft = getFeatureFlagsConfiguration(featureFlags.customer.createQuote.riskToggleOnLeft);
    const enableDatepicker = getFeatureFlagsConfiguration(featureFlags.customer.createQuote.datepicker);

    return (
      <Layout.Content>
        <Row gutter={40}>
          <Col lg={8}>
            <div style={{ margin: '0 0 10px 0' }}>
              <FeatureFlag featureFlag={featureFlags.customer.createQuote.customerDetail}>
                <CustomerDetail addPaymentDetails={this.handleAddPaymentDetails} />
              </FeatureFlag>
              <FeatureFlag featureFlag={featureFlags.customer.createQuote.createPaymentDetail}>
                <CreatePaymentDetail
                  partyId={customerid}
                  showPaymentModal={this.state.showPaymentModal}
                  showCustomWidget={this.state.showCustomWidget}
                  handleShowCustomWidget={this.handleShowCustomWidget}
                  closePaymentModal={this.closePaymentModal}
                />
              </FeatureFlag>
            </div>
            <FeatureFlag featureFlag={featureFlags.customer.createQuote.policies}>
              <Policies id={customerid} view={false} cancel={false} />
            </FeatureFlag>
          </Col>
          <Col lg={16}>
            <tia-toast
              visible={this.state.toastMessageVisibility}
              message={this.state.toastMessageText}
              type={this.state.toastMessageType}
              closeable="true"
              actiontitle=""
              textonly="false"
            />
            <FeatureFlag featureFlag={featureFlags.customer.createQuote.agentSalesFlowWidget}>
              <AgentSalesFlowWidget
                partyId={customerid}
                queryParameters={JSON.stringify(this.state.searchQueryParams)}
                localization={widgetLocalizationSalesFlow}
                enableClauseSearch={enableClauseSearch}
                enableRiskToggleOnLeft={enableRiskToggleOnLeft}
                enableDatepicker={enableDatepicker}
              />
            </FeatureFlag>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

CreateQuote.propTypes = {
  setRecentlyViewedCustomer: func.isRequired,
  match: object,
  t: func,
};

const mapStateToProps = (state) => ({
  customerId: state.pages.customer.recentlyViewedCustomerId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecentlyViewedCustomer,
    },
    dispatch
  );

export default withTranslation(['portal', 'tia-agent-sales-flow-widget', 'tia-opportunity-basket-widget'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateQuote))
);
