import React from 'react';
import PropTypes from 'prop-types';
import './SpaceFiller.scss';

/**
 * @param { String } text
 * @param { elementType } Icon
 */
const SpaceFiller = ({ text, Icon }) => (
  <div className="space-filler" data-cy="space-filler">
    <Icon style={{ color: '#8db2bf', fontSize: '33px' }} />
    <div className="space-filler-description">{text}</div>
  </div>
);

SpaceFiller.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

export default SpaceFiller;
