import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { func, object } from 'prop-types';
import CustomUiWidget from '../../widgets/CustomUiWidget/CustomUiWidget.widget';
import { customerDetailsEditPath } from '../../router/paths';

class CustomerDetail extends PureComponent {
  render() {
    return (
      <CustomUiWidget
        key={this.props.match.params.customerid}
        configName="AgentPlatformCustomerDetails"
        partyId={this.props.match.params.customerid}
        addPaymentDetails={this.props.addPaymentDetails}
        handleEdit={() => {
          this.props.history.push(customerDetailsEditPath(this.props.match.params.customerid));
        }}
      />
    );
  }
}

CustomerDetail.propTypes = {
  addPaymentDetails: func.isRequired,
  match: object,
  history: object,
};

export default withRouter(CustomerDetail);
