import { combineReducers } from 'redux';
import { GET_PARTIES_REQUEST, GET_PARTIES_SUCCESS, GET_PARTIES_ERROR } from '../../actionTypes';

const isFetching = (state = false, action) => {
  const { type } = action;

  switch (type) {
    case GET_PARTIES_REQUEST:
      return true;

    case GET_PARTIES_SUCCESS:
      return false;

    case GET_PARTIES_ERROR:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
});
