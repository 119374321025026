import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import PropTypes from 'prop-types';

export const FeatureFlag = ({ children, featureFlag }) => {
  const value = getFeatureFlagsConfiguration(featureFlag);

  if (!value) {
    return null;
  }

  return children;
};

FeatureFlag.propTypes = {
  children: PropTypes.object,
  featureFlag: PropTypes.string,
};
