import React from 'react';
import { oneOfType, arrayOf, object, any } from 'prop-types';

import './CardCell.scss';

/**
 * @param { Object } Icon
 * @param { JSX } children
 */
const CardCell = ({ children, Icon }) => (
  <div className="CardCell" style={cardCellStyle}>
    {Icon && <Icon style={cardCellIconStyle} />}
    <div style={cardCellContentStyle}>{children}</div>
  </div>
);

CardCell.propTypes = {
  children: oneOfType([arrayOf(any), object]).isRequired,
  Icon: object,
};

const cardCellStyle = {
  padding: '10px 0 10px 0',
  display: 'flex',
  width: '100%',
  transition: 'all .3s',
};

const cardCellIconStyle = {
  fontSize: '16px',
  color: '#007A84',
  marginLeft: '12px',
  marginRight: '12px',
  marginTop: '10px',
};

const cardCellContentStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%',
};

export default CardCell;
