"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var Account_1 = require("../api/Account");
var AccountConnector = /** @class */ (function () {
    function AccountConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    AccountConnector.prototype.getPaymentMethods = function () {
        return (0, fetchRequest_1.fetchRequest)((0, Account_1.getPaymentMethodsUsingGET)(), this.baseUrl);
    };
    return AccountConnector;
}());
exports.default = AccountConnector;
