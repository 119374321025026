import { combineReducers } from 'redux';
import {
  GET_POLICIES_BY_PARTY_ID_REQUEST,
  GET_POLICIES_BY_PARTY_ID_SUCCESS,
  GET_POLICIES_BY_PARTY_ID_ERROR,
  GET_POLICY_BY_ID_REQUEST,
  GET_POLICY_BY_ID_SUCCESS,
  GET_POLICY_BY_ID_ERROR,
} from '../../actionTypes';

const isFetching = (state = false, action) => {
  const { type } = action;

  switch (type) {
    case GET_POLICIES_BY_PARTY_ID_REQUEST:
      return true;

    case GET_POLICY_BY_ID_REQUEST:
      return true;

    case GET_POLICIES_BY_PARTY_ID_SUCCESS:
      return false;

    case GET_POLICIES_BY_PARTY_ID_ERROR:
      return false;

    case GET_POLICY_BY_ID_SUCCESS:
      return false;

    case GET_POLICY_BY_ID_ERROR:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
});
