import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { apiConnector } from '../../utils/apiConnector';
import { customerDetailsPath } from '../../router/paths';
import CustomUiWidget from '../../widgets/CustomUiWidget/CustomUiWidget.widget';
import { openNotification } from '../../utils/apiSetup';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import PropTypes from 'prop-types';

const CreateCustomerDetail = (props) => {
  const handleCustomUiSubmit = async (event) => {
    const formValues = event.detail;
    const { t } = props;

    try {
      const resData = await apiConnector().uiConfiguration.updateEntities(
        'AgentPlatformCreateCustomerDetails',
        formValues
      );

      if (resData && !resData.data) {
        openNotification({
          message: t('widgets.newCustomer.messages.failure'),
          severityLevel: 'error',
          title: resData.error[0].errorCode + ' - ' + resData.error[0].message,
        });
        window.dispatchEvent(new CustomEvent('cui-post-submit'));

        return;
      }

      if (resData.message && resData.message.length > 0) {
        openNotification({ message: resData.message[0].message, severityLevel: 'warning' });
      } else {
        openNotification({ message: t('widgets.newCustomer.messages.success'), severityLevel: 'success', title: ' ' });
      }

      window.dispatchEvent(new CustomEvent('cui-post-submit'));

      return props.history.push(customerDetailsPath(resData.data.party.id));
    } catch (error) {
      console.error(error);
      window.dispatchEvent(new CustomEvent('cui-post-submit'));
      openNotification({ message: t('widgets.newCustomer.messages.error'), severityLevel: 'error' });
    }
  };

  return <CustomUiWidget configName="AgentPlatformCreateCustomerDetails" handleCustomUiSubmit={handleCustomUiSubmit} />;
};

CreateCustomerDetail.propTypes = {
  t: PropTypes.func,
  history: PropTypes.array,
};

export default withTranslation()(withRouter(CreateCustomerDetail));
