import React from 'react';
import { string, node } from 'prop-types';
import './authenticationMessage.scss';
import Background from './background.svg';

const AuthenticationMessage = ({ headline, description, children }) => (
  <div className="authentication-message__flex-container" style={{ ...flexContainer }}>
    <div className="authentication-message__container">
      <div style={{ width: '100%' }} className="authentication-message">
        <span className="authentication-message__headline">{headline}</span>
        <span className="authentication-message__description">{description}</span>
        <div className="authentication-message__children">{children}</div>
      </div>
    </div>
  </div>
);

AuthenticationMessage.defaultProps = {
  description: '',
  children: <></>,
};

AuthenticationMessage.propTypes = {
  headline: string,
  children: node,
  description: string,
};

const flexContainer = {
  height: '100%',
  backgroundImage: `url(${Background})`,
};

export default AuthenticationMessage;
