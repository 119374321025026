import React, { createRef, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, useHistory } from 'react-router-dom';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { editQuotePath, editOpportunityPath, viewPolicyPath, customerDetailsPath } from '../../router/paths';
import { getGlobalConfiguration } from '../../utils/getGlobalConfiguration';

export const Crm = () => {
  const widgetRef = createRef();
  const history = useHistory();
  const openInNewTab = getGlobalConfiguration('crmWidget.relatedOpenInNewTab');

  const handleFunction = (path, target) => {
    if (openInNewTab) {
      if (path.charAt(0) === '/') {
        path = path.substring(1);
      }

      window.open(path, target);
    } else {
      history.push(path);
    }
  };

  useEffect(() => {
    const handleQuoteClick = (event) => {
      const { partyId, value } = event.detail;

      handleFunction(editQuotePath(partyId, value), value);
    };

    const handleOpportunityClick = (event) => {
      const { partyId, value } = event.detail;

      handleFunction(editOpportunityPath(partyId, value), value);
    };

    const handlePolicyClick = (event) => {
      const { partyId, value } = event.detail;

      handleFunction(viewPolicyPath(partyId, value), value);
    };

    const handleCustomerClick = (event) => {
      const { partyId } = event.detail;

      handleFunction(customerDetailsPath(partyId), partyId);
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('ds-crm-widget-detail-quote-clicked', handleQuoteClick);
      widgetRef.current.addEventListener('ds-crm-widget-detail-basket-clicked', handleOpportunityClick);
      widgetRef.current.addEventListener('ds-crm-widget-detail-opportunity-clicked', handleOpportunityClick);
      widgetRef.current.addEventListener('ds-crm-widget-detail-policy-clicked', handlePolicyClick);
      widgetRef.current.addEventListener('ds-crm-widget-detail-customer-clicked', handleCustomerClick);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('ds-crm-widget-detail-quote-clicked', handleQuoteClick);
        widgetRef.current.removeEventListener('ds-crm-widget-detail-basket-clicked', handleOpportunityClick);
        widgetRef.current.removeEventListener('ds-crm-widget-detail-policy-clicked', handlePolicyClick);
        widgetRef.current.removeEventListener('ds-crm-widget-detail-opportunity-clicked', handleOpportunityClick);
        widgetRef.current.removeEventListener('ds-crm-widget-detail-customer-clicked', handleCustomerClick);
      }
    };
  }, [widgetRef]);

  return (
    <FeatureFlag featureFlag={featureFlags.crm.crmView.crmWidget}>
      <div style={{ backgroundColor: '#fff', padding: '1em' }}>
        <ds-crm-widget ref={widgetRef} />
      </div>
    </FeatureFlag>
  );
};

export default withTranslation()(withRouter(Crm));
