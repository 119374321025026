"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var Attachment_1 = require("../api/Attachment");
var UrlParam_1 = require("../helpers/UrlParam");
var AttachmentConnector = /** @class */ (function () {
    function AttachmentConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Get documents from a policy
     * @param sipasUuid
     * @param partyId
     * @param apiParams
     */
    AttachmentConnector.prototype.getAttachments = function (sipasUuid, partyId, opportunityId, searchMode, apiParams) {
        var params = new URLSearchParams();
        if (apiParams) {
            (0, UrlParam_1.appendDefaultParams)(params, apiParams);
        }
        (0, UrlParam_1.appendParam)(params, 'sipasUuid', sipasUuid);
        (0, UrlParam_1.appendParam)(params, 'partyId', partyId);
        (0, UrlParam_1.appendParam)(params, 'opportunityId', opportunityId);
        (0, UrlParam_1.appendParam)(params, 'searchMode', searchMode);
        return (0, fetchRequest_1.fetchRequestWithPagination)((0, Attachment_1.getAttachmentsUsingGET)(params), this.baseUrl);
    };
    /**
     * Get file from a document
     * @param attachmentId
     * @param attachmentSeqNo
     * @param apiParams
     */
    AttachmentConnector.prototype.getAttachment = function (attachmentId, attachmentSeqNo, apiParams) {
        var params = new URLSearchParams();
        if (apiParams) {
            (0, UrlParam_1.appendDefaultParams)(params, apiParams);
        }
        return (0, fetchRequest_1.fetchBinaryWithResponseWrapper)((0, Attachment_1.getAttachmentUsingGET)(attachmentId, attachmentSeqNo, params), this.baseUrl);
    };
    /**
     * Stores user selected attachment to the backend
     * @param partyId
     * @param data
     */
    AttachmentConnector.prototype.uploadAttachment = function (partyId, sipasUuid, opportunityId, data) {
        var params = new URLSearchParams();
        params.append('partyId', partyId);
        if (sipasUuid) {
            params.append('sipasUuid', sipasUuid);
        }
        if (opportunityId) {
            params.append('opportunityId', opportunityId);
        }
        var requestConfig = (0, Attachment_1.uploadAttachmentUsingPOST)(data, params);
        return (0, fetchRequest_1.fetchRequestBase)(requestConfig, this.baseUrl)
            .then(function (r) { return r.json(); })
            .then(function (response) {
            return {
                data: response.data,
                successful: response.successful,
                error: response.errors,
                message: response.messages,
            };
        });
    };
    /**
     * Merge list of pdf
     * @param attachments
     */
    AttachmentConnector.prototype.mergeAttachments = function (attachments) {
        return __awaiter(this, void 0, void 0, function () {
            var requestConfig, response, responseJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        requestConfig = (0, Attachment_1.mergingAttachmentsUsingPUT)(attachments);
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestBase)(requestConfig, this.baseUrl)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        responseJson = _a.sent();
                        return [2 /*return*/, {
                                data: responseJson.data,
                                successful: responseJson.successful,
                                error: responseJson.errors,
                                message: responseJson.messages,
                            }];
                }
            });
        });
    };
    return AttachmentConnector;
}());
exports.default = AttachmentConnector;
