import React, { PureComponent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import { getCustomerDetails } from '../../state/pages/customer/selectors';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { _getPartyById as getPartyById } from '../../state/api/party/actionCreators';
import getSearchParams from '../../utils/getSearchParams';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { Documents } from '../../widgets/DocumentsWidget/Documents.widget';
import { AgentSalesFlowWidget } from '../../components/AgentSalesFlowWidget/AgentSalesFlowWidget';
import { SearchMode } from '@tia/agent-api-connector/dist/enums/SearchMode';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import PropTypes from 'prop-types';

class EditQuote extends PureComponent {
  state = {
    toastMessageVisibility: false,
    toastMessageText: '',
    toastMessageType: '',
    searchQueryParams: '',
    showPaymentModal: false,
    showCustomWidget: false,
  };

  constructor(props) {
    super(props);
    this.fetchAssets();

    this.agentSalesFlowWidget = createRef();
  }

  componentDidMount() {
    const queryParams = getSearchParams();

    this.setState({ searchQueryParams: queryParams });
  }

  handleAddPaymentDetails = () => {
    this.setState({
      showPaymentModal: true,
    });
  };

  handleShowCustomWidget = () => {
    this.setState({
      showCustomWidget: true,
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      showCustomWidget: false,
    });
  };

  async fetchAssets() {
    const { match, setRecentlyViewedCustomer } = this.props;
    const { customerid } = match.params;

    setRecentlyViewedCustomer(customerid);
  }

  showProductSelectin(mode) {
    return mode !== 'setinforce';
  }

  render() {
    const { t, match, history } = this.props;
    const { customerid, sipasuuid } = match.params;

    const widgetLocalizationSalesFlow = JSON.stringify(
      t('tia-agent-sales-flow-widget-quote-view:widget', { returnObjects: true })
    );

    const documentFeature = getFeatureFlagsConfiguration('customer.documentsWidget.policy.edit');

    const queryParams = getSearchParams();

    const enableClauseSearch = getFeatureFlagsConfiguration(featureFlags.customer.editQuote.searchClauses);
    const enableRiskToggleOnLeft = getFeatureFlagsConfiguration(featureFlags.customer.editQuote.riskToggleOnLeft);
    const enableDatepicker = getFeatureFlagsConfiguration(featureFlags.customer.editQuote.datepicker);

    return (
      <Layout.Content>
        <tia-toast
          visible={this.state.toastMessageVisibility}
          message={this.state.toastMessageText}
          type={this.state.toastMessageType}
          closeable="true"
          actiontitle=""
          textonly="false"
        />
        <Row gutter={40} style={{ marginTop: '10px' }}>
          <Col xs={24} lg={8}>
            <FeatureFlag featureFlag={featureFlags.customer.editQuote.customerDetail}>
              <CustomerDetail addPaymentDetails={this.handleAddPaymentDetails} />
            </FeatureFlag>
            <FeatureFlag featureFlag={featureFlags.customer.editQuote.createPaymentDetail}>
              <CreatePaymentDetail
                partyId={customerid}
                showPaymentModal={this.state.showPaymentModal}
                showCustomWidget={this.state.showCustomWidget}
                handleShowCustomWidget={this.handleShowCustomWidget}
                closePaymentModal={this.closePaymentModal}
              />
            </FeatureFlag>
            <FeatureFlag featureFlag={featureFlags.customer.documentsWidget.policy.edit.widget}>
              <Documents
                config={getFeatureFlagsConfiguration('customer.documentsWidget.policy.edit')}
                sipasUuid={sipasuuid}
                partyId={customerid}
                searchMode={SearchMode.SipasUuid}
                featureFlag={documentFeature}
              />
            </FeatureFlag>
          </Col>
          <Col xs={24} lg={16}>
            <FeatureFlag featureFlag={featureFlags.customer.editQuote.agentSalesFlowWidget}>
              <div className="agent-sales-flow-widget">
                <AgentSalesFlowWidget
                  partyId={customerid}
                  policyUuid={sipasuuid}
                  queryParameters={JSON.stringify(this.state.searchQueryParams)}
                  mode={queryParams.mode || ''}
                  disableProductSelection={this.showProductSelectin(queryParams.mode)}
                  enableClauseSearch={enableClauseSearch}
                  enableRiskToggleOnLeft={enableRiskToggleOnLeft}
                  enableDatepicker={enableDatepicker}
                  localization={widgetLocalizationSalesFlow}
                  history={history}
                  match={match}
                />
              </div>
            </FeatureFlag>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

const mapStateToProps = (state) => ({
  customerDetails: getCustomerDetails(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getPartyById,
      setRecentlyViewedCustomer,
    },
    dispatch
  );

export default withTranslation(['portal', 'tia-agent-sales-flow-widget'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditQuote))
);

EditQuote.propTypes = {
  match: PropTypes.object,
  setRecentlyViewedCustomer: PropTypes.func,
  t: PropTypes.func,
  history: PropTypes.object,
};
