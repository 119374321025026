import { SET_SEARCH_QUERY_PARTIES, SET_PARTIES_PAGE_NUMBER } from './actionTypes';
import { _getParties } from '../../api/parties/actionCreators';

let partiesSearchQueryDebounceTimer;

function debounce(callback, delay) {
  return function() {
    clearTimeout(partiesSearchQueryDebounceTimer);
    partiesSearchQueryDebounceTimer = setTimeout(callback, delay);
  };
}

export const updatePartiesSearchQuery = (payload) => (dispatch) => {
  dispatch({ type: SET_SEARCH_QUERY_PARTIES, payload });
  debounce(() => dispatch(_getParties()), 400)();
};

export const updatePartiesPageNumber = (payload) => (dispatch) => {
  dispatch({ type: SET_PARTIES_PAGE_NUMBER, payload });
  dispatch(_getParties());
};
