"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PlatformConfiguration_1 = require("../api/PlatformConfiguration");
var fetchRequest_1 = require("./fetchRequest");
var PlatformConfigurationConnector = /** @class */ (function () {
    function PlatformConfigurationConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Returns agent platform configuration
     */
    PlatformConfigurationConnector.prototype.getAgentConfig = function () {
        var requestConfig = (0, PlatformConfiguration_1.getAgentConfig)();
        return (0, fetchRequest_1.fetchRequestBase)(requestConfig, this.baseUrl)
            .then(function (r) { return r.json(); })
            .then(function (response) {
            return {
                data: response.data,
                successful: response.successful,
                error: response.errors,
                message: response.messages,
            };
        });
    };
    return PlatformConfigurationConnector;
}());
exports.default = PlatformConfigurationConnector;
