import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFeatureFlagsConfiguration, getGlobalConfiguration } from '../../utils/getGlobalConfiguration';
import PropTypes from 'prop-types';

export const RelationWidget = (props) => {
  const { t } = useTranslation();
  const widgetLocalizationAgentRelation = JSON.stringify(
    t('tia-agent-relation-widget:widget', { returnObjects: true })
  );

  const allowToCreateNewRelation = getFeatureFlagsConfiguration('customer.relationWidget.allowToCreateNewRelation');
  const allowToEditRelation = getFeatureFlagsConfiguration('customer.relationWidget.allowToEditRelation');
  const additionalColumns = JSON.stringify(getGlobalConfiguration('relationWidget.additionalColumns'));
  const relationClasses = JSON.stringify(getGlobalConfiguration('relationWidget.relationClassesConfig'));
  const relationTypes = JSON.stringify(getGlobalConfiguration('relationWidget.relationTypesConfig'));
  const relationEditableFields = JSON.stringify(getGlobalConfiguration('relationWidget.relationEditableFields'));
  const notificationDuration = JSON.stringify(getGlobalConfiguration('notification.duration'));
  const widgetRef = createRef();

  useEffect(() => {
    const handleClickRow = (event) => {
      props.onEditRelation(event.detail.member, event.detail.relationClass);
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('agent-relation-widget-click-row-event', handleClickRow);
    }
  }, [props.ownerId]);

  return (
    <tia-agent-relation-widget
      ref={widgetRef}
      ownerId={props.ownerId}
      key={props.ownerId}
      localization={widgetLocalizationAgentRelation}
      additionalColumns={additionalColumns}
      allowToCreateNewRelation={allowToCreateNewRelation}
      allowToEditRelation={allowToEditRelation}
      dateFormat={window.agentEnv.dateFormat}
      relationClassesConfig={relationClasses}
      relationTypesConfig={relationTypes}
      relationEditableFields={relationEditableFields}
      notificationDuration={notificationDuration}
      agentApiUrl={window.agentEnv.agentApiUri}
    />
  );
};

RelationWidget.propTypes = {
  onEditRelation: PropTypes.func,
  ownerId: PropTypes.string,
};
