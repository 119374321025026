import {
  ROUTE_ROOT,
  ROUTE_CUSTOMERS,
  ROUTE_CUSTOMER,
  ROUTE_NEW_CUSTOMER,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_CALLBACK,
  ROUTE_EDIT_QUOTE,
  ROUTE_NEW_QUOTE,
  ROUTE_EDIT_CUSTOMER,
  ROUTE_VIEW_POLICY,
  ROUTE_EDIT_OPPORTUNITY,
  ROUTE_NEW_OPPORTUNITY,
  ROUTE_CRM,
  ROUTE_PAYMENTS,
  ROUTE_CLAIMS,
  ROUTE_CUSTOM_WIDGET,
} from './routes';
import {
  PATH_ROUTE,
  PATH_CUSTOMERS,
  PATH_CUSTOMER,
  PATH_NEW_CUSTOMER,
  PATH_EDIT_CUSTOMER,
  PATH_NEW_QUOTE,
  PATH_EDIT_QUOTE,
  PATH_VIEW_POLICY,
  PATH_LOGIN,
  PATH_LOGOUT,
  PATH_CALLBACK,
  PATH_EDIT_OPPORTUNITY,
  PATH_NEW_OPPORTUNITY,
  PATH_CRM,
  PATH_CUSTOMER_PAYMENTS,
  PATH_NEW_CLAIM,
  PATH_CUSTOM_WIDGET,
} from './paths';
import LoginView from '../pages/Login/LoginView/LoginView';
import Customer from '../pages/Customer/Customer';
import Customers from '../pages/Customers/Customers';
import PolicyView from '../pages/PolicyView/PolicyView';
import CreateCustomerDetail from '../pages/CreateCustomerDetail/CreateCustomerDetail';
import EditCustomerDetail from '../pages/EditCustomerDetail/EditCustomerDetail';
import CreateQuote from '../pages/CreateQuote/CreateQuote';
import EditQuote from '../pages/EditQuote/EditQuote';
import EditOpportunity from '../pages/EditOpportunity/EditOpportunity';
import CreateOpportunity from '../pages/CreateOpportunity/CreateOpportunity';
import Crm from '../pages/Crm/Crm';
import Payments from '../pages/Payments/Payment';
import Claims from '../pages/Claims/Claim';
import { CustomWidgetPage } from '../pages/custom/custom';

const ROUTER_CONFIG: Record<string, { path: string; component: any; exact?: boolean }> = {
  [ROUTE_ROOT]: {
    path: PATH_ROUTE,
    component: Customers,
    exact: true,
  },

  [ROUTE_CUSTOMERS]: {
    path: PATH_CUSTOMERS,
    component: Customers,
    exact: true,
  },

  [ROUTE_CUSTOMER]: {
    path: PATH_CUSTOMER,
    component: Customer,
    exact: false,
  },

  [ROUTE_NEW_CUSTOMER]: {
    path: PATH_NEW_CUSTOMER,
    component: CreateCustomerDetail,
    exact: true,
  },

  [ROUTE_LOGIN]: {
    path: PATH_LOGIN,
    component: LoginView,
    exact: true,
  },

  [ROUTE_LOGOUT]: {
    path: PATH_LOGOUT,
    component: LoginView,
    exact: true,
  },

  [ROUTE_CALLBACK]: {
    path: PATH_CALLBACK,
    component: LoginView,
    exact: true,
  },

  [ROUTE_EDIT_QUOTE]: {
    path: PATH_EDIT_QUOTE,
    component: EditQuote,
    exact: true,
  },

  [ROUTE_EDIT_OPPORTUNITY]: {
    path: PATH_EDIT_OPPORTUNITY,
    component: EditOpportunity,
    exact: true,
  },

  [ROUTE_NEW_QUOTE]: {
    path: PATH_NEW_QUOTE,
    component: CreateQuote,
    exact: true,
  },

  [ROUTE_NEW_OPPORTUNITY]: {
    path: PATH_NEW_OPPORTUNITY,
    component: CreateOpportunity,
    exact: true,
  },

  [ROUTE_EDIT_CUSTOMER]: {
    path: PATH_EDIT_CUSTOMER,
    component: EditCustomerDetail,
    exact: true,
  },

  [ROUTE_VIEW_POLICY]: {
    path: PATH_VIEW_POLICY,
    component: PolicyView,
    exact: true,
  },

  [ROUTE_CLAIMS]: {
    path: PATH_NEW_CLAIM,
    component: Claims,
    exact: true,
  },

  [ROUTE_CRM]: {
    path: PATH_CRM,
    component: Crm,
    exact: true,
  },

  [ROUTE_CUSTOM_WIDGET]: {
    path: PATH_CUSTOM_WIDGET,
    component: CustomWidgetPage,
    exact: true
  },

  [ROUTE_PAYMENTS]: {
    path: PATH_CUSTOMER_PAYMENTS,
    component: Payments,
  },
};

export default ROUTER_CONFIG;
