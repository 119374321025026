"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCaseItem = exports.setInForceWithBody = exports.setInForceByUuid = exports.calculatePremium = exports.updateSalesPolicy = exports.duplicatePolicy = exports.getSalesPolicyByUuid = exports.printSingleUuid = exports.print = exports.acceptQuoteOnPolicy = exports.postSalesPolicies = exports.createQuoteOnRenewalUsingPost = exports.createQuoteOnPolicyUsingPost = exports.cancelSalesPolicyUsingDELETE = void 0;
var API_URL = 'v1/sales-policies';
var cancelSalesPolicyUsingDELETE = function (spdUuid, params) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "?").concat(params.toString()),
        method: 'DELETE',
    };
};
exports.cancelSalesPolicyUsingDELETE = cancelSalesPolicyUsingDELETE;
var createQuoteOnPolicyUsingPost = function (spdUuid) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/quote-on-policy"),
        method: 'POST',
    };
};
exports.createQuoteOnPolicyUsingPost = createQuoteOnPolicyUsingPost;
var createQuoteOnRenewalUsingPost = function (spdUuid) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/quote-on-renewal"),
        method: 'POST',
    };
};
exports.createQuoteOnRenewalUsingPost = createQuoteOnRenewalUsingPost;
var postSalesPolicies = function (productsWithBasketForDiscount, params) {
    return {
        url: "".concat(API_URL, "?").concat(params),
        method: 'POST',
        data: productsWithBasketForDiscount,
    };
};
exports.postSalesPolicies = postSalesPolicies;
var acceptQuoteOnPolicy = function (spdUuid) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/accept-quote-on-policy"),
        method: 'PUT',
    };
};
exports.acceptQuoteOnPolicy = acceptQuoteOnPolicy;
var print = function (spdUuids, params) {
    return {
        url: "".concat(API_URL, "/print?").concat(params.toString()),
        method: 'PUT',
        data: spdUuids,
    };
};
exports.print = print;
var printSingleUuid = function (spdUuid, params) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/print?").concat(params.toString()),
        method: 'PUT',
    };
};
exports.printSingleUuid = printSingleUuid;
var getSalesPolicyByUuid = function (spdUuid, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.getSalesPolicyByUuid = getSalesPolicyByUuid;
var duplicatePolicy = function (spdUuid, params, query) {
    return {
        url: "".concat(API_URL, "/duplicate-policy/").concat(spdUuid, "?").concat(params.toString()).concat(query),
        method: 'POST',
    };
};
exports.duplicatePolicy = duplicatePolicy;
var updateSalesPolicy = function (salesPolicy, params, query) {
    return {
        url: "".concat(API_URL, "?").concat(params.toString()).concat(query),
        method: 'PUT',
        data: salesPolicy,
    };
};
exports.updateSalesPolicy = updateSalesPolicy;
var calculatePremium = function (salesPolicy) {
    return {
        url: "".concat(API_URL, "/calculate-premium"),
        method: 'PUT',
        data: salesPolicy,
    };
};
exports.calculatePremium = calculatePremium;
var setInForceByUuid = function (spdUuid) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/set-in-force"),
        method: 'PUT',
    };
};
exports.setInForceByUuid = setInForceByUuid;
var setInForceWithBody = function (salesPolicy) {
    return {
        url: "".concat(API_URL, "/set-in-force"),
        method: 'PUT',
        data: salesPolicy,
    };
};
exports.setInForceWithBody = setInForceWithBody;
var createCaseItem = function (spdUuid, params) {
    return {
        url: "".concat(API_URL, "/").concat(spdUuid, "/create-case-item"),
        method: 'POST',
        data: params,
    };
};
exports.createCaseItem = createCaseItem;
