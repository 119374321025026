
/* eslint-disable react/no-render-return-value */
import './utils/Polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from './root';
import store from './configureStore';
import registerEnvironmentConfig from './registerEnvironmentConfig';

const container = document.getElementById('root')!;
const root = createRoot(container);

registerEnvironmentConfig().then(() => root.render(<Root store={store} />));
