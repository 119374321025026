import { combineReducers } from 'redux';
import {
  GET_AGENT_CONFIGURATIONS_SUCCESS,
  GET_AGENT_CONFIGURATIONS_ERROR,
  GET_AGENT_CONFIGURATIONS_REQUEST,
  SET_AGENT_TYPE,
  SET_AGENT_FIRMS,
} from '../../actionTypes';

const AGENT_CONFIGURATION_INITIAL_STATE = {
  isFetching: false,
  isLoaded: false,
};

const agentType = (state = '', action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AGENT_TYPE:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

const agentFirms = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_AGENT_FIRMS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

const agentConfigurations = (state = AGENT_CONFIGURATION_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_AGENT_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case GET_AGENT_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
        isLoaded: true,
      };

    case GET_AGENT_CONFIGURATIONS_ERROR:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
      };

    default:
      return state;
  }
};

export default combineReducers({
  agentConfigurations,
  agentType,
  agentFirms,
});
