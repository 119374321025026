export const ROUTE_ROOT = 'root';
export const ROUTE_CUSTOMERS = 'customers';
export const ROUTE_CUSTOMER = 'customer';
export const ROUTE_NEW_CUSTOMER = 'newCustomer';
export const ROUTE_LOGIN = 'login';
export const ROUTE_LOGOUT = 'logout';
export const ROUTE_CALLBACK = 'callback';
export const ROUTE_EDIT_QUOTE = 'editQuote';
export const ROUTE_EDIT_OPPORTUNITY = 'editOpportunity';
export const ROUTE_NEW_QUOTE = 'newQuote';
export const ROUTE_NEW_OPPORTUNITY = 'newOpportunity';
export const ROUTE_EDIT_CUSTOMER = 'editCustomer';
export const ROUTE_VIEW_POLICY = 'viewPolicy';
export const ROUTE_CRM = 'crm';
export const ROUTE_CUSTOM_WIDGET = 'custom-widget';
export const ROUTE_PAYMENTS = 'payments';
export const ROUTE_CLAIMS = 'claims';
