"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchMode = void 0;
var SearchMode;
(function (SearchMode) {
    SearchMode["Policy"] = "POLICY";
    SearchMode["Party"] = "PARTY";
    SearchMode["Opportunity"] = "BASKET";
    SearchMode["SipasUuid"] = "SIPASUUID";
})(SearchMode = exports.SearchMode || (exports.SearchMode = {}));
