import React, { createRef, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { bool, string } from 'prop-types';
import { editQuotePath, newQuotePath } from '../../router/paths';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { featureFlagsChangeAtom } from '../../atoms/atoms';
import { useAtomValue } from 'jotai';

const Quotes = memo(function Quotes({ id }) {
  const featureFlagsAtom = useAtomValue(featureFlagsChangeAtom);
  const widgetRef = createRef();
  const { t } = useTranslation();
  const history = useHistory();

  const newQuoteButton = getFeatureFlagsConfiguration(featureFlags.customer.customerView.newQuoteButton);
  const cancel = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.cancel);
  const cancelQuoteOnPolicy = getFeatureFlagsConfiguration(
    featureFlags.customer.customerView.quoteActions.cancelQuoteOnPolicy
  );
  const cancelQuoteOnRenewal = getFeatureFlagsConfiguration(
    featureFlags.customer.customerView.quoteActions.cancelQuoteOnRenewal
  );
  const sendTo = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.sendTo);
  const sendToAgent = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.sendToAgent);
  const sendToAgentFirm = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.sendToAgentFirm);
  const sendToCustomer = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.sendToCustomer);

  const setInForce = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.setInForce);
  const edit = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.edit);
  const addNoteToQuote = getFeatureFlagsConfiguration(featureFlags.customer.customerView.quoteActions.addNoteToQuote);

  const acceptQuoteOnPolicy = getFeatureFlagsConfiguration(
    featureFlags.customer.customerView.quoteActions.acceptQuoteOnPolicy
  );

  const acceptQuoteOnRenewal = getFeatureFlagsConfiguration(
    featureFlags.customer.customerView.quoteActions.acceptQuoteOnRenewal
  );

  const featureFlag = JSON.stringify({quote:{
    newQuoteButton,
    cancel,
    cancelQuoteOnPolicy,
    cancelQuoteOnRenewal,
    edit,
    sendTo,
    setInForce,
    sendToAgent,
    sendToAgentFirm,
    sendToCustomer,
    acceptQuoteOnPolicy,
    acceptQuoteOnRenewal,
    addNoteToQuote,
  }});
  const policyStatusTranslations = t('portal:policyStatus', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });

  const quoteTranslations = t('portal:widgets.quoteList', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const translations = JSON.stringify({
    quote: quoteTranslations,
    policyStatus: policyStatusTranslations,
  });

  useEffect(() => {
    const handleReturnMessages = (event) => {
      const { errors, messages } = event.detail;

      if (errors) {
        showMultipleToasts(errors);
      }

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    const handleNewQuoteClick = () => {
      history.push(newQuotePath(id));
    };

    const editQuote = (event) => {
      const { uuid } = event.detail;

      history.push(editQuotePath(id, uuid));
    };

    const handleSuccess = (event) => {
      switch (event.type) {
        case 'quote-successful-print':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtPrintSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-accept':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtAcceptSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-cancel':
          openNotification({
            message: t('widgets.quoteList.messages.cancelQuoteSuccessful'),
            severityLevel: 'success',
            title: t('widgets.quoteList.messages.messageTitleSuccess'),
          });
          break;

          case 'quote-successful-add-note':
              openNotification({
                  message: t('widgets.quoteList.messages.createQuoteNoteSuccessful'),
                  severityLevel: 'success',
                  title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
              });
              break;

        case 'quote-successful-setinforce':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtSetInForceSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;
      }

      const { messages } = event.detail;

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('new-quote', handleNewQuoteClick);
      widgetRef.current.addEventListener('quote-edit', editQuote);
      widgetRef.current.addEventListener('quote-successful-print', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-accept', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-cancel', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-setinforce', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-add-note', handleSuccess);

      widgetRef.current.addEventListener('quotes-error', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-print', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-accept', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-cancel', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-setinforce', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-add-note', handleReturnMessages);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('new-quote', handleNewQuoteClick);
        widgetRef.current.removeEventListener('quote-edit', editQuote);
        widgetRef.current.removeEventListener('quote-successful-print', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-accept', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-setinforce', handleSuccess);
          widgetRef.current.removeEventListener('quote-successful-cancel', handleSuccess);
          widgetRef.current.removeEventListener('quote-successful-add-note', handleSuccess);

        widgetRef.current.removeEventListener('quotes-error', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-print', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-accept', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-cancel', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-setinforce', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-add-note', handleReturnMessages);
      }
    };
  }, [id]);

  return (
    <ds-portfolio-quote-widget
      ref={widgetRef}
      variant={'quote'}
      partyId={id}
      featureFlag={featureFlag}
      translations={translations}
    />
  );
});

Quotes.propTypes = {
  id: string.isRequired,
  newQuoteButton: bool,
  cancel: bool,
  cancelQuoteOnPolicy: bool,
  cancelQuoteOnRenewal: bool,
  edit: bool,
  sendTo: bool,
  setInForce: bool,
  sendToAgent: bool,
  sendToAgentFirm: bool,
  sendToCustomer: bool,
  acceptQuoteOnPolicy: bool,
  acceptQuoteOnRenewal: bool,
  addNoteToQuote: bool,
};

export default Quotes;
