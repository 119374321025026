import { GET_PARTY_BY_ID_REQUEST, GET_PARTY_BY_ID_SUCCESS, GET_PARTY_BY_ID_ERROR } from '../../actionTypes';
import { addUpsertEntities } from '../../entities/actionCreators';
import { responseNormalizer } from '../../../utils/responseNormalizer';
import { PARTY_DETAILS_ENTITIES } from './consts';
import { apiConnector } from '../../../utils/apiConnector';

const getPartyById = (partyId) => async (dispatch) => {
  try {
    const response = await apiConnector().party.getParty(partyId);
    const normalizedResponse = responseNormalizer([response.data], 'id');

    dispatch(
      addUpsertEntities({
        entities: normalizedResponse,
        entityType: PARTY_DETAILS_ENTITIES,
      })
    );

    return {
      ids: Object.keys(normalizedResponse),
      status: response.status,
    };
  } catch (err) {
    console.error('Error: ', err.toString());
  }
};

export const _getPartyById = (partyId) => async (dispatch) => {
  dispatch({ type: GET_PARTY_BY_ID_REQUEST });

  try {
    const payload = await dispatch(getPartyById(partyId));

    dispatch({ type: GET_PARTY_BY_ID_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_PARTY_BY_ID_ERROR, payload: err.toString() });
  }
};
