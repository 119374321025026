import React, { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

export const PolicyTimeline = (props) => {
  const { setClickedPolicyVersionDateAndStatus, partyId } = props;
  const { sipasuuid } = useParams();

  const ref = createRef();

  const { t } = useTranslation();

  const policyTimelineTranslations = t('portal:widgets.policyTimeline', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });

  const handleTimelineClick = (e) => {
    const { versionDate, latest } = e.detail;

    setClickedPolicyVersionDateAndStatus({
      policyVersionDate: versionDate,
      status: latest ? 'active' : 'historic',
    });
  };

  const handleReturnMessages = (event) => {
    const { errors, messages } = event.detail;

    if (errors) {
      showMultipleToasts(errors);
    }

    if (messages) {
      showMultipleToasts(messages);
    }
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('policytimeline-view', handleTimelineClick);
      ref.current.addEventListener('policytimeline-error', handleReturnMessages);
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('policytimeline-view', handleTimelineClick);
        ref.current.removeEventListener('policytimeline-error', handleReturnMessages);
      }
    };
  }, [sipasuuid]);

  return (
    <ds-portfolio-policytimeline-widget
      ref={ref}
      variant="policyTimeline"
      sipasUuid={sipasuuid}
      partyId={partyId}
      translations={JSON.stringify({ policyTimeline: policyTimelineTranslations })}
    />
  );
};

PolicyTimeline.propTypes = {
  setClickedPolicyVersionDateAndStatus: PropTypes.func,
  partyId: PropTypes.string,
};
