"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteObjectKeys = exports.postSalesPolicyKeys = exports.calculatePremiumKeys = exports.updateSalesPolicyKeys = void 0;
exports.updateSalesPolicyKeys = 'lov,affectsPremium,dataType,default,defaultExpression,format,helpText,locked,maximum,metaData,minimum,mtaEditAble,order,page,readonly,searchConfiguration,visible';
exports.calculatePremiumKeys = 'lov,affectsPremium,dataType,default,defaultExpression,format,helpText,locked,maximum,metaData,minimum,mtaEditAble,order,page,readonly,searchConfiguration,visible';
exports.postSalesPolicyKeys = 'lov,affectsPremium,dataType,default,defaultExpression,format,helpText,locked,maximum,metaData,minimum,mtaEditAble,order,page,readonly,searchConfiguration,visible';
/**
 * Delete object specified keys
 * @param object - object
 * @param key - keys to remove. Separated by comma. Example.: id,name
 */
var deleteObjectKeys = function (object, keys) {
    var keysToRemove = keys.split(',');
    return Object.fromEntries(Object.entries(object)
        .map(function (_a) {
        var key = _a[0], property = _a[1];
        if (property instanceof Array) {
            return [key, property.map(function (obj) { return (typeof obj === 'object' ? (0, exports.deleteObjectKeys)(obj, keys) : obj); })];
        }
        else if (property instanceof Object) {
            return [key, (0, exports.deleteObjectKeys)(property, keys)];
        }
        return [key, property];
    })
        .filter(function (_a) {
        var key = _a[0];
        return keysToRemove.indexOf(key) === -1;
    }));
};
exports.deleteObjectKeys = deleteObjectKeys;
