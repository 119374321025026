import { combineReducers } from 'redux';

import entities from './entities/reducer';
import pages from './pages/rootReducer';
import api from './api/rootReducer';

export default combineReducers({
  entities,
  pages,
  api,
});
