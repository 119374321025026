import React from 'react';
import { ROUTE_ROOT, ROUTE_CRM, ROUTE_CUSTOM_WIDGET } from './routes';
import { ContactsOutlined, FileDoneOutlined, ApiOutlined } from '@ant-design/icons';

export const MENU_ITEM_ADMIN = 'Admin';

// Menu items should be registered via helper function
const MENU_ITEMS = {
  [ROUTE_ROOT]: {
    icon: <ContactsOutlined />,
    translationKey: 'menus.leftMenu.customers',
  },
  [ROUTE_CRM]: {
    icon: <FileDoneOutlined />,
    translationKey: 'menus.leftMenu.crm',
  },
  [ROUTE_CUSTOM_WIDGET]: {
    icon: <ApiOutlined />,
    translationKey: 'menus.leftMenu.customWidget'
  }
};

export default MENU_ITEMS;
