"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirmBasket = exports.resetDiscount = exports.applyDiscount = exports.cancelBasket = exports.duplicateBasket = exports.setBasketInForce = exports.fetchBasketWithPolicies = exports.updateBasketDetail = void 0;
var API_URL = 'v1/opportunity-baskets';
var updateBasketDetail = function (basket) {
    return {
        url: API_URL,
        method: 'PUT',
        data: basket,
    };
};
exports.updateBasketDetail = updateBasketDetail;
var fetchBasketWithPolicies = function (params) {
    return {
        url: "".concat(API_URL, "/baskets-with-policies?").concat(params.toString()),
        method: 'GET',
    };
};
exports.fetchBasketWithPolicies = fetchBasketWithPolicies;
var setBasketInForce = function (params, uuids) {
    return {
        url: "".concat(API_URL, "/set-basket-in-force/with-policies?").concat(params.toString()),
        method: 'PUT',
        data: uuids,
    };
};
exports.setBasketInForce = setBasketInForce;
var duplicateBasket = function (params, uuids) {
    return {
        url: "".concat(API_URL, "/duplicate-basket/with-policies?").concat(params.toString()),
        method: 'POST',
        data: uuids,
    };
};
exports.duplicateBasket = duplicateBasket;
var cancelBasket = function (params, uuids) {
    return {
        url: "".concat(API_URL, "/cancel-basket/with-policies?").concat(params.toString()),
        method: 'PUT',
        data: uuids,
    };
};
exports.cancelBasket = cancelBasket;
var applyDiscount = function (basketWithUuids) {
    return {
        url: "".concat(API_URL, "/apply-basket-discount"),
        method: 'POST',
        data: basketWithUuids,
    };
};
exports.applyDiscount = applyDiscount;
var resetDiscount = function (basketWithUuids) {
    return {
        url: "".concat(API_URL, "/reset-basket-discount"),
        method: 'POST',
        data: basketWithUuids,
    };
};
exports.resetDiscount = resetDiscount;
var confirmBasket = function (opportunityId) {
    return {
        url: "".concat(API_URL, "/").concat(opportunityId, "/confirm-basket"),
        method: 'POST',
    };
};
exports.confirmBasket = confirmBasket;
