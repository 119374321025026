export const formatPriceDecimals = (value) => {
  if (!value) {
    return 0;
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};
