/**
 * All the paths defined as constants
 */
export const PATH_ROUTE = '/';

/**
 * Customer paths
 */
export const PATH_CUSTOMERS = '/customers';
export const PATH_NEW_CUSTOMER = `${PATH_CUSTOMERS}/new-customer`;
export const PATH_CUSTOMER = `${PATH_CUSTOMERS}/details/:customerid`;
export const PATH_EDIT_CUSTOMER = `${PATH_CUSTOMER}/edit`;
export const PATH_CUSTOMER_PAYMENTS = `${PATH_CUSTOMER}/payments`;

/**
 * Quote paths
 */
export const PATH_NEW_QUOTE = `${PATH_CUSTOMER}/new-quote`;
export const PATH_EDIT_QUOTE = `${PATH_CUSTOMER}/edit-quote/:sipasuuid`;
export const PATH_NEW_OPPORTUNITY = `${PATH_CUSTOMER}/new-opportunity`;
export const PATH_EDIT_OPPORTUNITY = `${PATH_CUSTOMER}/edit-opportunity/:opportunityid`;
export const PATH_VIEW_POLICY = `${PATH_CUSTOMER}/policy/:sipasuuid`;
export const PATH_NEW_CLAIM = `${PATH_VIEW_POLICY}/new-claim`;

export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_CALLBACK = '/callback';

export const customerDetailsPath = (id) => PATH_CUSTOMER.replace(':customerid', id);
export const customerDetailsEditPath = (id) => PATH_EDIT_CUSTOMER.replace(':customerid', id);
export const customerDetailsPaymentPath = (id) => PATH_CUSTOMER_PAYMENTS.replace(':customerid', id);

export const newQuotePath = (id) => PATH_NEW_QUOTE.replace(':customerid', id);
export const newOpportunityPath = (id) => PATH_NEW_OPPORTUNITY.replace(':customerid', id);
export const editQuotePath = (customerId, quoteId, mode) => {
  const modeQuery = mode ? `?mode=${mode}` : '';

  return PATH_EDIT_QUOTE.replace(':customerid', customerId).replace(':sipasuuid', quoteId) + modeQuery;
};

export const editOpportunityPath = (customerId, opportunityId, mode) => {
  const modeQuery = mode ? `?mode=${mode}` : '';

  return PATH_EDIT_OPPORTUNITY.replace(':customerid', customerId).replace(':opportunityid', opportunityId) + modeQuery;
};

/**
 * @param { String } customerId Unique identifier
 * @param { String } sipasUuid Unique identifier
 * @returns { URL } url
 */
export const viewPolicyPath = (customerId, sipasUuid) =>
  PATH_VIEW_POLICY.replace(':customerid', customerId).replace(':sipasuuid', sipasUuid);

export const newClaimPath = (customerId, sipasUuid) =>
PATH_NEW_CLAIM.replace(':customerid', customerId).replace(':sipasuuid', sipasUuid);

/**
 * CRM paths
 */

export const PATH_CRM = '/tasks';

export const PATH_CUSTOM_WIDGET = '/custom-widget';
