import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, object, element, string } from 'prop-types';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import CardCell from '../../components/CardCell/CardCell';
import WidgetCard from '../../components/WidgetCard/WidgetCard';
import SpaceFiller from '../../components/SpaceFiller/SpaceFiller';
import { getTimeAgo } from '../../state/pages/customers/helpers';
import { PATH_CUSTOMERS } from '../../router/paths';
import { PaperClipOutlined } from '@ant-design/icons';

const RecentCustomers = ({ customers, contentCol }) => {
  const { t } = useTranslation();
  const title = t('widgets.recentCustomers.title');

  if (!customers.length) {
    return (
      <RecentCustomerContainer title={title}>
        <SpaceFiller Icon={PaperClipOutlined} text={t('widgets.recentCustomers.spaceFiller')} />
      </RecentCustomerContainer>
    );
  }

  return (
    <RecentCustomerContainer title={title}>
      <>
        {customers.map((customer) => (
          <Col {...contentCol} key={customer.id}>
            <Link to={`${PATH_CUSTOMERS}/details/${customer.id}`}>
              <CardCell style={{ cursor: 'pointer' }}>
                <div style={{ padding: '2px 15px' }}>
                  <span style={recentlyViewedTitleStyle}>{customer.name}</span>
                  <div style={{ fontSize: '11px', color: '#8db2bf' }}>{getTimeAgo(customer.date, t)}</div>
                </div>
              </CardCell>
            </Link>
          </Col>
        ))}
      </>
    </RecentCustomerContainer>
  );
};

const RecentCustomerContainer = ({ children, title }) => (
  <WidgetCard title={title} style={{ marginTop: 24 }}>
    <Row style={{ display: 'block' }} gutter={40}>
      {children}
    </Row>
  </WidgetCard>
);

RecentCustomers.propTypes = {
  customers: array.isRequired,
  contentCol: object,
};

RecentCustomers.defaultProps = {
  contentCol: { xs: 24 },
};

RecentCustomerContainer.propTypes = {
  children: element,
  title: string,
};

const recentlyViewedTitleStyle = {
  display: 'block',
  fontSize: '14px',
  fontWeight: '600',
  color: '#4E505E',
  marginBottom: '1px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default RecentCustomers;
