"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergingAttachmentsUsingPUT = exports.getAttachmentUsingGET = exports.uploadAttachmentUsingPOST = exports.getAttachmentsUsingGET = void 0;
var API_URL = 'v1/documents';
var getAttachmentsUsingGET = function (params) {
    return {
        url: "".concat(API_URL, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getAttachmentsUsingGET = getAttachmentsUsingGET;
var uploadAttachmentUsingPOST = function (values, params) {
    var formData = new FormData();
    formData.append('file', values.file);
    formData.append('description', values.description);
    return {
        url: "".concat(API_URL, "?").concat(params.toString()),
        method: 'POST',
        data: formData,
    };
};
exports.uploadAttachmentUsingPOST = uploadAttachmentUsingPOST;
var getAttachmentUsingGET = function (attachmentId, attachmentSeqNo, params) {
    return {
        url: "".concat(API_URL, "/").concat(attachmentId, "/").concat(attachmentSeqNo, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getAttachmentUsingGET = getAttachmentUsingGET;
var mergingAttachmentsUsingPUT = function (attachments) {
    return {
        url: "".concat(API_URL, "/merge-pdf"),
        method: 'PUT',
        data: attachments,
    };
};
exports.mergingAttachmentsUsingPUT = mergingAttachmentsUsingPUT;
