import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Provider as JotaiProvider, createStore } from 'jotai';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App/App';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from '@tia/agent-api-connector/dist/query/client';

export const jotaiStore = createStore();
const ReactQueryDevtools = process.env.NODE_ENV === 'development' ? React.lazy(() =>
  import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    })
  )
) : () => null

const Root = ({ store }) => (
  <QueryClientProvider client={queryClient}>
    <Suspense>
      <ReactQueryDevtools />
    </Suspense>
    <Provider store={store}>
      <JotaiProvider store={jotaiStore}>
        <Router basename={window.agentEnv.contextPath}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </Router>
      </JotaiProvider>
    </Provider>
  </QueryClientProvider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
