import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { Row, Col } from 'antd';

import RecentCustomersWidget from '../../widgets/RecentCustomers/RecentCustomers.widget';
import PartyTable from '../../widgets/PartyTable/PartyTable.widget';
import { getRecentlyViewedCustomerList } from '../../state/pages/customers/selectors';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import PropTypes from 'prop-types';

class Customers extends PureComponent {
  render() {
    const { recentlyViewedCustomer } = this.props;

    return (
      <Row gutter={40}>
        <Col lg={8} md={24}>
          <FeatureFlag featureFlag={featureFlags.customers.customersView.recentCustomersWidget}>
            <RecentCustomersWidget customers={recentlyViewedCustomer} />
          </FeatureFlag>
        </Col>
        <Col lg={16} md={24}>
          <FeatureFlag featureFlag={featureFlags.customers.customersView.partyTable}>
            <PartyTable />
          </FeatureFlag>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  recentlyViewedCustomer: getRecentlyViewedCustomerList(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

Customers.propTypes = {
  recentlyViewedCustomer: PropTypes.array,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Customers));
