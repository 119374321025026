import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { customerDetailsPath } from '../../router/paths';
import { apiConnector } from '../../utils/apiConnector';
import { openNotification } from '../../utils/apiSetup';
import CustomUiWidget from '../../widgets/CustomUiWidget/CustomUiWidget.widget';

export const EditCustomerDetail = memo(function EditCustomerDetail() {
  const match = useRouteMatch<{ customerid?: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const partyId = match.params?.customerid ?? '';

  const handleCustomUiSubmit = async (event: any) => {
    const formValues = event.detail;

    if (!partyId) {
      openNotification({ message: t('widgets.editCustomerDetails.messages.error'), severityLevel: 'error' });

      return;
    }

    try {
      const resData = await apiConnector().uiConfiguration.updateEntities(
        'AgentPlatformEditCustomerDetails',
        formValues,
        { partyId } as any
      );

      if (resData && !resData.data) {
        openNotification({
          message: t('widgets.editCustomerDetails.messages.failure'),
          severityLevel: 'error',
          title: resData.error[0].errorCode + ' - ' + resData.error[0].message,
        });

        window.dispatchEvent(new CustomEvent('cui-post-submit'));

        return;
      }

      if (resData.message && resData.message.length > 0) {
        openNotification({ message: resData.message[0].message, type: 'warning' });
      } else {
        openNotification({
          message: t('widgets.editCustomerDetails.messages.success'),
          severityLevel: 'success',
          title: ' ',
        });
      }

      return history.push(customerDetailsPath(partyId));
    } catch (error) {
      window.dispatchEvent(new CustomEvent('cui-post-submit'));
      console.error(error);
      openNotification({ message: t('widgets.editCustomerDetails.messages.missingPartyID'), severityLevel: 'error' });
    }
  };

  return (
    <FeatureFlag featureFlag={featureFlags.customer.editCustomerDetails.customUiWidget}>
      <CustomUiWidget
        configName="AgentPlatformEditCustomerDetails"
        partyId={partyId}
        handleCustomUiSubmit={handleCustomUiSubmit}
      />
    </FeatureFlag>
  );
});

export default EditCustomerDetail;
