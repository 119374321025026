import { combineReducers } from 'redux';
import { GET_PARTY_BY_ID_REQUEST, GET_PARTY_BY_ID_SUCCESS, GET_PARTY_BY_ID_ERROR } from '../../actionTypes';

const isFetching = (state = false, action) => {
  const { type } = action;

  switch (type) {
    case GET_PARTY_BY_ID_REQUEST:
      return true;

    case GET_PARTY_BY_ID_SUCCESS:
      return false;

    case GET_PARTY_BY_ID_ERROR:
      return false;

    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
});
