import React, { memo, useState, useEffect } from 'react';
import { getLanguage } from '../../utils/language';
import { useTranslation } from 'react-i18next';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import { useHistory } from 'react-router-dom';
type Props = {
  configName: string;
  partyId: string;
  handleCancel?: (...args: any[]) => any;
  handleCustomUiSubmit?: (...args: any[]) => any;
  handleEdit?: (...args: any[]) => any;
  addPaymentDetails?: (...args: any[]) => any;
};

const CustomUiWidget = memo(function CustomUiWidget({
  configName,
  partyId,
  addPaymentDetails,
  handleCancel,
  handleCustomUiSubmit,
  handleEdit,
}: Props) {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const widgetLocalizationCustomUI = JSON.stringify(t('tia-custom-ui-config-widget:widget', { returnObjects: true }));
  const history = useHistory();
  const [ref, setRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!ref) {
      return;
    }

    const cuiCancelHandler = (e: Event) => {
      if (handleCancel) {
        handleCancel(e);
      } else {
        history.go(-1);
      }
    };

    const cuiEditHandler = (e: Event) => {
      handleEdit?.(e);
    };

    const cuiSubmitHandler = (e: Event) => {
      handleCustomUiSubmit?.(e);
    };

    const cuiPaymentHandler = (e: Event) => {
      addPaymentDetails?.(e);
    };

    const errorsAndMessagesHandler = (e: CustomEvent) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    };

    ref.addEventListener('cui-cancel', cuiCancelHandler);
    ref.addEventListener('cui-edit', cuiEditHandler);
    ref.addEventListener('cui-submit', cuiSubmitHandler);
    ref.addEventListener('cui-payment', cuiPaymentHandler);
    ref.addEventListener('widget-error', errorsAndMessagesHandler);
    ref.addEventListener('widget-message', errorsAndMessagesHandler);

    return () => {
      ref.removeEventListener('cui-cancel', cuiCancelHandler);
      ref.removeEventListener('cui-edit', cuiEditHandler);
      ref.removeEventListener('cui-submit', cuiSubmitHandler);
      ref.removeEventListener('cui-payment', cuiPaymentHandler);
      ref.removeEventListener('widget-error', errorsAndMessagesHandler);
      ref.removeEventListener('widget-message', errorsAndMessagesHandler);
    };
  }, [ref]);

  return (
    <tia-custom-ui-config-widget
      ref={setRef}
      configName={configName}
      partyId={partyId}
      language={getLanguage()}
      dateFormat={window.agentEnv?.dateFormat}
      translation={widgetLocalizationCustomUI}
    />
  );
});

export default CustomUiWidget;
