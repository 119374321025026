import { notification } from 'antd';
import i18n from 'i18next';
import { getGlobalConfiguration } from './getGlobalConfiguration';

export const showMultipleToasts = (messages) => {
  messages.forEach((message) => {
    openNotification(message);
  });
};

export const openNotification = (error) => {
  let notificationType = 'warning';
  let duration = getGlobalConfiguration('notification.duration.warning');

  if (!error.severityLevel) {
    error.severityLevel = 'error';
  }

  switch (error.severityLevel.toLowerCase()) {
    case 'alert':
      break;

    case 'warning':
      notificationType = 'warning';
      duration = getGlobalConfiguration('notification.duration.warning');
      break;

    case 'info':
      break;

    case 'debug':
      notificationType = 'info';
      duration = getGlobalConfiguration('notification.duration.info');
      break;

    case 'emergency':
      break;

    case 'critical':
      break;

    case 'error':
      notificationType = 'error';
      duration = getGlobalConfiguration('notification.duration.error');
      break;

    case 'success':
      notificationType = 'success';
      duration = getGlobalConfiguration('notification.duration.success');
      break;

    default:
      notificationType = 'warning';
      duration = getGlobalConfiguration('notification.duration.warning');
  }

  const title =
    i18n.t(`errorHandling.errorCodes.${error.source}.title.${error.externalErrorCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.general.title.${error.errorCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.${error.source}.title.${error.externalMessageCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.general.title.${error.messageCode}`, '') ||
    error.title ||
    i18n.t(`errorHandling.errorCodes.${error.source}.title.FALLBACK_TITLE`, '') ||
    i18n.t('errorHandling.errorCodes.general.title.FALLBACK_ERROR');

  const message =
    i18n.t(`errorHandling.errorCodes.${error.source}.message.${error.externalErrorCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.general.message.${error.errorCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.${error.source}.message.${error.externalMessageCode}`, '') ||
    i18n.t(`errorHandling.errorCodes.general.message.${error.messageCode}`, '') ||
    error.message ||
    i18n.t(`errorHandling.errorCodes.${error.source}.message.FALLBACK_MESSAGE`, '') ||
    i18n.t('errorHandling.errorCodes.general.message.FALLBACK_ERROR');

  notification[notificationType]({
    message: title,
    description: message,
    duration,
  });
};
