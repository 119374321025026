import React from 'react';
import { withRouter } from 'react-router-dom';
import WidgetCard from '../../components/WidgetCard/WidgetCard';
import { getGlobalConfiguration } from '../../utils/getGlobalConfiguration';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Payments = ({ partyId }) => {
  const { t } = useTranslation();

  const displayedColumnConfiguration = JSON.stringify(
    getGlobalConfiguration('paymentWidget.displayedColumnConfiguration')
  );
  const translations = JSON.stringify(t('tia-payment-widget:widget.label', { returnObjects: true }));

  const muiGridTranslations = JSON.stringify(t('tia-payment-widget:widget.muiGrid', { returnObjects: true, defaultValue: {} }));

  return (
    <WidgetCard style={{ marginTop: '0' }} title={t('widgets.payments.headline')}>
      <ds-payment-widget
        partyId={partyId}
        displayedColumnConfiguration={displayedColumnConfiguration}
        translations={translations}
        muiGridTranslations={muiGridTranslations}
      />
    </WidgetCard>
  );
};

Payments.propTypes = {
  partyId: PropTypes.string,
};

export default withRouter(Payments);
