import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const i18nInit = (whitelistedLanguages, fallbackLanguage, contextPath) => {
  const supportedLanguages = whitelistedLanguages ? whitelistedLanguages.split(',') : [];
  const fallbackLng = fallbackLanguage ? fallbackLanguage : 'tia';

  if (!supportedLanguages.includes(fallbackLng)) {
    supportedLanguages.push(fallbackLng);
  }

  const translationsPath = contextPath ? `/${contextPath}/locales/{{lng}}/{{ns}}.json` : '/locales/{{lng}}/{{ns}}.json';

  return (
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: fallbackLng,
        defaultNS: 'portal',
        supportedLngs: supportedLanguages,

        ns: [
          'portal',
          'tia-agent-sales-flow-widget',
          'tia-agent-sales-flow-widget-quote-view',
          'tia-opportunity-basket-widget',
          'tia-agent-relation-widget',
          'tia-payment-widget',
          'tia-custom-ui-config-widget',
        ],

        debug: false,

        interpolation: {
          // not needed for react as it escapes by default
          escapeValue: false,
        },
        backend: {
          loadPath: translationsPath,
        },
        react: {},
        detection: {
          // order and from where user language should be detected
          order: ['querystring', 'localStorage', 'sessionStorage', 'navigator'],

          // keys or params to lookup language from
          lookupQuerystring: 'lng',
          lookupLocalStorage: 'lng',
          lookupSessionStorage: 'lng',

          // cache user language on
          caches: ['localStorage'],
          // languages to not persist (cookie, localStorage)
          excludeCacheFor: ['cimode'],
        },
      })
  );
};

export default i18nInit;
export { i18n };
