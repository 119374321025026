import React, { useEffect, createRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { string } from 'prop-types';
import { editOpportunityPath, editQuotePath, newOpportunityPath } from '../../router/paths';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import {useAtomValue} from "jotai/index";
import {featureFlagsChangeAtom} from "../../atoms/atoms";

const Opportunities = memo(function Opportunities({ id }) {
  const featureFlagsAtom = useAtomValue(featureFlagsChangeAtom);
  const widgetRef = createRef();
  const { t } = useTranslation();
  const history = useHistory();

  const opportunityFeatures = getFeatureFlagsConfiguration('customer.customerView.opportunityActions');

  const opportunityPolicyFeatures = getFeatureFlagsConfiguration('customer.customerView.opportunityPolicyActions');

  const bundleDiscount = getFeatureFlagsConfiguration(featureFlags.customer.editOpportunity.basket.bundleDiscount);

  const newOpportunityButton = getFeatureFlagsConfiguration('customer.customerView.newOpportunityButton');

  const featureFlag = JSON.stringify({
    opportunity: {
      bundleDiscount,
      newOpportunityButton,
      ...opportunityFeatures,
    },
    quote: opportunityPolicyFeatures,
  });
  const policyStatusTranslations = t('portal:policyStatus', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const opportunityTranslations = t('portal:widgets.opportunitiesList', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const quoteTranslations = t('portal:widgets.quoteList', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });

  const translations = JSON.stringify({
    quote: quoteTranslations,
    opportunity: opportunityTranslations,
    policyStatus: policyStatusTranslations,
  });

  const styles = JSON.stringify({
    components: {
      WidgetCard: {
        contentContainerPadding: '14px',
      },
    },
  });

  useEffect(() => {
    const handleReturnMessages = (event) => {
      const { errors, messages } = event.detail;

      if (errors) {
        showMultipleToasts(errors);
      }

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    const handleNewOpportunityClick = () => {
      history.push(newOpportunityPath(id));
    };

    const editQuote = (event) => {
      const { uuid } = event.detail;

      history.push(editQuotePath(id, uuid));
    };

    const editOpportunity = (event) => {
      const { opportunityId } = event.detail;

      history.push(editOpportunityPath(id, opportunityId));
    };

    const handleSuccess = (event) => {
      switch (event.type) {
        case 'opportunity-successful-print':
          openNotification({
            message: t('widgets.opportunityBasket.messages.messageAtPrintSuccesful'),
            severityLevel: 'success',
            title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          });
          break;

        case 'opportunity-successful-duplicate':
          openNotification({
            message: t('widgets.opportunityBasket.messages.messageDuplicationSuccesful'),
            severityLevel: 'success',
            title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          });
          break;

        case 'opportunity-successful-cancel':
          openNotification({
            message: t('widgets.opportunityBasket.messages.messageCancelSuccessful'),
            severityLevel: 'success',
            title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          });
          break;

        case 'opportunity-successful-setinforce':
          openNotification({
            message: t('widgets.opportunityBasket.messages.setBasketInForceSuccesful'),
            severityLevel: 'success',
            title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-print':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtPrintSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-accept':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtAcceptSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-cancel':
          openNotification({
            message: t('widgets.quoteList.messages.cancelQuoteSuccessful'),
            severityLevel: 'success',
            title: t('widgets.quoteList.messages.messageTitleSuccess'),
          });
          break;

        case 'quote-successful-setinforce':
          openNotification({
            message: t('widgets.quoteList.quoteOptions.messageAtSetInForceSuccess'),
            severityLevel: 'success',
            title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
          });
          break;

          case 'quote-successful-add-note':
              openNotification({
                  message: t('widgets.quoteList.messages.createQuoteNoteSuccessful'),
                  severityLevel: 'success',
                  title: t('widgets.quoteList.quoteOptions.messageTitleSuccess'),
              });
              break;
      }

      const { messages } = event.detail;

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('new-opportunity', handleNewOpportunityClick);

      widgetRef.current.addEventListener('quote-edit', editQuote);
      widgetRef.current.addEventListener('quote-successful-print', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-accept', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-cancel', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-setinforce', handleSuccess);
      widgetRef.current.addEventListener('quote-successful-add-note', handleSuccess);

      widgetRef.current.addEventListener('opportunity-edit', editOpportunity);
      widgetRef.current.addEventListener('opportunity-successful-print', handleSuccess);
      widgetRef.current.addEventListener('opportunity-successful-duplicate', handleSuccess);
      widgetRef.current.addEventListener('opportunity-successful-cancel', handleSuccess);
      widgetRef.current.addEventListener('opportunity-successful-setinforce', handleSuccess);

      widgetRef.current.addEventListener('quotes-error', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-print', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-accept', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-cancel', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-setinforce', handleReturnMessages);
      widgetRef.current.addEventListener('quote-error-add-note', handleReturnMessages);

      widgetRef.current.addEventListener('opportunities-error', handleReturnMessages);
      widgetRef.current.addEventListener('opportunity-error-print', handleReturnMessages);
      widgetRef.current.addEventListener('opportunity-error-duplicate', handleReturnMessages);
      widgetRef.current.addEventListener('opportunity-error-cancel', handleReturnMessages);
      widgetRef.current.addEventListener('opportunity-error-setinforce', handleReturnMessages);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('new-opportunity', handleNewOpportunityClick);

        widgetRef.current.removeEventListener('quote-edit', editQuote);
        widgetRef.current.removeEventListener('quote-successful-print', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-accept', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-cancel', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-setinforce', handleSuccess);
        widgetRef.current.removeEventListener('quote-successful-add-note', handleSuccess);

        widgetRef.current.removeEventListener('opportunity-edit', editOpportunity);
        widgetRef.current.removeEventListener('opportunity-successful-print', handleSuccess);
        widgetRef.current.removeEventListener('opportunity-successful-duplicate', handleSuccess);
        widgetRef.current.removeEventListener('opportunity-successful-cancel', handleSuccess);
        widgetRef.current.removeEventListener('opportunity-successful-setinforce', handleSuccess);

        widgetRef.current.removeEventListener('quotes-error', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-print', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-accept', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-cancel', handleReturnMessages);
        widgetRef.current.removeEventListener('quote-error-setinforce', handleReturnMessages);

        widgetRef.current.removeEventListener('opportunities-error', handleReturnMessages);
        widgetRef.current.removeEventListener('opportunity-error-print', handleReturnMessages);
        widgetRef.current.removeEventListener('opportunity-error-accept', handleReturnMessages);
        widgetRef.current.removeEventListener('opportunity-error-cancel', handleReturnMessages);
        widgetRef.current.removeEventListener('opportunity-error-setinforce', handleReturnMessages);
      }
    };
  }, [id]);

  return (
    <ds-portfolio-opportunity-widget
      ref={widgetRef}
      variant={'opportunity'}
      partyId={id}
      translations={translations}
      featureFlag={featureFlag}
      styleOverrides={styles}
    />
  );
});

Opportunities.propTypes = {
  id: string.isRequired,
};

export default Opportunities;
