import React, { Component } from 'react';
import { oneOfType, object, arrayOf } from 'prop-types';
import { Layout } from 'antd';
import LeftMenu from './LeftMenu/LeftMenu';
import TopMenu from './TopMenu/TopMenu';
import PropTypes from 'prop-types';

class WithMenus extends Component {
  state = {
    collapsed: false,
  };

  toggle = () =>
    this.setState((prevState) => ({
      collapsed: !prevState.collapsed,
    }));

  render() {
    const { children } = this.props;
    const { collapsed } = this.state;

    const paths = children.props.children.map((c) => c.key);

    return (
      <Layout style={{ minHeight: '100vh' }}>
        <LeftMenu collapsed={collapsed} toggle={this.toggle} rerender={this.props.rerender} />
        <Layout>
          <TopMenu collapsed={collapsed} toggle={this.toggle} paths={paths} />
          <Layout.Content style={{ padding: '15px' }}>{children}</Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

WithMenus.propTypes = {
  children: oneOfType([object, arrayOf(object)]).isRequired,
  rerender: PropTypes.bool,
};

export default WithMenus;
