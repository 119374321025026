"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAgentConfig = void 0;
var API_URL = 'v1/platform-configurations';
var getAgentConfig = function () {
    return {
        url: "".concat(API_URL, "/agent"),
        method: 'GET',
    };
};
exports.getAgentConfig = getAgentConfig;
