import { createSelector } from 'reselect';
import { getPoliciesEntities } from '../../api/policies/selectors';
import { getPolicyStatusColor } from './helpers';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../emptyState';
import { getPartiesEntities } from '../../api/parties/selectors';
import { formatPriceDecimals } from '../../../utils/priceUtils';

/**
 * Get recently viewed customers ID
 */
const getRecentlyViewedCustomerId = (state) => state.pages.customer.recentlyViewedCustomerId;

/**
 * Get an object tree of a customer relation which relates to customers claims, quotes, policies
 */
const getCustomerRelation = (state) => state.pages.customer.relation;

/**
 * Get customer relation object per customer
 */
const getCustomerRelationObject = createSelector(
  getRecentlyViewedCustomerId,
  getCustomerRelation,
  (customerId, customerObject) => customerObject[customerId]
);

/**
 * Get policy IDs from customer relation object
 */
const getPoliciesIds = createSelector(
  getCustomerRelationObject,
  (customerRelationObject) => customerRelationObject && customerRelationObject.policyIds
);

/**
 * Get customer details from entities by customers ID
 */
export const getCustomerDetails = createSelector(
  getPartiesEntities,
  getRecentlyViewedCustomerId,
  (partyDetails = EMPTY_OBJECT, id) => (partyDetails[id] ? partyDetails[id] : EMPTY_OBJECT)
);

/**
 * Get policies from entities by policy IDs filtered by policy status
 */
export const getPoliciesList = createSelector(
  getPoliciesEntities,
  getPoliciesIds,
  (policies = EMPTY_ARRAY, policyIds = EMPTY_ARRAY) =>
    policyIds.reduce((acc, id) => {
      if (!policies[id]) {
        return EMPTY_ARRAY;
      }

      return [
        ...acc,
        {
          id: getUniqueEntityId(policies[id]),
          sipasUuid: policies[id].sipasUuid,
          policySeqNo: policies[id].policySeqNo,
          description: policies[id].sipasProdName,
          subtitle: `${formatPriceDecimals(policies[id].price)} ${policies[id].currency}`,
          color: getPolicyStatusColor(policies[id].policyStatus),
          policyStatus: policies[id].policyStatus,
          renewalDate: policies[id].renewalDate,
          coverStartDate: policies[id].coverStartDate,
          policyAdditionalInfo: policies[id].policyAdditionalInfo,
        },
      ];
    }, [])
);

/** Generates unique entity id */
const getUniqueEntityId = (entity) => {
  const quoteId = Array.isArray(entity.id) ? entity.id.join('') : `${entity.id}`;

  return `${quoteId}${entity.sipasUuid}`;
};
