import getSearchParams from './getSearchParams';

export const getAuthOptions = () => {
  const {
    issuer,
    clientID,
    audience,
    additionalAuthOptions,
    overrides,
    tokenEndpoint,
    authorizationEndpoint,
    userinfoEndpoint,
    logoutEndpoint,
    jwksUri,
    revocationEndpoint,
    clientSecret,
  } = window.agentEnv;

  const allAdditionalOptions = { ...additionalAuthOptions, ...getSearchParams() };

  return {
    issuer: `${issuer}`,
    clientID,
    audience,
    additionalAuthOptions: allAdditionalOptions,
    redirectUri: `${window.agentEnv.contextLocation}/callback`,
    successfulLogoutUri: `${window.agentEnv.contextLocation}/logout`,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    tokenKey: ['access_token', 'tiaagent_access_token', 'ds_crm_access_token'],
    overrides: overrides,
    tokenEndpoint: `${tokenEndpoint}`,
    authorizationEndpoint: `${authorizationEndpoint}`,
    userinfoEndpoint: `${userinfoEndpoint}`,
    logoutEndpoint: `${logoutEndpoint}`,
    jwksUri: `${jwksUri}`,
    revocationEndpoint: `${revocationEndpoint}`,
    clientSecret: `${clientSecret}`,
  };
};
