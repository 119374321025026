export const COLOR_GREEN = 'green';
export const COLOR_GREY = 'grey';
export const COLOR_NO_COLOR = '';
export const COLOR_BLUE = 'blue';
export const COLOR_RED = 'red';
export const COLOR_ORANGE = 'orange';

export const STATUS_PAID = 'Paid';
export const STATUS_SENT = 'Sent';
export const STATUS_UNPAID = 'Unpaid';
export const STATUS_REMINDED = 'Reminded';

export const QUOTE = 'QUOTE';
export const DECLINED_QUOTE = 'DECLINED_QUOTE';
export const REFERRED_QUOTE = 'REFERRED_QUOTE';
export const SUSPENDED_QUOTE = 'SUSPENDED_QUOTE';
export const POLICY = 'POLICY';
export const REFERRED_POLICY = 'REFERRED_POLICY';
export const SUSPENDED_POLICY = 'SUSPENDED_POLICY';
export const QUOTE_ON_POLICY = 'QUOTE_ON_POLICY';
export const QUOTE_ON_RENEWAL = 'QUOTE_ON_RENEWAL';
export const CANCELLED_POLICY = 'CANCELLED_POLICY';
export const SUSPENDED_QUOTE_PROP = 'SUSPENDED_QUOTE_PROP';
export const UNKNOWN = 'UNKNOWN';

export const ICON_TYPE_DOLLAR = 'dollar';
export const ICON_TYPE_CALENDAR = 'calendar';

export const CLAIM_STATUS_CLOSED = 'Closed';
export const CLAIM_STATUS_OPEN = 'Open';
export const CLAIM_STATUS_FINISHED = 'Finished';

export const PAYMENT_STATUS_WARNING = 'Warning';
