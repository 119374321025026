import React, { useMemo, useState } from 'react';
import { Modal } from './Modal';
import { useAtom } from 'jotai';
import { agentFirmIdAtom } from '../../atoms/atoms';
import { ComboBox } from '@tia/ui-components';
import { useModal } from '../../hooks/useModal';
import { State } from '../../configureStore';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

export type AgentFirms = { firmId: string, firmName: string };


type AgencySwitchModalProps = {
  agencies: AgentFirms[],
  name: string,
  setIsAgencySwitching: (value: boolean) => void,
  isAgencySwitching: boolean,
  closable?: boolean
};

export const AgencySwitchModal = ({ agencies, name, setIsAgencySwitching, isAgencySwitching, closable = true, }: AgencySwitchModalProps) => {
  const { closeModal } = useModal(name);
  const [agentFirmId, setAgentFirmId] = useAtom(agentFirmIdAtom);


  const options = useMemo(() =>
    agencies.map(({ firmId, firmName }) => ({ value: firmId, label: `${firmName} - ${firmId}` })),
    [agencies]
  );

  const defaultOption = useMemo(() =>
    options.find(option => option.value === agentFirmId),
    [options, agentFirmId]
  );

  const [selectedAgentFirmId, setSelectedAgentFirmId] = useState<string>(defaultOption?.value as string ?? '');
  const { t } = useTranslation();

  return <Modal
    closable={closable}
    modalName={name}
    title={t('modals.agencySwitchModal.title')}
    okText={t('modals.agencySwitchModal.okText')}
    cancelText={name === 'agencySwitch' ? t('modals.agencySwitchModal.cancelText') : ''}
    onClose={closeModal}
    onOk={() => {
      setIsAgencySwitching(true)

      if (agentFirmId !== selectedAgentFirmId) {
        setAgentFirmId(selectedAgentFirmId === '' ? defaultOption?.value as string : selectedAgentFirmId);
      }
      const newUrl = window.agentEnv?.contextPath ? `${window.location.origin}/${window.agentEnv?.contextPath}/` : `${window.location.origin}/`;
      window.location.assign(newUrl);
    }
    }
    okButtonProps={{ disabled: agentFirmId === selectedAgentFirmId || selectedAgentFirmId === '' }}

  >
    <ComboBox
      size="default"
      onSelect={(value: string) => setSelectedAgentFirmId(value)}
      value={selectedAgentFirmId || ''}
      fullWidth={true}
      options={options}
    />
    <div style={{ minHeight: '20px', marginTop: '8px' }}>
      <span>
        {agentFirmId &&
          !isAgencySwitching &&
          `${t('modals.agencySwitchModal.currentAgencyText')}: `}
        <b>
          {isAgencySwitching
            ? t('modals.agencySwitchModal.settingAgencyText')
            : agentFirmId && defaultOption?.label}
        </b>
      </span>
    </div>
  </Modal >
}

const mapStateToProps = (state: State) => ({
  agencies: state.api.configurations.agentFirms.data
});

export default (connect(mapStateToProps)(AgencySwitchModal));
