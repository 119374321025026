"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.queryClient = void 0;
var query_core_1 = require("@tanstack/query-core");
/**
 * Default Options
 * ```
 * {
 *   queries: {
 *      // 5 seconds is something what should be enough to prevent duplicate calls
 *      // Yet short enough to be able fetch new data when it exists
 *      // https://tkdodo.eu/blog/practical-react-query#the-defaults-explained
 *      staleTime: 5000,
 *      cacheTime: 30000,
 *      // By default we're not refetching, as we're not using query properly
 *      // At the moment it's only mere wrapper to prevent duplicate API calls
 *      // In future, it should be used as supposed - enabled by default, and only disabled where it's relavant
 *      // https://tanstack.com/query/v4/docs/react/guides/window-focus-refetching
 *      refetchOnWindowFocus: false,
 *      refetchOnReconnect: false,
 *      refetchOnMount: true,
 *    },
 *    mutations: {
 *      cacheTime: 30000
 *    }
 *  }
 *  ```
 */
exports.queryClient = "agentQueryClient" in window ? window.agentQueryClient : (window.agentQueryClient = new query_core_1.QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5000,
            cacheTime: 30000,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: true,
        },
        mutations: {
            cacheTime: 30000
        }
    }
}));
