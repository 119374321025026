import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DownOutlined, LogoutOutlined, UserOutlined, RetweetOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import { getUser } from '../../utils/idToken';
import { useTiaAuth } from '@tia/authenticator';
import { PATH_LOGOUT } from '../../router/paths';
import './UserActions.scss';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { AgencySwitchModal } from '../../components/Modal/AgencySwitchModal';
import { useModal } from '../../hooks/useModal';
import store from '../../configureStore';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



const UserActions = (props) => {
  const { agencies } = props;
  const user = useMemo(getUser, []);
  const { t } = useTranslation();
  const { logout } = useTiaAuth();
  const userName = user ? user.name : <UserOutlined />;
  const { openModal: openAgencySwitchModal } = useModal('agencySwitch');
  const [isAgencySwitching, setIsAgencySwitching] = useState(false);

  const menu = () => {
    const menuItems = [];

    agencies?.length > 1 && menuItems.push({
      label: (
        <div data-cy="switch-agency-button" onClick={openAgencySwitchModal} onKeyDown={openAgencySwitchModal}>
          <RetweetOutlined style={{ marginRight: '12px' }} />
          {t('menus.topMenu.dropdown.switchAgency')}
        </div>
      ),
      key: 'switch-agency',
    });

    menuItems.push({
      label: (
        <Link data-cy="log-out-button" to={PATH_LOGOUT} onClick={() => logout()}>
          <LogoutOutlined style={{ marginRight: '12px' }} />
          {t('menus.topMenu.dropdown.logOut')}
        </Link>
      ),
      key: 'logout-button',
    });

    return (
      <>
        <Menu
          style={{ marginTop: '5px' }}
          items={menuItems}
        ></Menu>
        {agencies?.length > 1 && <AgencySwitchModal
          name='agencySwitch'
          agencies={agencies}
          isAgencySwitching={isAgencySwitching}
          setIsAgencySwitching={setIsAgencySwitching}
        />}
      </>

    );
  };

  return (
    <>
      <Dropdown key="1" trigger={['click']} placement="bottomLeft" overlay={menu()}>
        <span data-cy="user-dropdown" style={nameStyle}>
          {userName}
          <DownOutlined style={{ color: '#007A84', marginLeft: '10px' }} />
        </span>
      </Dropdown>
    </>
  );
};

const nameStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  cursor: 'pointer',
};

UserActions.propTypes = { agencies: PropTypes.array };

const mapStateToProps = (state) => ({
  agencies: state.api.configurations.agentFirms.data,
});

export default (connect(mapStateToProps)(UserActions));
