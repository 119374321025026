import React, { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, Modal, Radio, Button } from 'antd';
import { apiConnector } from '../../utils/apiConnector';
import CustomUiWidget from '../../widgets/CustomUiWidget/CustomUiWidget.widget';
import { openNotification } from '../../utils/apiSetup';
import { useQuery } from '@tanstack/react-query';
import { RadioChangeEvent } from 'antd/lib/radio';

interface Props {
  showPaymentModal: boolean;
  showCustomWidget: boolean;
  partyId: string;
  handleShowCustomWidget: () => void;
  closePaymentModal: () => void;
}
const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

const CreatePaymentDetailComponent = memo(function CreatePaymentDetailComponent({
  showPaymentModal,
  showCustomWidget,
  closePaymentModal,
  handleShowCustomWidget,
  partyId,
}: Props) {
  const { data } = useQuery({
    queryKey: ['account', 'getPaymentMethods'],
    queryFn: () => {
      return apiConnector().account.getPaymentMethods();
    },
  });
  const paymentMethods = data as unknown as string[];

  const { t } = useTranslation();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');

  const handleContinue = () => {
    handleShowCustomWidget();
  };
  const handleCancel = () => {
    closePaymentModal();
  };
  const handleCustomUiSubmit = async (event: CustomEvent) => {
    const formValues = event.detail;

    if (!partyId) {
      openNotification({ message: t('widgets.createPaymentDetail.messages.errorNoPartyID'), severityLevel: 'error' });

      return;
    }

    try {
      const resData = await apiConnector().uiConfiguration.updateEntities(
        `PaymentDetails_${selectedPaymentMethod}`,
        formValues,
        { partyId } as any
      );

      if (resData && !resData.data) {
        openNotification({
          message: t('widgets.createPaymentDetail.messages.creationEventFailed'),
          severityLevel: 'error',
          title: resData.error[0].errorCode + ' - ' + resData.error[0].message,
        });

        return;
      }

      openNotification({
        message: t('widgets.createPaymentDetail.messages.creationEventSuccesful'),
        severityLevel: 'success',
        title: ' ',
      });
      closePaymentModal();
    } catch (error) {
      console.error(error);
      openNotification({ message: t('widgets.createPaymentDetail.messages.errorUnspecified'), severityLevel: 'error' });
    }
  };
  const onChange = (e: RadioChangeEvent) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const modalFooter = useMemo(
    () =>
      !showCustomWidget
        ? [
          <Button key="cancel" onClick={handleCancel}>
            {t('widgets.createPaymentDetail.cancelButton').toString()}
          </Button>,
          <Button key="Continue" type="primary" onClick={handleContinue} disabled={!selectedPaymentMethod}>
            {t('widgets.createPaymentDetail.continueButton').toString()}
          </Button>,
        ]
        : null,
    [showCustomWidget, handleCancel, handleContinue, selectedPaymentMethod]
  );

  const modalContent = useMemo(() => {
    return !showCustomWidget ? (
      <Radio.Group onChange={onChange} value={selectedPaymentMethod}>
        {paymentMethods?.length
          ? paymentMethods.map((paymentMethod) => {
            const paymentMethodText = t(`widgets.createPaymentDetail.paymentMethods.${paymentMethod}`, paymentMethod);

            return (
              <Radio style={radioStyle} key={paymentMethod} value={paymentMethod}>
                {paymentMethodText}
              </Radio>
            );
          })
          : null}
      </Radio.Group>
    ) : (
      <CustomUiWidget
        configName={`PaymentDetails_${selectedPaymentMethod}`}
        partyId={partyId}
        handleCustomUiSubmit={handleCustomUiSubmit}
        handleCancel={handleCancel}
      />
    );
  }, [showCustomWidget, onChange, selectedPaymentMethod, handleCustomUiSubmit, handleCancel]);

  return (
    <Layout.Content style={{ marginTop: '10px' }}>
      <Modal
        title={showCustomWidget ? null : t('widgets.createPaymentDetail.widgetTitle').toString()}
        closable={false}
        open={showPaymentModal}
        onOk={handleContinue}
        onCancel={handleCancel}
        zIndex={3}
        footer={modalFooter}
        okText={t('widgets.createPaymentDetail.okText').toString()}
        cancelText={t('widgets.createPaymentDetail.cancelText').toString()}>
        {modalContent}
      </Modal>
    </Layout.Content>
  );
});

export const CreatePaymentDetail = CreatePaymentDetailComponent;
