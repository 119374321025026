import { idTokenAtom } from '../atoms/atoms';
import { jotaiStore } from '../root';

const customClaim = 'https://tiatechnology.com/identities';

let agentFirmToken = null;

export const getAgentFirm = () => {
  if (!agentFirmToken) {
    agentFirmToken = getToken();
  }

  if (!agentFirmToken?.[customClaim]) {
    return null;
  }

  const agentFirmClaim = agentFirmToken[customClaim].find((element) => element.type === 'agentfirm');

  if (agentFirmClaim && agentFirmClaim.user_id) {
    return agentFirmClaim.user_id;
  }

  return null;
};

export const getUser = () => {
  const token = getToken();

  if (token && token[`${customClaim}/user`]) {
    return token[`${customClaim}/user`];
  }
};

export const getToken = () => {
  const idToken = jotaiStore.get(idTokenAtom);

  if (!idToken) {
    return;
  }

  try {
    return parseJwt(idToken);
  } catch (e) {
    console.error('Failed to parse id token: ', idToken);
  }
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');

  return JSON.parse(window.atob(base64));
};
