import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table, Row, Col } from 'antd';
import ExtraContent from './ExtraContent';
import WidgetCard from '../../components/WidgetCard/WidgetCard';
import { customerDetailsPath } from '../../router/paths';
import { _getParties as getParties } from '../../state/api/parties/actionCreators';
import { getPartiesSelector } from '../../state/pages/customers/selectors';
import { updatePartiesSearchQuery, updatePartiesPageNumber } from '../../state/pages/customers/actionCreators';

class PartyTable extends PureComponent {
  componentDidMount() {
    this.fetchParties();
  }

  async fetchParties() {
    try {
      await this.props.getParties();
    } catch (err) {
      console.error('Error: ', err);
    }
  }

  // Checks if it is the actual record that is being clicked or one of
  // the action buttons, redirect if it is a record.
  // The selectedCustomer state defines if a customer has been selected in the table and then initiates a redirect
  // Link to redirect pattern: https://tylermcginnis.com/react-router-programmatically-navigate/
  redirect(e, id) {
    if (e.target.type !== 'button') {
      this.props.history.push(customerDetailsPath(id));
    }
  }

  render() {
    const {
      t,
      customers,
      contentCol,
      status,
      searchedValue,
      pagination: { currentPage, size, totalElements },
    } = this.props;

    const customersTranslated = customers.map((customer) => {
      customer.partyTypeDesc = t(`widgets.customerList.rows.partyType.${customer.partyType}`);

      return customer;
    });

    return (
      <WidgetCard
        bordered
        httpStatus={status}
        title={t('widgets.customerList.title')}
        style={{ marginTop: 24 }}
        bodyStyle={{ padding: 0 }}
        extra={<ExtraContent value={searchedValue} updateSearch={this.props.updatePartiesSearchQuery} />}>
        <Row gutter={0}>
          <Col {...contentCol}>
            <Table
              dataSource={customersTranslated}
              columns={getColumnsLarge(t)}
              onRow={(record) => ({
                onClick: (e) => this.redirect(e, record.id),
              })}
              pagination={{
                showSizeChanger: false,
                showQuickJumper: false,
                pageSize: size,
                total: totalElements,
                onChange: this.props.updatePartiesPageNumber,
                current: currentPage,
              }}
              rowKey={(record) => record.id}
              loading={this.props.isFetching}
              style={{ cursor: 'pointer' }}
              locale={{ emptyText: t('widgets.customerList.emptyText') }}
            />
          </Col>
        </Row>
      </WidgetCard>
    );
  }
}

const getColumnsLarge = (t) => {
  return [
    {
      title: t('widgets.customerList.columns.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('widgets.customerList.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('widgets.customerList.columns.partyType'),
      dataIndex: 'partyTypeDesc',
      key: 'partyTypeDesc',
    },
    {
      title: t('widgets.customerList.columns.companyRegNo'),
      dataIndex: 'companyRegNo',
      key: 'companyRegNo',
    },
  ];
};

PartyTable.propTypes = {
  customers: PropTypes.array.isRequired,
  contentCol: PropTypes.object,
  getParties: PropTypes.func.isRequired,
  history: PropTypes.object,
  isFetching: PropTypes.bool,
  t: PropTypes.func,
  status: PropTypes.number,
  searchedValue: PropTypes.string,
  pagination: PropTypes.object,
  updatePartiesSearchQuery: PropTypes.func,
  updatePartiesPageNumber: PropTypes.func,
};

PartyTable.defaultProps = {
  contentCol: { xs: 24 },
};

const mapStateToProps = (state) => ({
  isFetching: state.api.parties.isFetching,
  pagination: state.pages.customers.pagination,
  searchedValue: state.pages.customers.searchedValue,
  status: state.pages.customers.status,
  customers: getPartiesSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParties,
      updatePartiesSearchQuery,
      updatePartiesPageNumber,
    },
    dispatch
  );

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(PartyTable)));
