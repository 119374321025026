import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './state/rootReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];
const appliedMiddlewares =
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middlewares)
    : composeEnhancers(applyMiddleware(...middlewares));

const configureStore = () => createStore(rootReducer, appliedMiddlewares);
const store = configureStore();
export type State = ReturnType<typeof rootReducer>;
export type Store = typeof store;

export default store;
