"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPolicies = exports.getPolicyTimeline = void 0;
var API_URL = 'v1/policies';
var getPolicyTimeline = function (sipasUuid) {
    return {
        url: "".concat(API_URL, "/").concat(sipasUuid, "/get-policy-timeline"),
        method: 'GET',
    };
};
exports.getPolicyTimeline = getPolicyTimeline;
var getPolicies = function (params) {
    return {
        url: "".concat(API_URL, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getPolicies = getPolicies;
