import MENU_ITEMS from './menuItems';
import ROLE_ACCESS_RIGHTS from './roles';
import ROUTER_CONFIG from './routesConfig';
import { getScope } from '../utils/accessToken';

const getMenuTitleWithRoute = (route) =>
  ROUTER_CONFIG[route] &&
  MENU_ITEMS[route] && {
    ...ROUTER_CONFIG[route],
    ...MENU_ITEMS[route],
  };

const getExistingMenuItems = (routes) => routes.map((route) => getMenuTitleWithRoute(route)).filter((item) => item);

export const getMenuItemsListBasedOnRoles = () => {
  const combinedRoutes = getCombinesRoutes();
  const existingMenuItems = getExistingMenuItems(combinedRoutes);
  const menuStructure = [];

  existingMenuItems.forEach((route) => {
    // All regular menu elements are added
    if (!route.parentMenuItem) {
      menuStructure.push(route);
    } else {
      // Does the parent element exist?
      const menuIndex = menuStructure.findIndex((m) => m.title === route.parentMenuItem.title);

      // If not create element and add submenu item
      if (menuIndex < 0) {
        menuStructure.push({
          ...route.parentMenuItem,
          subMenus: [route],
        });
      } else {
        // Else add only the submenu to the parent element
        menuStructure[menuIndex].subMenus = [...menuStructure[menuIndex].subMenus, route];
      }
    }
  });

  return menuStructure;
};

export const getRoutesBasedOnRoles = () => {
  const combinedRoutes = getCombinesRoutes();

  return combinedRoutes.map((route) => ROUTER_CONFIG[route]);
};

const getCombinesRoutes = () => {
  const scope = getScope(true);
  let combinedRoutes = [];

  Object.keys(ROLE_ACCESS_RIGHTS).forEach((key) => {
    if (scope.indexOf(key) > -1) {
      combinedRoutes = [...new Set(ROLE_ACCESS_RIGHTS[key])];
    }
  });

  return combinedRoutes;
};
