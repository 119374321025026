"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var Policy_1 = require("../api/Policy");
var UrlParam_1 = require("../helpers/UrlParam");
var PolicyConnector = /** @class */ (function () {
    function PolicyConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    /**
     * Returns policy timeline
     */
    PolicyConnector.prototype.getPolicyTimeline = function (sipasUuid) {
        var requestConfig = (0, Policy_1.getPolicyTimeline)(sipasUuid);
        return (0, fetchRequest_1.fetchRequestBase)(requestConfig, this.baseUrl)
            .then(function (r) { return r.json(); })
            .then(function (response) {
            return {
                data: response.data,
                successful: response.successful,
                error: response.errors,
                message: response.messages,
            };
        });
    };
    /**
     * Returns policies for a party
     */
    PolicyConnector.prototype.getPolicies = function (partyId, policyStatus, excludeOpportunityPolicies) {
        var params = new URLSearchParams();
        var pagination = { currentPage: '1', size: '50' };
        (0, UrlParam_1.appendParam)(params, 'partyId', partyId);
        (0, UrlParam_1.appendParam)(params, 'page', pagination.currentPage);
        (0, UrlParam_1.appendParam)(params, 'size', pagination.size);
        policyStatus.forEach(function (status) {
            (0, UrlParam_1.appendParam)(params, 'policyStatus', status);
        });
        if (excludeOpportunityPolicies !== undefined) {
            (0, UrlParam_1.appendParam)(params, 'excludeOpportunityPolicies', excludeOpportunityPolicies ? 'true' : 'false');
        }
        var requestConfig = (0, Policy_1.getPolicies)(params);
        return (0, fetchRequest_1.fetchRequestBase)(requestConfig, this.baseUrl)
            .then(function (r) { return r.json(); })
            .then(function (response) {
            return {
                data: response.data,
                successful: response.successful,
                error: response.errors,
                message: response.messages,
            };
        });
    };
    return PolicyConnector;
}());
exports.default = PolicyConnector;
