"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPaymentMethodsUsingGET = void 0;
var API_URL = 'v1/accounts';
var getPaymentMethodsUsingGET = function () {
    return {
        url: "".concat(API_URL, "/payment-methods"),
        method: 'GET',
    };
};
exports.getPaymentMethodsUsingGET = getPaymentMethodsUsingGET;
