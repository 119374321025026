import { atomWithStorage } from 'jotai/utils';
import featureFallbackFlags from './../../src/utils/feature-flag-external-fallback.json';


export const idTokenAtom = atomWithStorage('id_token', '');
export const accessTokenAtom = atomWithStorage('access_token', '');
export const featureFlagsChangeAtom = atomWithStorage<Partial<typeof featureFallbackFlags | undefined>>('feature_flags_change_atom', undefined);

export const agentAccessTokenAtom = atomWithStorage('tiaagent_access_token', '');
export const agentApiEndpointAtom = atomWithStorage('agent_endpoint', process.env.REACT_APP_API_URI);

export const crmApiEndpointAtom = atomWithStorage('ds_crm_endpoint', process.env.REACT_APP_CRM_API_URI);
export const claimApiEndpointAtom = atomWithStorage('claims_api_endpoint', process.env.REACT_APP_CLAIM_URI);
export const spdSalesUiApiAtom = atomWithStorage('spd_sales_ui_api', process.env.REACT_APP_SALES_UI_API);
export const uiConfigApiEndpointAtom = atomWithStorage('uiconfig_api_endpoint', process.env.REACT_APP_UI_CONFIG_API_URL);

export const crmAccessTokenAtom = atomWithStorage('ds_crm_access_token', '');

export const thousandSeparatorAtom = atomWithStorage('thousand_separator', ',');
export const decimalSeparatorAtom = atomWithStorage('decimal_separator', '.');

export const dateFormatAtom = atomWithStorage('dateFormat', 'YYYY-MM-DD');

export const utcDateFormatAtom = atomWithStorage('utcDateFormat', 'yyyy-MM-dd');
export const utcDateTimeFormatAtom = atomWithStorage('utcDateTimeFormat', 'yyyy-MM-dd HH:mm');
export const dateLocaleAtom = atomWithStorage('dateLocale', 'en-US');

export const lastVisitedAtom = atomWithStorage('last_visited', '');

export const isDebugLoggingEnabledAtom = atomWithStorage('is_debug_logging_enabled', false);
export const agentFirmIdAtom = atomWithStorage('agent_firm_id', '');
