import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Row, Col, Layout, Modal, Button, Tabs, Space } from 'antd';
import { string, func, object } from 'prop-types';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import Quotes from '../../widgets/Quotes/Quotes.widget';
import Policies from '../../widgets/Policies/Policies.widget';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { _getPartyById as getPartyById } from '../../state/api/party/actionCreators';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { customerDetailsPath, customerDetailsPaymentPath } from '../../router/paths';
import Opportunities from '../../widgets/Opportunities/Opportunities.widget';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { Documents } from '../../widgets/DocumentsWidget/Documents.widget';
import { RelationWidget } from '../../widgets/RelationWidget/RelationWidget';
import { SearchMode } from '@tia/agent-api-connector/dist/enums/SearchMode';
import Payments from '../Payments/Payment';
import { ProfileOutlined, EuroCircleOutlined } from '@ant-design/icons';
import './Customer.style.scss';

class Customer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      redirect: false,
      showPaymentModal: false,
      showCustomWidget: false,
      currentTab: 'portfolio',
    };

    this.fetchAssets();
  }

  handleAddPaymentDetails = () => {
    this.setState({
      showPaymentModal: true,
    });
  };

  handleShowCustomWidget = () => {
    this.setState({
      showCustomWidget: true,
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      showCustomWidget: false,
    });
  };

  async fetchAssets() {
    const { match, setRecentlyViewedCustomer } = this.props;
    const { customerid } = match.params;

    setRecentlyViewedCustomer(customerid);

    try {
      getPartyById(customerid);
    } catch (err) {
      console.error('Error: ', err);
    }
  }

  handleEditRelation = (customerId) => {
    this.props.history.push(customerDetailsPath(customerId.toString()));
    this.props.match.params.customerid = customerId.toString();
    this.fetchAssets();
  };

  componentDidMount() {
    if (this.props.location.pathname === customerDetailsPaymentPath(this.props.match.params.customerid)) {
      this.setState({ currentTab: 'payments' });
    }
  }

  componentDidUpdate() {
    if (
      getFeatureFlagsConfiguration(featureFlags.customer.customerView.paymentTab) &&
      this.props.location.pathname === customerDetailsPath(this.props.match.params.customerid)
    ) {
      this.setState({ currentTab: 'portfolio' });
    }
  }

  render() {
    const { t, match } = this.props;

    const { customerid } = match.params;

    if (this.state.redirect) {
      return <Redirect to="/customers" />;
    }

    const policyCancelFeature = getFeatureFlagsConfiguration(featureFlags.customer.customerView.policyActions.cancel);
    const policyViewFeature = getFeatureFlagsConfiguration(featureFlags.customer.customerView.policyActions.view);
    const addNoteToPolicyFeature = getFeatureFlagsConfiguration(featureFlags.customer.customerView.policyActions.addNoteToPolicy);

    const documentFeature = getFeatureFlagsConfiguration('customer.documentsWidget.customer.view');

    documentFeature.disableMergeCheckbox = true;
    documentFeature.disableMergeButton = true;

    const handleTabChange = (e) => {
      this.setState({ currentTab: e });
      if (e === 'payments') {
        this.props.history.push(customerDetailsPaymentPath(customerid));
      } else {
        this.props.history.push(customerDetailsPath(customerid));
      }
    };

    const items = [
      {
        key: 'portfolio',
        label: (
          <div data-cy="customer-tab-portfolio">
            <ProfileOutlined /> {t('pages.customer.tabs.portfolio')}
          </div>
        ),
      },
      {
        key: 'payments',
        label: (
          <div data-cy="customer-tab-payments">
            <EuroCircleOutlined /> {t('pages.customer.tabs.payments')}
          </div>
        ),
      },
    ];

    return (
      <Layout.Content style={{ marginTop: '10px' }}>
        <Modal title={t('pages.customer.errorModal.title')} closable={false} open={this.state.showModal} footer={null}>
          {t('pages.customer.errorModal.text')}
          <div style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={() => this.setState({ redirect: true, showModal: false })}>
              {t('pages.customer.errroModal.buttonText')}
            </Button>
          </div>
        </Modal>
        <FeatureFlag featureFlag={featureFlags.customer.customerView.paymentTab}>
          <Tabs items={items} activeKey={this.state.currentTab} onChange={handleTabChange} type="card" />
        </FeatureFlag>
        <Row gutter={40}>
          <Col xs={24} lg={8}>
            <FeatureFlag featureFlag={featureFlags.customer.customerView.customerDetail}>
              <CustomerDetail addPaymentDetails={this.handleAddPaymentDetails} />
            </FeatureFlag>
            <FeatureFlag featureFlag={featureFlags.customer.customerView.createPaymentDetail}>
              <CreatePaymentDetail
                partyId={customerid}
                showPaymentModal={this.state.showPaymentModal}
                showCustomWidget={this.state.showCustomWidget}
                handleShowCustomWidget={this.handleShowCustomWidget}
                closePaymentModal={this.closePaymentModal}
              />
            </FeatureFlag>
            <FeatureFlag featureFlag={featureFlags.customer.documentsWidget.customer.view.widget}>
              <Documents
                config={getFeatureFlagsConfiguration('customer.documentsWidget.customer.view')}
                partyId={customerid}
                searchMode={SearchMode.Party}
                featureFlag={documentFeature}
              />
            </FeatureFlag>
            <FeatureFlag featureFlag={featureFlags.customer.customerView.viewRelations}>
              <RelationWidget
                onEditRelation={(customerId) => this.handleEditRelation(customerId)}
                ownerId={customerid}
              />
            </FeatureFlag>
            {document.getElementById('policy-predictions') && <ds-predictions-policy partyId={customerid} />}
          </Col>
          <Col xs={24} lg={16}>
            {this.state.currentTab === 'portfolio' && (
              <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <FeatureFlag featureFlag={featureFlags.customer.customerView.policies}>
                  <Policies id={customerid} view={policyViewFeature} cancel={policyCancelFeature} addNoteToPolicy={addNoteToPolicyFeature}/>
                </FeatureFlag>
                <FeatureFlag featureFlag={featureFlags.customer.customerView.quotes}>
                  <Quotes id={customerid} />
                </FeatureFlag>
                <FeatureFlag featureFlag={featureFlags.customer.customerView.opportunities}>
                  <Opportunities id={customerid} />
                </FeatureFlag>
              </Space>
            )}
            {this.state.currentTab === 'payments' && (
              <>
                <FeatureFlag featureFlag={featureFlags.customer.customerView.paymentTab}>
                  <Payments partyId={customerid} />
                </FeatureFlag>
              </>
            )}
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

Customer.propTypes = {
  customerId: string.isRequired,
  setRecentlyViewedCustomer: func.isRequired,
  t: func,
  match: object,
  history: object,
  location: object,
};

const mapStateToProps = (state) => ({
  customerId: state.pages.customer.recentlyViewedCustomerId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecentlyViewedCustomer,
      getPartyById,
    },
    dispatch
  );

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Customer)));
