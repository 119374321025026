"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.callSearchConfiguration = void 0;
var API_URL = 'v1/services/interface';
var callSearchConfiguration = function (body) {
    return {
        url: API_URL,
        method: 'POST',
        data: body,
    };
};
exports.callSearchConfiguration = callSearchConfiguration;
