import { getAgentConfigUrl } from './consts';
import {
  GET_AGENT_CONFIGURATIONS_REQUEST,
  GET_AGENT_CONFIGURATIONS_SUCCESS,
  GET_AGENT_CONFIGURATIONS_ERROR,
  SET_AGENT_TYPE,
  SET_AGENT_FIRMS,
} from '../../actionTypes';
import { apiConnector } from '../../../utils/apiConnector';

const getAgentConfigurations = () => async () => {
  try {
    return await apiConnector().platformConfiguration.getAgentConfig();
  } catch (err) {
    console.error('Error: ', err);
    throw new Error(err);
  }
};

/**
 * Get configurations for the insurance agent
 */
export const _getAgentConfigurations = () => async (dispatch) => {
  const url = `${getAgentConfigUrl()}`;

  dispatch({ type: GET_AGENT_CONFIGURATIONS_REQUEST });

  try {
    const payload = await dispatch(getAgentConfigurations());

    dispatch({ type: GET_AGENT_CONFIGURATIONS_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_AGENT_CONFIGURATIONS_ERROR, payload: err });
  }
};

export const setAgentType = (agentType) => async (dispatch) => {
  dispatch({ type: SET_AGENT_TYPE, payload: { agentType: agentType } });
};

export const setAgentFirms = (agentFirms) => async (dispatch) => {
  dispatch({ type: SET_AGENT_FIRMS, payload: { data: agentFirms } });
};
