"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelation = exports.updateRelation = exports.createRelation = exports.getRelationsUsingGET = void 0;
var API_URL = 'v1/relations';
var getRelationsUsingGET = function (params) {
    return {
        url: "".concat(API_URL, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getRelationsUsingGET = getRelationsUsingGET;
var createRelation = function (relation) {
    return {
        url: "".concat(API_URL),
        method: 'POST',
        data: relation,
    };
};
exports.createRelation = createRelation;
var updateRelation = function (relation, seqNo) {
    return {
        url: "".concat(API_URL, "\\").concat(seqNo),
        method: 'PUT',
        data: relation,
    };
};
exports.updateRelation = updateRelation;
var getRelation = function (seqNo) {
    return {
        url: "".concat(API_URL, "\\").concat(seqNo),
        method: 'GET',
    };
};
exports.getRelation = getRelation;
