import React, { PureComponent, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { array, func, object } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, generatePath } from 'react-router-dom';
import { Layout, Row, Col } from 'antd';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import Policies from '../../widgets/Policies/Policies.widget';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { customerDetailsPath } from '../../router/paths';
import getSearchParams from '../../utils/getSearchParams';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { AgentSalesFlowWidget } from '../../components/AgentSalesFlowWidget/AgentSalesFlowWidget';
import { PATH_CUSTOMER } from '../../router/paths';

class CreateOpportunity extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      toastMessageVisibility: false,
      toastMessageText: '',
      toastMessageType: '',
      searchQueryParams: '',
      showPaymentModal: false,
      showCustomWidget: false,
    };

    this.agentSalesOpportunityBasketWidget = createRef();
    this.fetchAssets();
  }

  componentDidMount() {
    const { t } = this.props;
    const urlParams = getSearchParams();
    const getOpportunityId = urlParams.opportunityid ? urlParams.opportunityid : '';

    this.setState({ searchQueryParams: urlParams }, () => {
      this.agentSalesOpportunityBasketWidget.current.setAttribute('opportunityid', getOpportunityId);
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('confirm-offer', () => {
      this.props.history.push(customerDetailsPath(this.props.match.params.customerid));
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('set-in-force-policy', (e) => {
      if (e.detail.status === 'successful') {
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.setInForceSuccesful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.setInForceFailed'),
        });
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('set-in-force-opportunity', (e) => {
      if (e.detail.status === 'successful') {
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.setInForceSuccesful'),
        });
        this.props.history.push(generatePath(PATH_CUSTOMER, { customerid: this.props.match.params.customerid }));
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.setInForceFailed'),
        });
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('duplicate-basket', (e) => {
      if (e.detail.status === 'successful') {
        this.props.history.push(customerDetailsPath(this.props.match.params.customerid));
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.messageDuplicationSuccesful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.messageDuplicationError'),
        });
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('cancel-basket', (e) => {
      if (e.detail.status === 'successful') {
        this.props.history.push(customerDetailsPath(this.props.match.params.customerid));
        openNotification({
          severityLevel: 'success',
          title: t('widgets.opportunityBasket.messages.messageTitleSuccess'),
          message: t('widgets.opportunityBasket.messages.messageCancelSuccessful'),
        });
      } else {
        openNotification({
          severityLevel: 'error',
          title: t('widgets.opportunityBasket.messages.messageTitleError'),
          message: t('widgets.opportunityBasket.messages.messageCancelError'),
        });
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('success-widget-action', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('widget-error', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });

    this.agentSalesOpportunityBasketWidget.current.addEventListener('widget-message', (e) => {
      if (Array.isArray(e.detail)) {
        showMultipleToasts(e.detail);
      } else {
        if (e.detail !== null) {
          openNotification(e);
        }
      }
    });
  }

  handleAddPaymentDetails = () => {
    this.setState({
      showPaymentModal: true,
    });
  };

  handleShowCustomWidget = () => {
    this.setState({
      showCustomWidget: true,
    });
  };

  closePaymentModal = () => {
    this.setState({
      showPaymentModal: false,
      showCustomWidget: false,
    });
  };

  async fetchAssets() {
    const { match, setRecentlyViewedCustomer } = this.props;
    const { customerid } = match.params;

    setRecentlyViewedCustomer(customerid);
  }

  render() {
    const { t, match } = this.props;
    const { customerid } = match.params;

    const widgetLocalizationSalesFlow = JSON.stringify(
      t('tia-agent-sales-flow-widget:widget', { returnObjects: true })
    );

    const widgetLocalizationOpportunityBasket = JSON.stringify(
      t('tia-opportunity-basket-widget:widget', { returnObjects: true })
    );

    const basketDisablePrint = getFeatureFlagsConfiguration(featureFlags.customer.createOpportunity.basket.print);
    const basketDisablePrintAgent = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.printAgent
    );
    const basketDisablePrintAgentFirm = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.printAgentFirm
    );
    const basketDisablePrintCustomer = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.printCustomer
    );

    const basketDisableSetInForce = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.setInForce
    );

    const disableBundleDiscount = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.bundleDiscount
    );

    const disableSetBasketInForce = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.setBasketInForce
    );

    const disableConfirmOffer = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.confirmOffer
    );

    const disableDuplicateBasket = getFeatureFlagsConfiguration(
      featureFlags.customer.createOpportunity.basket.duplicate
    );

    const disableCancelBasket = getFeatureFlagsConfiguration(featureFlags.customer.createOpportunity.basket.cancel);

    const enableClauseSearch = getFeatureFlagsConfiguration(featureFlags.customer.createOpportunity.searchClauses);
    const enableRiskToggleOnLeft = getFeatureFlagsConfiguration(featureFlags.customer.createOpportunity.riskToggleOnLeft);
    const enableDatepicker = getFeatureFlagsConfiguration(featureFlags.customer.createOpportunity.datepicker);

    return (
      <Layout.Content>
        <Row gutter={40}>
          <Col lg={8}>
            <FeatureFlag featureFlag={featureFlags.customer.createOpportunity.opportunityBasket}>
              <tia-opportunity-basket-widget
                style={{
                  margin: '0 0 10px 0',
                  display: 'block',
                }}
                ref={this.agentSalesOpportunityBasketWidget}
                partyId={customerid}
                confirmOfferButton={!disableConfirmOffer}
                isSelectable
                localization={widgetLocalizationOpportunityBasket}
                disablePrintSelection={!basketDisablePrint}
                disablePrintAgentSelection={!basketDisablePrintAgent}
                disablePrintAgentFirmSelection={!basketDisablePrintAgentFirm}
                disablePrintCustomerSelection={!basketDisablePrintCustomer}
                disableSetInForceAction={!basketDisableSetInForce}
                disableBundleDiscount={!disableBundleDiscount}
                disableSetBasketInForce={!disableSetBasketInForce}
                disableDuplicateBasket={!disableDuplicateBasket}
                disableCancelBasket={!disableCancelBasket}
              />
            </FeatureFlag>
            <div style={{ margin: '0 0 10px 0' }}>
              <FeatureFlag featureFlag={featureFlags.customer.createOpportunity.customerDetail}>
                <CustomerDetail addPaymentDetails={this.handleAddPaymentDetails} />
              </FeatureFlag>
              <FeatureFlag featureFlag={featureFlags.customer.createOpportunity.createPaymentDetail}>
                <CreatePaymentDetail
                  partyId={customerid}
                  showPaymentModal={this.state.showPaymentModal}
                  showCustomWidget={this.state.showCustomWidget}
                  handleShowCustomWidget={this.handleShowCustomWidget}
                  closePaymentModal={this.closePaymentModal}
                />
              </FeatureFlag>
            </div>
            <FeatureFlag featureFlag={featureFlags.customer.createOpportunity.policies}>
              <Policies id={customerid} view={false} cancel={false} />
            </FeatureFlag>
          </Col>
          <Col lg={16}>
            <tia-toast
              visible={this.state.toastMessageVisibility}
              message={this.state.toastMessageText}
              type={this.state.toastMessageType}
              closeable="true"
              actiontitle=""
              textonly="false"
            />
            <FeatureFlag featureFlag={featureFlags.customer.createOpportunity.agentSalesFlowWidget}>
              <AgentSalesFlowWidget
                partyId={customerid}
                queryParameters={JSON.stringify(this.state.searchQueryParams)}
                localization={widgetLocalizationSalesFlow}
                enableClauseSearch={enableClauseSearch}
                enableRiskToggleOnLeft={enableRiskToggleOnLeft}
                enableDatepicker={enableDatepicker}
              />
            </FeatureFlag>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
}

CreateOpportunity.propTypes = {
  setRecentlyViewedCustomer: func.isRequired,
  t: func,
  history: array,
  match: object,
};

const mapStateToProps = (state) => ({
  customerId: state.pages.customer.recentlyViewedCustomerId,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setRecentlyViewedCustomer,
    },
    dispatch
  );

export default withTranslation(['portal', 'tia-agent-sales-flow-widget', 'tia-opportunity-basket-widget'])(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateOpportunity))
);
