"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGeneralGroupSalesCustomInstance = exports.getSalesCustomInstance = exports.getDetailInstance = exports.getObjectGroups = exports.getObjectGroup = exports.fetchSalesProduct = exports.fetchSalesProductNames = void 0;
var API_URL = 'v1/sales-products';
var fetchSalesProductNames = function (params) {
    return {
        url: "".concat(API_URL, "/names?").concat(params.toString()),
        method: 'GET',
    };
};
exports.fetchSalesProductNames = fetchSalesProductNames;
var fetchSalesProduct = function (productName, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(productName, "?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.fetchSalesProduct = fetchSalesProduct;
var getObjectGroup = function (salesProduct, objectGroupId, params) {
    return {
        url: "".concat(API_URL, "/").concat(salesProduct, "/object-groups/").concat(objectGroupId, "?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getObjectGroup = getObjectGroup;
var getObjectGroups = function (salesProduct, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(salesProduct, "/object-groups?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.getObjectGroups = getObjectGroups;
var getDetailInstance = function (salesProduct, objectGroupId, detailId, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(salesProduct, "/object-groups/").concat(objectGroupId, "/details/").concat(detailId, "/instances/template?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.getDetailInstance = getDetailInstance;
var getSalesCustomInstance = function (salesProduct, objectGroupId, detailId, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(salesProduct, "/object-groups/").concat(objectGroupId, "/sales-custom-entities/").concat(detailId, "/instances/template?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.getSalesCustomInstance = getSalesCustomInstance;
var getGeneralGroupSalesCustomInstance = function (salesProduct, generalGroupName, detailId, params, query) {
    return {
        url: "".concat(API_URL, "/").concat(salesProduct, "/general-groups/").concat(generalGroupName, "/sales-custom-entities/").concat(detailId, "/instances/template?").concat(params.toString()).concat(query),
        method: 'GET',
    };
};
exports.getGeneralGroupSalesCustomInstance = getGeneralGroupSalesCustomInstance;
