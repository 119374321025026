import React, { createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import { useTranslation } from 'react-i18next';
import { getGlobalConfiguration } from '../../utils/getGlobalConfiguration';

export const Documents = ({ sipasUuid, partyId, opportunityId, searchMode, config, featureFlag }) => {
  const { t } = useTranslation();
  const widgetRef = createRef();

  const documentTranslations = t('portal:widgets.documentList', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });

  const styles = JSON.stringify({
    components: {
      WidgetCard: {
        title: {
          fontWeight: 700,
        },
        newButtonBackgroundColor: '#007A84',
        newButtonBackgroundColorHover: '#045B62 !important',
      },
    },
  });

  useEffect(() => {
    const handleSuccess = (event) => {
      switch (event.type) {
        case 'document-upload-successful':
          openNotification({
            message: t('widgets.documentList.actions.uploadFile.uploadSuccessDescription'),
            severityLevel: 'success',
            title: t('widgets.documentList.actions.uploadFile.uploadSuccessNotification'),
          });
          break;

        case 'document-merge-successful':
          openNotification({
            message: t('widgets.documentList.actions.merge.uploadSuccessDescription'),
            severityLevel: 'success',
            title: t('widgets.documentList.actions.merge.uploadSuccessNotification'),
          });
          break;
      }

      const { messages } = event.detail;

      if (messages) {
        messages.forEach((messages) => {
          openNotification({
            message: messages.text,
            severityLevel: 'info',
            title: t('errorHandling.errorCodes.general.title.MERGE_DOCUMENTS'),
          });
        });
      }
    };

    const handleReturnMessages = (event) => {
      const { errors, messages } = event.detail;

      if (errors) {
        showMultipleToasts(errors);
      }

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('document-upload-successful', handleSuccess);
      widgetRef.current.addEventListener('document-merge-successful', handleSuccess);

      widgetRef.current.addEventListener('documents-error', handleReturnMessages);
      widgetRef.current.addEventListener('document-upload-error', handleReturnMessages);
      widgetRef.current.addEventListener('document-download-error', handleReturnMessages);
      widgetRef.current.addEventListener('document-merge-error', handleReturnMessages);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('document-upload-successful', handleSuccess);
        widgetRef.current.removeEventListener('document-merge-successful', handleSuccess);

        widgetRef.current.removeEventListener('documents-error', handleReturnMessages);
        widgetRef.current.removeEventListener('document-upload-error', handleReturnMessages);
        widgetRef.current.removeEventListener('document-download-error', handleReturnMessages);
        widgetRef.current.removeEventListener('document-merge-error', handleReturnMessages);
      }
    };
  }, [sipasUuid, partyId, opportunityId]);

  const getFieldsConfiguration = (sipasUuid, opportunityId) => {
    if (sipasUuid) {
      return getGlobalConfiguration('documentsWidget.policySectionDisplayColumns');
    }

    if (opportunityId) {
      return getGlobalConfiguration('documentsWidget.basketDisplayColumns');
    }

    return getGlobalConfiguration('documentsWidget.customerSectionDisplayColumns');
  };

  return (
    <ds-portfolio-document-widget
      ref={widgetRef}
      variant="document"
      sipasUuid={sipasUuid}
      partyId={partyId}
      opportunityId={opportunityId}
      searchMode={searchMode}
      config={JSON.stringify(config)}
      styleOverrides={styles}
      featureFlag={JSON.stringify({ document: featureFlag })}
      translations={JSON.stringify({ documentList: documentTranslations })}
      columnConfiguration={JSON.stringify(getFieldsConfiguration(sipasUuid, opportunityId))}
    />
  );
};

Documents.propTypes = {
  sipasUuid: PropTypes.string,
  partyId: PropTypes.string,
  opportunityId: PropTypes.string,
  searchMode: PropTypes.string,
  config: PropTypes.object,
  featureFlag: PropTypes.object,
};
