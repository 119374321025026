import { CustomWidget, eventBus } from '@tia/custom-widget';
import React, { useEffect, useState } from 'react';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { useHistory } from 'react-router-dom';
import {
  handleAddRecord,
  handleCancelEvent,
  handleCloseWidgetModal,
  handleFormButtonClick,
  handleGenericError,
  handleSCFailed,
  handleSCSuccess,
  handleSearch,
  handleSubmitFailed,
  handleSubmitSuccess,
  handleSubmitValidationFailed,
  handleTableCellClick,
  handleTableRowClick,
  handleTableRowDeleteFailed,
  handleTableRowDeleteSuccess,
  handleTableRowEdit,
} from './custom-widget-events';
import { Button } from '@tia/ui-components';

export function CustomWidgetPage() {
  const history = useHistory();
  useEffect(() => {
    if (!getFeatureFlagsConfiguration('customWidget.enabled')) {
      history.replace('/');
    }
  }, []);

  const [mode, setMode] = useState('new');
  const [config, setConfig] = useState('party-form');
  const [partyId, setPartyId] = useState('10001');
  const [modalNodeOpen, setModalNodeOpen] = useState(false);

  return (
    <div>
      <fieldset>
        <label>mode</label>
        <select onChange={(e) => setMode(e.target.value)}>
          <option value="new">new</option>
          <option value="edit">edit</option>
          <option value="view">view</option>
          <option value="search">search</option>
        </select>
      </fieldset>
      <fieldset>
        <label>config</label>
        <select onChange={(e) => setConfig(e.target.value)}>
          <option value="party-form">Party Form</option>
          <option value="parties-table">Parties Table</option>
        </select>
      </fieldset>
      <fieldset>
        <label>party id</label>
        <select onChange={(e) => setPartyId(e.target.value)}>
          <option value="10001">10001</option>
          <option value="10003">10003</option>
          <option value="10007">10007</option>
        </select>
      </fieldset>
      <fieldset>
        <label>widget as modal opened</label>
        <input type="checkbox" checked={modalNodeOpen} onChange={(e) => setModalNodeOpen(e.target.checked)}></input>
      </fieldset>
      <Button onClick={() => eventBus.publish({ id: config, eventType: 'refetch' })}>Refetch</Button>
      <div>
        <CustomWidget
          key={`${config}-${mode}-${partyId}`}
          mode={mode as any}
          configurationName={config}
          parameters={{
            id: partyId,
          }}
          datetimeConfig={{
            locale: 'pl',
          }}
          modalNodeOpen={modalNodeOpen}
          eventListeners={{
            'add-record': handleAddRecord,
            cancel: handleCancelEvent,
            'close-widget-modal': (detail) => handleCloseWidgetModal(detail, () => setModalNodeOpen(false)),
            'config-load-failed': handleGenericError,
            'form-data-load-failed': handleGenericError,
            search: handleSearch,
            'search-configuration-failed': handleSCFailed,
            'search-configuration-success': handleSCSuccess,
            'submit-failed': handleSubmitFailed,
            'submit-success': handleSubmitSuccess,
            'submit-validation-failed': handleSubmitValidationFailed,
            'table-cell-click': handleTableCellClick,
            'table-data-load-failed': handleGenericError,
            'table-row-click': handleTableRowClick,
            'table-row-edit': handleTableRowEdit,
            'table-row-delete-failed': handleTableRowDeleteFailed,
            'table-row-delete-success': handleTableRowDeleteSuccess,
            'widget-error': handleGenericError,
            'form-button-click': handleFormButtonClick,
          }}
        />
      </div>
    </div>
  );
}
