/**
 * Agent sales flow event details
 * @param { Object } eventDetails UIEvent emited from agent sales flow widget
 */
export const agentSalesFlowToastMessageQuotes = (eventDetails, t) => {
  if (eventDetails && eventDetails.detail) {
    if (eventDetails.detail.type === 'create' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.createQuoteSuccesful') };
    }

    if (eventDetails.detail.type === 'edit' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.editQuoteSuccesful') };
    }

    if (eventDetails.detail.type === 'mta' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.mtaPolicySuccesful') };
    }

    if (eventDetails.detail.actionType === 'secondary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.priceCalculationSuccesful') };
    }

    if (eventDetails.detail.actionType === 'CreateQuoteOnPolicy' && eventDetails.type === 'success-widget-action') {
          return { messageType: 'success', messageText: t('widgets.policyDetails.createQuoteOnPolicySuccessMessage') };
    }

    if (eventDetails.detail.actionType === 'CreateQuoteOnPolicy' && eventDetails.type === 'widget-error') {
          return { messageType: 'error', messageText: t('widgets.policyDetails.createQuoteOnPolicyFailedMessage') };
    }

    if (eventDetails.detail.actionType === 'CreateQuoteOnRenewal' && eventDetails.type === 'success-widget-action') {
          return { messageType: 'success', messageText: t('widgets.policyDetails.createQuoteOnRenewalSuccessMessage') };
    }

    if (eventDetails.detail.actionType === 'CreateQuoteOnRenewal' && eventDetails.type === 'widget-error') {
          return { messageType: 'error', messageText: t('widgets.policyDetails.createQuoteOnRenewalFailedMessage') };
    }
  }
};

/**
 * Agent sales flow event details
 * @param { Object } eventDetails UIEvent emited from agent sales flow widget
 */
export const agentSalesFlowToastMessageOpportunity = (eventDetails, t) => {
  if (eventDetails && eventDetails.detail) {
    if (eventDetails.detail.type === 'create' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.createOpportunitySuccesful') };
    }

    if (eventDetails.detail.type === 'edit' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.editOpportunitySuccesful') };
    }

    if (eventDetails.detail.type === 'mta' && eventDetails.detail.actionType === 'primary') {
      return { messageType: 'success', messageText: t('widgets.salesFlow.messages.mtaOpportunityPolicySuccesful') };
    }

    if (eventDetails.detail.actionType === 'secondary') {
      return {
        messageType: 'success',
        messageText: t('widgets.salesFlow.messages.priceOpportunityCalculationSuccesful'),
      };
    }
  }
};
