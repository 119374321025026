"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateUuidUsingGET = exports.getAgentFirmsUsingGET = exports.getAgentTypeUsingGET = void 0;
var API_URL = 'v1/utility';
var getAgentTypeUsingGET = function () {
    return {
        url: "".concat(API_URL, "/agent-type"),
        method: 'GET',
    };
};
exports.getAgentTypeUsingGET = getAgentTypeUsingGET;
var getAgentFirmsUsingGET = function () {
    return {
        url: "".concat(API_URL, "/agent-firms"),
        method: 'GET',
    };
};
exports.getAgentFirmsUsingGET = getAgentFirmsUsingGET;
var generateUuidUsingGET = function () {
    return {
        url: "".concat(API_URL, "/generate-uuid"),
        method: 'GET',
    };
};
exports.generateUuidUsingGET = generateUuidUsingGET;
