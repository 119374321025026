import { ADD_UPSERT_ENTITIES } from './actionTypes';

/*
 * Entities will hold all the fetched data based on entity type
 * This will make easier to cache and grab necessary parts of cached data
 * Selectors will first grab cached data and recalculate based on API results
 */
const entities = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_UPSERT_ENTITIES: {
      return {
        ...state,
        [payload.entityType]: {
          ...state[payload.entityType],
          ...payload.entities,
        },
      };
    }

    default:
      return state;
  }
};

export default entities;
