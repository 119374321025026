import store from '../configureStore';

const globalConfigFallback = require('./global-config-fallback.json');
const featureFlagInternalFallback = require('./feature-flag-internal-fallback.json');
const featureFlagExternalFallback = require('./feature-flag-external-fallback.json');

let globalConfig = null;
let featureFlags = null;

/**
 * This method is used to get global portal configuration specified in public/configuration/global-config.json.
 * If the configuration is not found in the above file, fallback value from global-config-fallback.json will be used instead.
 * @param {*} string string is the json path to the requested property etc "notification.duration.default"
 * @returns object
 */
export const getGlobalConfiguration = (string) => {
  if (!window.globalConfiguration) {
    return getFallbackGlobalValue(string);
  }

  if (!globalConfig) {
    globalConfig = window.globalConfiguration;
  }

  const globalValue = jsonPathToValue(globalConfig, string);

  if (jsonPathWasNotFound(globalValue)) {
    return getFallbackGlobalValue(string);
  }

  return globalValue;
};

const getFallbackGlobalValue = (string) => {
  const fallbackValue = jsonPathToValue(globalConfigFallback, string);

  if (jsonPathWasNotFound(fallbackValue)) {
    console.error(`No fallback value for global configuration ${string} was found`);
  }

  return fallbackValue;
};

/**
 * This method is used to get feature flags specified in public/configuration/feature-flag.json.
 * If the configuration is not found in the above file, fallback value from feature-flag-fallback.json will be used instead.
 * @param {*} string string is the json path to the requested property etc "customer.createCustomerDetail.customUiWidget"
 * @returns object
 */
export const getFeatureFlagsConfiguration = (string) => {
  if (!window.featureFlag) {
    return getFallbackFeatureFlag(string);
  }

  if (!featureFlags) {
    featureFlags = window.featureFlag;
  }
  
  const featureEnabled = jsonPathToValue(featureFlags, string);
  
  string === "customWidget.enabled" && console.log(string, featureFlags, featureEnabled, jsonPathWasNotFound(featureEnabled))
  if (jsonPathWasNotFound(featureEnabled)) {
    return getFallbackFeatureFlag(string);
  }
  return featureEnabled;
};

const getFallbackFeatureFlag = (string) => {
  let featureFlagFallback;

  if (store.getState().api.configurations.agentType.agentType === 'internal') {
    featureFlagFallback = featureFlagInternalFallback;
  } else {
    featureFlagFallback = featureFlagExternalFallback;
  }

  const fallbackValue = jsonPathToValue(featureFlagFallback, string);

  if (jsonPathWasNotFound(fallbackValue)) {
    console.error(`No fallback value for feature ${string} was found`);

    return true;
  }

  return fallbackValue;
};

/**
 * Returns true if featureResult is undefined.
 * @param {*} featureResult result of a call o jsonPathToValue
 * @returns boolean
 */
const jsonPathWasNotFound = (featureResult) => {
  return featureResult === undefined;
};

/**
 * This method will recursively iterate the the json object, to find the path matching path and return that value.
 * Undefined will be returned if path is not found in the json object.
 * @param {*} jsonData json object used to lookup values
 * @param {*} path json path that will be used as lookup etc "customer.createCustomerDetail.customUiWidget"
 * @returns object
 */
export const jsonPathToValue = (jsonData, path) => {
  if (!(jsonData instanceof Object) || typeof path === 'undefined') {
    throw new Error('Not a valid JSON object');
  }

  const pathArray = path.split('.');

  try {
    return pathArray.reduce((accVal, path) => {
      if (!accVal) {
        accVal = jsonData;
      }

      if (accVal[path] !== null) {
        accVal = accVal[path];
      }

      return accVal;
    }, '');
  } catch {
    return undefined;
  }
};
