"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var fetchRequest_1 = require("./fetchRequest");
var SalesPolicy_1 = require("../api/SalesPolicy");
var UrlParam_1 = require("../helpers/UrlParam");
var ObjectTrimmer_1 = require("../helpers/ObjectTrimmer");
var SalesPolicyConnector = /** @class */ (function () {
    function SalesPolicyConnector(baseUrl) {
        this.baseUrl = baseUrl;
    }
    SalesPolicyConnector.prototype.cancelSalesPolicy = function (spdUuid, date, cancelCode) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (date) {
                            (0, UrlParam_1.appendParam)(params, 'date', date);
                        }
                        if (cancelCode) {
                            (0, UrlParam_1.appendParam)(params, 'cancelCode', cancelCode);
                        }
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.cancelSalesPolicyUsingDELETE)(spdUuid, params), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.createQuoteOnPolicy = function (spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.createQuoteOnPolicyUsingPost)(spdUuid), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.createQuoteOnRenewal = function (spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.createQuoteOnRenewalUsingPost)(spdUuid), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.acceptQuoteOnPolicy = function (spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.acceptQuoteOnPolicy)(spdUuid), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.postSalesPolicies = function (productsWithBasketForDiscount, partyId, opportunityId, params) {
        return __awaiter(this, void 0, void 0, function () {
            var urlSearchParams;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        urlSearchParams = new URLSearchParams();
                        (0, UrlParam_1.appendParam)(urlSearchParams, 'partyId', partyId);
                        if (opportunityId) {
                            (0, UrlParam_1.appendParam)(urlSearchParams, 'opportunityId', opportunityId);
                        }
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.postSalesPolicies)((0, ObjectTrimmer_1.deleteObjectKeys)(productsWithBasketForDiscount, ObjectTrimmer_1.postSalesPolicyKeys), "".concat(urlSearchParams.toString()).concat(params)), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.print = function (policyReceiver, customerId, spdUuids) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (policyReceiver === 'CUSTOM') {
                            (0, UrlParam_1.appendParam)(params, 'customReceiver', customerId);
                        }
                        (0, UrlParam_1.appendParam)(params, 'printReceiver', policyReceiver);
                        (0, UrlParam_1.appendParam)(params, 'language', 'TIA');
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.print)(spdUuids, params), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.printSingleUuid = function (policyReceiver, customerId, spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (policyReceiver === 'CUSTOM') {
                            (0, UrlParam_1.appendParam)(params, 'customReceiver', customerId);
                        }
                        (0, UrlParam_1.appendParam)(params, 'printReceiver', policyReceiver);
                        (0, UrlParam_1.appendParam)(params, 'language', 'TIA');
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.printSingleUuid)(spdUuid, params), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.getPolicyByUuid = function (spdUuid, partyId, policyVersionDate, query) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (!query) {
                            query = '';
                        }
                        (0, UrlParam_1.appendParam)(params, 'partyId', partyId);
                        if (policyVersionDate) {
                            (0, UrlParam_1.appendParam)(params, 'versionDate', policyVersionDate);
                        }
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.getSalesPolicyByUuid)(spdUuid, params, query), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.duplicatePolicy = function (spdUuid, partyId, query) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (!query) {
                            query = '';
                        }
                        (0, UrlParam_1.appendParam)(params, 'partyId', partyId);
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.duplicatePolicy)(spdUuid, params, query), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.updatePolicy = function (salesPolicy, partyId, query) {
        return __awaiter(this, void 0, void 0, function () {
            var params, cleanSalesPolicy;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = new URLSearchParams();
                        if (!query) {
                            query = '';
                        }
                        (0, UrlParam_1.appendParam)(params, 'partyId', partyId);
                        cleanSalesPolicy = salesPolicy.map(function (sp) { return (0, ObjectTrimmer_1.deleteObjectKeys)(sp, ObjectTrimmer_1.updateSalesPolicyKeys); });
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.updateSalesPolicy)(cleanSalesPolicy, params, query), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.calculatePremium = function (salesPolicy) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.calculatePremium)((0, ObjectTrimmer_1.deleteObjectKeys)(salesPolicy, ObjectTrimmer_1.calculatePremiumKeys)), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.setInForceByUuid = function (spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.setInForceByUuid)(spdUuid), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.setInForceWithBody = function (salesPolicy) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.setInForceWithBody)(salesPolicy), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    SalesPolicyConnector.prototype.createCaseItem = function (spdUuid) {
        return __awaiter(this, void 0, void 0, function () {
            var params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        params = {
                            actionDate: new Date(),
                            userComm: 'string',
                        };
                        return [4 /*yield*/, (0, fetchRequest_1.fetchRequestWithPagination)((0, SalesPolicy_1.createCaseItem)(spdUuid, params), this.baseUrl)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    return SalesPolicyConnector;
}());
exports.default = SalesPolicyConnector;
