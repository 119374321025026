import { combineReducers } from 'redux';
import parties from './parties/reducer';
import party from './party/reducer';
import policies from './policies/reducer';
import configurations from './configurations/reducer';

export default combineReducers({
  parties,
  party,
  policies,
  configurations,
});
