import { combineReducers } from 'redux';
import { createDefaultPagination } from '../helpers';
import { getRecentlyViewedCustomers } from './helpers';
import { SET_CURRENT_CUSTOMER } from '../customer/actionTypes';
import { GET_PARTIES_REQUEST, GET_PARTIES_SUCCESS, GET_PARTIES_ERROR } from '../../actionTypes';
import { SET_SEARCH_QUERY_PARTIES, SET_PARTIES_PAGE_NUMBER } from './actionTypes';

const partyIds = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTIES_REQUEST:
      return state;

    case GET_PARTIES_ERROR:
      return state;

    case GET_PARTIES_SUCCESS:
      return payload.ids;

    default:
      return state;
  }
};

const searchedValue = (state = '', action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SEARCH_QUERY_PARTIES:
      return payload;

    default:
      return state;
  }
};

const pagination = (state = createDefaultPagination(), action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTIES_REQUEST:
      return state;

    case GET_PARTIES_ERROR:
      return state;

    case GET_PARTIES_SUCCESS:
      return createDefaultPagination(
        payload.pagination.size,
        payload.pagination.number,
        payload.pagination.totalElements
      );

    case SET_PARTIES_PAGE_NUMBER:
      return {
        ...state,
        currentPage: payload,
      };

    case SET_SEARCH_QUERY_PARTIES:
      return createDefaultPagination();

    default:
      return state;
  }
};

const status = (state = 0, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTIES_REQUEST:
      return state;

    case GET_PARTIES_SUCCESS:
      return payload.status;

    case GET_PARTIES_ERROR:
      return payload.status;

    default:
      return state;
  }
};

/**
 * recently viewed customers Array of objects
 * [
 *  {
 *    id: @param { String }
 *    date: @param { Date }
 *  }
 * ]
 */
const recentlyViewedCustomers = (state = [], action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_CUSTOMER:
      return getRecentlyViewedCustomers(state, payload);

    default:
      return state;
  }
};

export default combineReducers({
  partyIds,
  pagination,
  status,
  searchedValue,
  recentlyViewedCustomers,
});
