"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRelationMemberLov = void 0;
var API_URL = 'v1/search/lov';
var getRelationMemberLov = function (params) {
    return {
        url: "".concat(API_URL, "/relation-member?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getRelationMemberLov = getRelationMemberLov;
