import { combineReducers } from 'redux';
import { SET_CURRENT_CUSTOMER } from './actionTypes';
import {
  GET_PARTY_BY_ID_REQUEST,
  GET_PARTY_BY_ID_SUCCESS,
  GET_PARTY_BY_ID_ERROR,
  GET_POLICIES_BY_PARTY_ID_REQUEST,
  GET_POLICIES_BY_PARTY_ID_SUCCESS,
  GET_POLICIES_BY_PARTY_ID_ERROR,
  GET_QUOTES_BY_PARTY_ID_SUCCESS,
} from '../../actionTypes';

const recentlyViewedCustomerId = (state = '', action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CURRENT_CUSTOMER:
      return payload;

    default:
      return state;
  }
};

/**
 * This relation creates a connection between partyIds and related Objects
 * InitialRelationObject: {
 *  [partyId]: {
 *    policyIds: @param { Array } Array of policy IDs
 *    claimsIds: @param { Array } Array of claim IDs
 *    quotesIds: @param { Array } Array of quote IDs
 *  }
 * }
 */
const relation = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PARTY_BY_ID_REQUEST:
      return state;

    case GET_PARTY_BY_ID_ERROR:
      return state;

    case GET_POLICIES_BY_PARTY_ID_REQUEST:
      return state;

    case GET_POLICIES_BY_PARTY_ID_ERROR:
      return state;

    case GET_PARTY_BY_ID_SUCCESS:
      return {
        ...state,
        [payload.ids]: {
          ...state[payload.ids],
        },
      };

    case GET_POLICIES_BY_PARTY_ID_SUCCESS:
      return {
        ...state,
        [payload.partyId]: {
          ...state[payload.partyId],
          policyIds: [...payload.ids],
        },
      };

    case GET_QUOTES_BY_PARTY_ID_SUCCESS:
      return {
        ...state,
        [payload.partyId]: {
          ...state[payload.partyId],
          quotesIds: [...payload.ids],
        },
      };

    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        [payload]: {
          ...state[payload],
        },
      };

    default:
      return state;
  }
};

export default combineReducers({
  recentlyViewedCustomerId,
  relation,
});
