import { addUpsertEntities } from '../../entities/actionCreators';
import { responseNormalizer } from '../../../utils/responseNormalizer';
import { getPartiesPagination, getPartiesSearchQuery } from '../../pages/customers/selectors';
import { getPartiesParams } from './helpers';
import { PARTIES_ENTITIES } from './consts';
import { GET_PARTIES_REQUEST, GET_PARTIES_SUCCESS, GET_PARTIES_ERROR } from '../../actionTypes';
import { apiConnector } from '../../../utils/apiConnector';

const getParties = (params) => async (dispatch) => {
  try {
    const response = await apiConnector().party.getParties(false, params);

    if (!response) {
      return;
    }

    const normalizedResponse = responseNormalizer(response.data, 'id');

    dispatch(
      addUpsertEntities({
        entities: normalizedResponse,
        entityType: PARTIES_ENTITIES,
      })
    );

    return {
      ids: response.data.map(party => party.id),
      pagination: response.page,
      status: response.status,
    };
  } catch (err) {
    console.error('Error: ', err.toString());
  }
};

export const _getParties = () => async (dispatch, getState) => {
  const state = getState();
  const pagination = getPartiesPagination(state);
  const query = getPartiesSearchQuery(state);
  const conf = {
    query,
    number: pagination.currentPage,
    size: pagination.size,
  };
  const params = getPartiesParams({ ...conf });

  dispatch({ type: GET_PARTIES_REQUEST });

  try {
    const payload = await dispatch(getParties(params));

    if (!payload) {
      return;
    }

    dispatch({ type: GET_PARTIES_SUCCESS, payload });
  } catch (err) {
    dispatch({ type: GET_PARTIES_ERROR, payload: err.toString() });
  }
};
