"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateEntitiesUsingPUT = exports.getEntitiesWithUiConfigUsingGET = void 0;
var API_URL = 'v1/ui-configurations';
var getEntitiesWithUiConfigUsingGET = function (name, params) {
    return {
        url: "".concat(API_URL, "/").concat(name, "/get-entities-with-uiconfigurations?").concat(params.toString()),
        method: 'GET',
    };
};
exports.getEntitiesWithUiConfigUsingGET = getEntitiesWithUiConfigUsingGET;
var updateEntitiesUsingPUT = function (name, data, params) {
    return {
        data: data,
        url: "".concat(API_URL, "/").concat(name, "/save-entities?").concat(params.toString()),
        method: 'PUT',
    };
};
exports.updateEntitiesUsingPUT = updateEntitiesUsingPUT;
