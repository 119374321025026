/**
 * Get the time for recently viewed customer
 */
export const getTimeAgo = (date, t) => {
  const dateNow = new Date();
  const seconds = Math.round((dateNow - new Date(date)) / 1000);
  const minutes = Math.round(seconds / 60);

  if (seconds < 60) {
    return t('widgets.recentCustomers.timeAgo.seconds');
  }

  return t('widgets.recentCustomers.timeAgo.minutes', { minutes: minutes });
};

/**
 * Get 5 recently viewed customers
 * @param { Object } customers
 * @param { String } id
 */
export const getRecentlyViewedCustomers = (customers, id) =>
  [
    {
      id,
      date: new Date(),
    },
    ...customers.filter((customer) => customer.id !== id),
  ].splice(0, 5);
