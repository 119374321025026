/* eslint react/prop-types: 0 */
import React from 'react';
import { FC, ReactNode, memo } from 'react';
import { Button, Modal as AntModal, ButtonProps } from 'antd';
import { useModal } from '../../hooks/useModal';

interface Props {
  modalName: string;
  title?: ReactNode;
  children: ReactNode;
  onClose?: () => void;
  onOk: () => void;
  okButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  okText?: ReactNode;
  cancelText?: ReactNode;
  afterClose?: () => void;
  showIcon?: boolean;
  modalWidth?: number;
  closable?: boolean;
}

export const Modal: FC<Props> = memo(function Modal({
  modalName,
  title,
  children,
  onClose,
  onOk,
  okButtonProps,
  cancelButtonProps,
  okText,
  cancelText,
  afterClose,
  modalWidth,
  closable = true
}) {
  const { isOpen, closeModal } = useModal(modalName);

  return (
    <AntModal
      width={modalWidth ? modalWidth : 416}
      closable={closable}
      centered={true}
      open={isOpen}
      onOk={onOk}
      title={title}
      cancelText={<span id="cancelButton">{cancelText ?? 'Cancel'}</span>}
      onCancel={(e: React.MouseEvent<HTMLElement>) => {
        if (closable) {
          if ((e.target as HTMLDivElement).id === 'cancelButton') {
            onClose?.();
          }
          closeModal();
        }
      }}
      footer={null}
    >
      <div className="typography-paragraph">{children}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '16px',
          gap: '8px',
        }}
      >
        <Button
          data-cy="modal-cancel-button"
          type="default"
          onClick={onClose}
          {...cancelButtonProps}
        >
          {cancelText ?? 'Cancel'}
        </Button>
        <Button
          data-cy="modal-ok-button"
          type="primary"
          onClick={onOk}
          {...okButtonProps}
        >
          {okText}
        </Button>
      </div>
    </AntModal>
  );
});
