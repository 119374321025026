import React, { createRef, memo, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NewClaimWidget } from '@tia/claims-widget';
import { Layout, Row, Col } from 'antd';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import CustomerDetail from '../CustomerDetail/CustomerDetail';
import { CreatePaymentDetail } from '../Payment/CreatePaymentDetail';
import { Documents } from '../../widgets/DocumentsWidget/Documents.widget';
import { setRecentlyViewedCustomer } from '../../state/pages/customer/actionCreators';
import { SearchMode } from '@tia/agent-api-connector/dist/enums/SearchMode';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';
import { viewPolicyPath } from '../../router/paths';
import { openNotification } from '../../utils/apiSetup';
import { useTranslation } from 'react-i18next';
import { useAtomValue } from 'jotai';
import { utcDateFormatAtom, dateLocaleAtom, utcDateTimeFormatAtom } from '../../atoms/atoms';

const Claims = memo(function Claims() {
  const match = useRouteMatch<{ customerid?: string; sipasuuid?: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showCustomWidget, setShowCustomWidget] = useState(false);

  useEffect(() => {
    fetchAssets();
  }, []);

  const handleAddPaymentDetails = () => {
    setShowPaymentModal(true);
  };

  const handleShowCustomWidget = () => {
    setShowCustomWidget(true);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
    setShowCustomWidget(false);
  };

  const closeClaimWidget = () => {
    setShowPaymentModal(false);
    setShowCustomWidget(false);
    history.push(viewPolicyPath(customerid, sipasuuid));
  };

  const onSuccessfulClaim = () => {
    setShowPaymentModal(false);
    setShowCustomWidget(false);
    history.push(viewPolicyPath(customerid, sipasuuid));
    openNotification({
      message: t('widgets.claims.actions.create.successDescription'),
      severityLevel: 'success',
      title: t('widgets.claims.actions.create.successNotification'),
    });

  };

  const fetchAssets = async () => {
    const { customerid } = match.params as { customerid: string };
    dispatch(setRecentlyViewedCustomer(customerid));
  };

  const { customerid, sipasuuid } = match.params as { customerid: string; sipasuuid: string };
  const documentFeature = getFeatureFlagsConfiguration('customer.documentsWidget.policy.edit');

  return (
    <Layout.Content>
      <Row gutter={40} style={{ marginTop: '10px' }}>
        <Col xs={24} lg={8}>
          <FeatureFlag featureFlag={featureFlags.customer.editQuote.customerDetail}>
            <CustomerDetail addPaymentDetails={handleAddPaymentDetails} />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.editQuote.createPaymentDetail}>
            <CreatePaymentDetail
              partyId={customerid}
              showPaymentModal={showPaymentModal}
              showCustomWidget={showCustomWidget}
              handleShowCustomWidget={handleShowCustomWidget}
              closePaymentModal={closePaymentModal}
            />
          </FeatureFlag>
          <FeatureFlag featureFlag={featureFlags.customer.documentsWidget.policy.edit.widget}>
            <Documents
              config={getFeatureFlagsConfiguration('customer.documentsWidget.policy.edit')}
              sipasUuid={sipasuuid}
              partyId={customerid}
              searchMode={SearchMode.SipasUuid}
              featureFlag={documentFeature}
            />
          </FeatureFlag>
        </Col>
        <Col xs={24} lg={16}>
          <FeatureFlag featureFlag={featureFlags.customer.policyView.showNewClaimButton}>
            <div className="agent-sales-flow-widget">
              <NewClaimWidget
                partyId={customerid}
                sipasuuid={sipasuuid}
                onCancel={closeClaimWidget}
                onSuccessfulSubmit={onSuccessfulClaim}
                onErrors={(obj) => {
                  obj.errors?.forEach((message) => openNotification({title: t('widgets.claims.actions.create.error'), message: message.errorMessage, severityLevel: 'error'}));
                }}
                onMessages={(obj) => {
                  obj.messages?.forEach((message) => openNotification({title: t('widgets.claims.actions.create.warning'), message: message.messageText, severityLevel: 'warning'}));
                }}
                config={{
                  dateFormat: useAtomValue(utcDateFormatAtom),
                  dateTimeFormat: useAtomValue(utcDateTimeFormatAtom),
                  locale: useAtomValue(dateLocaleAtom)
                }}
              />
            </div>
          </FeatureFlag>
        </Col>
      </Row>
    </Layout.Content>
  );
});

Claims.displayName = 'Claims';

export default Claims;
