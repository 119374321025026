import React, { useMemo } from 'react';
import { getAuthOptions } from '../../utils/authConfig';
import { AuthProvider } from '@tia/authenticator';
import LoginView from '../../pages/Login/LoginView/LoginView';
import AuthenticationMessage from '../Unauthorized/AuthenticationMessage';
import { useTranslation } from 'react-i18next';
import { oneOfType, arrayOf, object, any } from 'prop-types';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { accessTokenAtom, agentAccessTokenAtom, agentFirmIdAtom, crmAccessTokenAtom, featureFlagsChangeAtom, idTokenAtom } from '../../atoms/atoms';

export const AgentAuthProvider = ({ children }) => {
  const authOptions = useMemo(getAuthOptions, []);
  const { t } = useTranslation();
  const setIdToken = useSetAtom(idTokenAtom);
  const setAccessToken = useSetAtom(accessTokenAtom);
  const setAgentAccessToken = useSetAtom(agentAccessTokenAtom);
  const setCrmAccessToken = useSetAtom(crmAccessTokenAtom);
  const setAgentFirmIdToken = useSetAtom(agentFirmIdAtom)
  const setFeatureFlags = useSetAtom(featureFlagsChangeAtom);

  const handleOnTokenRenew = (user) => {
    if (user) {
      const accessToken = user.access_token;
      const idToken = user.id_token;

      setIdToken(idToken);
      setAccessToken(accessToken);
      setAgentAccessToken(accessToken);
      setCrmAccessToken(accessToken);
    }
  };

  const Loading = () => {
    return <AuthenticationMessage headline={t('pages.login.callbackView.authenticationMessage')} />;
  };

  return (
    <AuthProvider
      UnauthenticatedState={LoginView}
      LoadingState={Loading}
      issuer={authOptions.issuer}
      logoutUri={authOptions.successfulLogoutUri}
      audience={authOptions.audience}
      clientId={authOptions.clientID}
      redirectUri={authOptions.redirectUri}
      onTokenRenew={handleOnTokenRenew}
      tokenEndpoint={authOptions.tokenEndpoint}
      authorizationEndpoint={authOptions.authorizationEndpoint}
      userinfoEndpoint={authOptions.userinfoEndpoint}
      logoutEndpoint={authOptions.logoutEndpoint}
      jwksUri={authOptions.jwksUri}
      revocationEndpoint={authOptions.revocationEndpoint}
      clientSecret={authOptions.clientSecret}
      onLogout={() => {
        setIdToken(RESET);
        setAccessToken(RESET);
        setAgentAccessToken(RESET);
        setCrmAccessToken(RESET);
        setAgentFirmIdToken(RESET);
        setFeatureFlags(RESET);
      }}
      successfulLogoutUri={authOptions.successfulLogoutUri}
      scope={authOptions.scope}>
      {children}
    </AuthProvider>
  );
};

AgentAuthProvider.propTypes = {
  children: oneOfType([arrayOf(any), object]).isRequired,
};
