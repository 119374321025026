import { jotaiStore } from '../root';
import { accessTokenAtom } from '../atoms/atoms';
const getToken = (parsed) => {
  const accessToken = jotaiStore.get(accessTokenAtom);

  if (!accessToken) {
    return undefined;
  }

  try {
    return !parsed ? accessToken : parseJwt(accessToken);
  } catch (e) {
    console.error('Failed to parse id token:', accessToken);

    return undefined;
  }
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');

  return JSON.parse(window.atob(base64));
};

export const getScope = () => {
  const token = getToken(true);

  if (token) {
    return token.scope.split(' ');
  }

  return [];
};

export default getToken;
