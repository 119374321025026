import React, { createRef, memo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getGlobalConfiguration } from '../../utils/getGlobalConfiguration';
import { bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { viewPolicyPath } from '../../router/paths';
import { showMultipleToasts, openNotification } from '../../utils/apiSetup';
import {useAtomValue} from "jotai/index";
import {featureFlagsChangeAtom} from "../../atoms/atoms";

const getCancelCodes = () => {
  const cancelCodes = getGlobalConfiguration('cancelCodes');

  return Object.keys(cancelCodes).map((code) => {
    return { key: code, value: cancelCodes[code] };
  });
};

const Policies = memo(function Policies({ id, view, cancel, addNoteToPolicy }) {
  const featureFlagsAtom = useAtomValue(featureFlagsChangeAtom);
  const widgetRef = createRef();
  const { t } = useTranslation();
  const history = useHistory();

  const cancelCodes = JSON.stringify(getCancelCodes());

  const widgetTranslations = t('portal:widgets.policyList', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const cancelCodeTranslations = t('portal:cancelCodes', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const policyStatusTranslations = t('portal:policyStatus', {
    returnObjects: true,
    interpolation: { skipOnVariables: true },
  });
  const translations = JSON.stringify({
    policy: widgetTranslations,
    cancelCodes: cancelCodeTranslations,
    policyStatus: policyStatusTranslations,
  });

  const featureFlags = JSON.stringify({ policy: { view, cancel, addNoteToPolicy } });

  useEffect(() => {
    const viewPolicy = (event) => {
      const { uuid } = event.detail;

      history.push(viewPolicyPath(id, uuid));
    };

    const handleReturnMessages = (event) => {
      const { errors, messages } = event.detail;

      if (errors) {
        showMultipleToasts(errors);
      }

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    const handleSuccess = (event) => {
        switch (event.type) {
            case 'policy-successful-cancel':
                openNotification({
                    message: t('widgets.policyList.messages.cancelPolicySuccessful'),
                    severityLevel: 'success',
                    title: t('widgets.policyList.messages.messageTitleSuccess'),
                });
                break;

            case 'policy-successful-add-note':
                openNotification({
                    message: t('widgets.policyList.messages.createPolicyNoteSuccessful'),
                    severityLevel: 'success',
                    title: t('widgets.policyList.messages.messageTitleSuccess'),
                });
                break;
        }

      const { messages } = event.detail;

      if (messages) {
        showMultipleToasts(messages);
      }
    };

    if (widgetRef && widgetRef.current) {
      widgetRef.current.addEventListener('policy-view', viewPolicy);
      widgetRef.current.addEventListener('policy-error-cancel', handleReturnMessages);
      widgetRef.current.addEventListener('policies-error', handleReturnMessages);
      widgetRef.current.addEventListener('policy-successful-cancel', handleSuccess);
      widgetRef.current.addEventListener('policy-successful-add-note', handleSuccess);
      widgetRef.current.addEventListener('policy-error-add-note', handleReturnMessages);
    }

    return () => {
      if (widgetRef && widgetRef.current) {
        widgetRef.current.removeEventListener('policy-view', viewPolicy);
        widgetRef.current.removeEventListener('policy-error-cancel', handleReturnMessages);
        widgetRef.current.removeEventListener('policies-error', handleReturnMessages);
        widgetRef.current.removeEventListener('policy-successful-cancel', handleSuccess);
        widgetRef.current.removeEventListener('policy-successful-add-note', handleSuccess);
        widgetRef.current.removeEventListener('policy-error-add-note', handleReturnMessages);
      }
    };
  }, [id]);

  return (
    <ds-portfolio-policy-widget
      ref={widgetRef}
      variant={'policy'}
      partyId={id}
      cancelCodes={cancelCodes}
      translations={translations}
      featureFlag={featureFlags}
    />
  );
});

Policies.propTypes = {
  id: string.isRequired,
  view: bool.isRequired,
  cancel: bool.isRequired,
   addNoteToPolicy: bool.isRequired,
};

export default Policies;
