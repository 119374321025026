import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter, matchPath } from 'react-router-dom';
import { Layout, Breadcrumb } from 'antd';
import UserActions from './UserActions';
import PropTypes from 'prop-types';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { getFeatureFlagsConfiguration } from '../../utils/getGlobalConfiguration';

const { Header } = Layout;

const TopMenu = ({ location, paths }) => {
  const { t } = useTranslation();

  /**
   * Extract breadcrumb snippets from the url by splitting on '/'
   */
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  /**
   * map pathSnippets to dynamic breadcrumb items
   */
  const extraBreadcrumbItems = pathSnippets.map((pathSnippet, index) => {
    /**
     * Reconstruct url to make breadcrumbs into links
     */
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    /**
     * Check if there exists a page for the url
     */
    const urlMatch = paths.find((p) => matchPath(url, p) && matchPath(url, p).isExact);

    const breadcrumbText = t(`breadcrumb.${pathSnippet}`, '') || pathSnippet;

    if (!urlMatch) {
      return '';
    }

    return (
      // <Breadcrumb.Item key={pathSnippet}>
      <Breadcrumb.Item data-cy={'breadcrumb-' + pathSnippet} key={pathSnippet}>
        <Link to={url}>{breadcrumbText}</Link>
      </Breadcrumb.Item>
    );
  });

  const isDarkModeToggleEnabled = getFeatureFlagsConfiguration(featureFlags.darkModeToggle);

  return (
    <Header style={{ background: '#fff', padding: 0 }}>
      <div style={{ display: 'inline-block' }}>
        <Breadcrumb style={{ marginLeft: '30px', lineHeight: '74px' }}>
          <Breadcrumb.Item data-cy="breadcrumb-home" key="home">
            <Link to="/">{t('menus.topMenu.home')}</Link>
          </Breadcrumb.Item>
          {extraBreadcrumbItems}
        </Breadcrumb>
      </div>
      <div style={{ display: 'inline-block', float: 'right', marginRight: '15px' }}>
        <div style={{ display: 'flex', gap: '16px' }}>
          {Boolean(isDarkModeToggleEnabled) && (
            <fieldset style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <input
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    document.querySelector('html').classList.add('dark');
                  } else {
                    document.querySelector('html').classList.remove('dark');
                  }
                }}
              />
              <label>Enable dark mode</label>
            </fieldset>
          )}
          <UserActions />
        </div>
      </div>
    </Header>
  );
};

TopMenu.propTypes = {
  location: PropTypes.object,
  paths: PropTypes.array,
};

export default withRouter(TopMenu);
