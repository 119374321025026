import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FeatureFlag } from '../../components/FeatureFlag/FeatureFlag';
import { featureFlags } from '../../components/FeatureFlag/featureFlagConst';
import { PATH_NEW_CUSTOMER } from '../../router/paths';

const ExtraContent = ({ updateSearch, value }) => {
  const { t } = useTranslation();
  const onSearch = (e) => updateSearch(e.target.value);

  return (
    <div>
      <Input.Search
        value={value}
        placeholder={t('widgets.customerList.searchPlaceholder')}
        autoFocus
        onChange={onSearch}
        style={{ width: '240px', color: '#007A84' }}
        suffix={value && <CloseOutlined data-cy="customer-search-clear-icon" onClick={() => updateSearch('')} />}
        data-cy="customer-search-box"
      />
      <div style={{ display: 'inline-block', width: '10px' }} />
      <Link to={PATH_NEW_CUSTOMER}>
        <FeatureFlag featureFlag={featureFlags.customer.createCustomerDetail.customUiWidget}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ fontWeight: 'bold', backgroundColor: '#007A84', borderColor: '#007A84', display: 'inline-flex', alignItems: 'center' }}
            data-cy="new-customer-button">
            {t('widgets.customerList.newCustomer')}
          </Button>
        </FeatureFlag>
      </Link>
    </div>
  );
};

ExtraContent.propTypes = {
  updateSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default ExtraContent;
