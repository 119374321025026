import i18nInit from './i18n';
import { jotaiStore } from './root';
import {
  uiConfigApiEndpointAtom,
  agentApiEndpointAtom,
  crmApiEndpointAtom,
  dateFormatAtom,
  claimApiEndpointAtom,
  thousandSeparatorAtom,
  decimalSeparatorAtom,
  utcDateFormatAtom,
  utcDateTimeFormatAtom,
  dateLocaleAtom,
  spdSalesUiApiAtom,
  isDebugLoggingEnabledAtom
} from './atoms/atoms';
import { getAgentFirmIdHeader } from './utils/getAgentFirmIdHeader';

const defaultDateFormat = 'DD-MM-YYYY';

function refactorFlags(response) {
  const refactoredFlags = {};

  response.flags.forEach((flag) => {
    const flagNames = flag.name.split('.');
    let tempObject = refactoredFlags;

    for (let i = 0; i < flagNames.length - 1; i++) {
      if (!tempObject[flagNames[i]]) {
        tempObject[flagNames[i]] = {};
      }

      tempObject = tempObject[flagNames[i]];
    }

    tempObject[flagNames[flagNames.length - 1]] = flag.enabled;
  });

  return refactoredFlags;
}

const fetchConfigurations = async (options) => {
  try {
    const headers = getAgentFirmIdHeader();

    const globalConfiguration = fetch(`${process.env.PUBLIC_URL}/configuration/global-config.json`, headers && { headers }).then((response) =>
      response.json()
    );

    let featureFlagEndpoint = options.featureFlagEndpoint;

    var featureFlagInternalCall = {};
    var featureFlagInternal = {};
    var featureFlagExternalCall = {};
    var featureFlagExternal = {};

    if (typeof featureFlagEndpoint === 'string' && featureFlagEndpoint !== '') {
      featureFlagInternalCall = await fetch(`${featureFlagEndpoint}api/v1/namespaces/internal/flags`, headers && { headers }).catch((error) => {
        console.error(error);
      });
      featureFlagExternalCall = await fetch(`${featureFlagEndpoint}api/v1/namespaces/external/flags`, headers && { headers }).catch((error) => {
        console.error('Error', error);
      });

      featureFlagInternal = refactorFlags(await featureFlagInternalCall.json());
      featureFlagExternal = refactorFlags(await featureFlagExternalCall.json());
    } else {
      featureFlagInternal = fetch(
        `${process.env.PUBLIC_URL}/configuration/feature-flag-internal.json`, headers && { headers }).then((response) => response.json());
      featureFlagExternal = fetch(
        `${process.env.PUBLIC_URL}/configuration/feature-flag-external.json`, headers && { headers }).then((response) => response.json());
    }

    const featureFlag = fetch(`${process.env.PUBLIC_URL}/configuration/feature-flag.json`).then((response) =>
      response.json()
    );

    const response = await Promise.allSettled([
      globalConfiguration,
      featureFlag,
      featureFlagInternal,
      featureFlagExternal,
    ]);

    if (response[1].status === 'rejected' && response[2].status === 'rejected' && response[3].status === 'rejected') {
      console.error('No feature flag configuration found, portal will use fallback values');
    }

    return {
      globalConfiguration: response[0].value,
      featureFlag: response[1].value,
      featureFlagInternal: response[2].value,
      featureFlagExternal: response[3].value,
    };
  } catch (err) {
    console.error('Error: ', err);
  }
};

const register = async () => {
  const headers = getAgentFirmIdHeader();
  let options;

  if (process.env.NODE_ENV === 'production') {
    const optionsJson = await fetch(`${process.env.PUBLIC_URL}/environment/environment.json`, headers && { headers });

    options = await optionsJson.json();
    jotaiStore.set(agentApiEndpointAtom, options.agentApiUri);
    jotaiStore.set(crmApiEndpointAtom, options.crmApiUri);
    jotaiStore.set(uiConfigApiEndpointAtom, options.uiConfigApiUrl);
    jotaiStore.set(dateFormatAtom, options.dateFormat);
    jotaiStore.set(thousandSeparatorAtom, options.thousandSeparator || ',');
    jotaiStore.set(decimalSeparatorAtom, options.decimalSeparator || '.');
    jotaiStore.set(claimApiEndpointAtom, options.claimsApiUri);
    jotaiStore.set(utcDateFormatAtom, options.utcDateFormat);
    jotaiStore.set(utcDateTimeFormatAtom, options.utcDateTimeFormat);
    jotaiStore.set(dateLocaleAtom, options.dateLocale);
    jotaiStore.set(spdSalesUiApiAtom, options.spdSalesUiApi);
    jotaiStore.set(isDebugLoggingEnabledAtom, options.debugLogging === 'true');
    if (options && !options.dateFormat) {
      options.dateFormat = defaultDateFormat;
      jotaiStore.set(dateFormatAtom, defaultDateFormat);
    }
  } else {
    options = {
      issuer: process.env.REACT_APP_ISSUER || '',
      clientID: process.env.REACT_APP_CLIENT_ID || '',
      audience: process.env.REACT_APP_AUDIENCE || '',
      agentApiUri: process.env.REACT_APP_API_URI || '',
      crmApiUri: process.env.REACT_APP_CRM_API_URI || '',
      uiConfigApiUrl: process.env.REACT_APP_UI_CONFIG_API_URL || '',
      supportedLanguages: process.env.REACT_APP_SUPPORTED_LANGUAGES || '',
      fallbackLanguage: process.env.REACT_APP_FALLBACK_LANGUAGE || '',
      logo: process.env.REACT_APP_LOGO || '',
      dateFormat: process.env.REACT_APP_DATE_FORMAT || defaultDateFormat,
      tokenEndpoint: process.env.REACT_APP_TOKEN_ENDPOINT || '',
      authorizationEndpoint: process.env.REACT_APP_AUTH_ENDPOINT || '',
      userinfoEndpoint: process.env.REACT_APP_USER_INFO_ENDPOINT || '',
      logoutEndpoint: process.env.REACT_APP_LOGOUT_ENDPOINT || '',
      jwksUri: process.env.REACT_APP_JWKS_URI || '',
      revocationEndpoint: process.env.REACT_APP_REVOCATION_ENDPOINT || '',
      clientSecret: process.env.REACT_APP_CLIENT_SECRET || '',
      featureFlagEndpoint: process.env.REACT_APP_FEATURE_FLAG_ENDPOINT || '',
      thousandSeparator: process.env.THOUSAND_SEPARATOR || ',',
      decimalSeparator: process.env.DECIMAL_SEPARATOR || '.',
      utcDateFormat: process.env.UTC_DATE_FORMAT || 'yyyy-MM-dd',
      utcDateTimeFormat: process.env.UTC_DATE_TIME_FORMAT || 'yyyy-MM-dd HH:mm',
      dateLocale: process.env.DATE_LOCALE || 'en-US',
      spdSalesUiApi: process.env.REACT_APP_SALES_UI_API || '',
      additionalAuthOptions: {},
      contextPath: '',
      overrides: {
        __token_issuer: '',
      },
    };

    jotaiStore.set(agentApiEndpointAtom, process.env.REACT_APP_API_URI || '');
    jotaiStore.set(crmApiEndpointAtom, process.env.REACT_APP_CRM_API_URI || '');
    jotaiStore.set(uiConfigApiEndpointAtom, process.env.REACT_APP_UI_CONFIG_API_URL || '');
    jotaiStore.set(dateFormatAtom, process.env.REACT_APP_DATE_FORMAT || defaultDateFormat);
    jotaiStore.set(thousandSeparatorAtom, options.thousandSeparator);
    jotaiStore.set(decimalSeparatorAtom, options.decimalSeparator);
    jotaiStore.set(claimApiEndpointAtom, process.env.REACT_APP_CLAIM_URI || '');
    jotaiStore.set(spdSalesUiApiAtom, process.env.REACT_APP_SALES_UI_API || '');
    jotaiStore.set(isDebugLoggingEnabledAtom, true);

    jotaiStore.set(utcDateFormatAtom, options.utcDateFormat);
    jotaiStore.set(utcDateTimeFormatAtom, options.utcDateTimeFormat);
    jotaiStore.set(dateLocaleAtom, options.dateLocale);
  }

  const configurations = await fetchConfigurations(options);

  window.agentEnv = options;
  window.globalConfiguration = configurations.globalConfiguration;
  window.featureFlagInternal = configurations.featureFlagInternal;
  window.featureFlagExternal = configurations.featureFlagExternal;
  window.featureFlag = configurations.featureFlag;

  const windowLocation = window.location.origin;
  const contextPath = window.agentEnv.contextPath;

  window.agentEnv.contextLocation = contextPath ? `${windowLocation}/${contextPath}` : windowLocation;
  i18nInit(options.supportedLanguages, options.fallbackLanguage, options.contextPath);

  window?.initTiaWidgets({
    agentApiUrl: `${options.agentApiUri}`,
    dateFormat: `${options.dateFormat}`,
  });
};

export default register;
