import {
  COLOR_GREEN,
  COLOR_BLUE,
  COLOR_RED,
  COLOR_ORANGE,
  QUOTE,
  DECLINED_QUOTE,
  REFERRED_QUOTE,
  SUSPENDED_QUOTE,
  SUSPENDED_QUOTE_PROP,
  POLICY,
  REFERRED_POLICY,
  SUSPENDED_POLICY,
  QUOTE_ON_POLICY,
  QUOTE_ON_RENEWAL,
  CANCELLED_POLICY,
  UNKNOWN,
} from './consts';

/**
 * Get policy description from agreement lines
 * @param { Object } agreementLines
 * @returns { String }
 */
export const getPolicyDescription = (agreementLines) =>
  agreementLines.objects[0].sipasProdName
    ? agreementLines.objects[0].sipasProdName
    : `${agreementLines.productLineId}-${agreementLines.productLineVerNo}`;

/**
 * Converts date to DD-MM-YYYY hh:mm
 * @param { Date } date
 * @returns { String } Date in string format
 */
export const convertDateToLocalDateString = (date) =>
  new Date(date).toLocaleDateString('nl', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });

/**
 * Check if array is not empty
 * @param { Array } array Array of objects
 */
export const isCustomerDataLoaded = (array) => (array ? !array.length : false);

/**
 * Get policy status color based on policy status
 */
export const getPolicyStatusColor = (policyStatus) => {
  switch (policyStatus) {
    case QUOTE:
      return COLOR_GREEN;

    case DECLINED_QUOTE:
      return COLOR_RED;

    case REFERRED_QUOTE:
      return COLOR_BLUE;

    case SUSPENDED_QUOTE:
      return COLOR_BLUE;

    case SUSPENDED_QUOTE_PROP:
      return COLOR_RED;

    case POLICY:
      return COLOR_GREEN;

    case REFERRED_POLICY:
      return COLOR_BLUE;

    case SUSPENDED_POLICY:
      return COLOR_BLUE;

    case QUOTE_ON_POLICY:
      return COLOR_BLUE;

    case QUOTE_ON_RENEWAL:
      return COLOR_ORANGE;

    case CANCELLED_POLICY:
      return COLOR_RED;

    case UNKNOWN:
      return COLOR_RED;

    default:
      return COLOR_RED;
  }
};

/** Generates unique entity id */
export const getUniqueEntityPolicyId = (entity) => {
  const quoteId = Array.isArray(entity.id) ? entity.id.join('') : `${entity.id}`;

  return `${quoteId}${entity.sipasUuid}`;
};
