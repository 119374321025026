/* eslint-disable max-len */
export const GET_PRODUCTS_REQUEST = 'state/products/GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'state/products/GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'state/products/GET_PRODUCTS_ERROR';

export const GET_PARTIES_REQUEST = 'state/api/parties/GET_PARTIES_REQUEST';
export const GET_PARTIES_SUCCESS = 'state/api/parties/GET_PARTIES_SUCCESS';
export const GET_PARTIES_ERROR = 'state/api/parties/GET_PARTIES_ERROR';

export const GET_PARTY_BY_ID_REQUEST = 'state/api/party/GET_PARTY_BY_ID_REQUEST';
export const GET_PARTY_BY_ID_SUCCESS = 'state/api/party/GET_PARTY_BY_ID_SUCCESS';
export const GET_PARTY_BY_ID_ERROR = 'state/api/party/GET_PARTY_BY_ID_ERROR';

export const GET_POLICIES_BY_PARTY_ID_REQUEST = 'state/api/policies/GET_POLICIES_BY_PARTY_ID_REQUEST';
export const GET_POLICIES_BY_PARTY_ID_SUCCESS = 'state/api/policies/GET_POLICIES_BY_PARTY_ID_SUCCESS';
export const GET_POLICIES_BY_PARTY_ID_ERROR = 'state/api/policies/GET_POLICIES_BY_PARTY_ID_ERROR';

export const GET_QUOTES_BY_PARTY_ID_REQUEST = 'state/api/policies/GET_QUOTES_BY_PARTY_ID_REQUEST';
export const GET_QUOTES_BY_PARTY_ID_SUCCESS = 'state/api/policies/GET_QUOTES_BY_PARTY_ID_SUCCESS';
export const GET_QUOTES_BY_PARTY_ID_ERROR = 'state/api/policies/GET_QUOTES_BY_PARTY_ID_ERROR';

export const GET_OPPORTUNITIES_BY_PARTY_ID_REQUEST = 'state/api/policies/GET_OPPORTUNITIES_BY_PARTY_ID_REQUEST';
export const GET_OPPORTUNITIES_BY_PARTY_ID_SUCCESS = 'state/api/policies/GET_OPPORTUNITIES_BY_PARTY_ID_SUCCESS';
export const GET_OPPORTUNITIES_BY_PARTY_ID_ERROR = 'state/api/policies/GET_OPPORTUNITIES_BY_PARTY_ID_ERROR';

export const POST_DUPLICATE_BASKET_WITH_POLICIES_REQUEST =
  'state/api/policies/POST_DUPLICATE_BASKET_WITH_POLICIES_REQUEST';
export const POST_DUPLICATE_BASKET_WITH_POLICIES_SUCCESS =
  'state/api/policies/POST_DUPLICATE_BASKET_WITH_POLICIES_SUCCESS';
export const POST_DUPLICATE_BASKET_WITH_POLICIES_ERROR = 'state/api/policies/POST_DUPLICATE_BASKET_WITH_POLICIES_ERROR';

export const POST_PRINT_PRODUCTS_REQUEST = 'state/api/products/POST_PRINT_PRODUCTS_REQUEST';
export const POST_PRINT_PRODUCTS_SUCCESS = 'state/api/products/POST_PRINT_PRODUCTS_SUCCESS';
export const POST_PRINT_PRODUCTS_ERROR = 'state/api/products/POST_PRINT_PRODUCTS_ERROR';

export const POST_PRINT_LIST_OF_PRODUCTS_REQUEST = 'state/api/products/POST_PRINT_LIST_OF_PRODUCTS_REQUEST';
export const POST_PRINT_LIST_OF_PRODUCTS_SUCCESS = 'state/api/products/POST_PRINT_LIST_OF_PRODUCTS_SUCCESS';
export const POST_PRINT_LIST_OF_PRODUCTS_ERROR = 'state/api/products/POST_PRINT_LIST_OF_PRODUCTS_ERROR';

export const POST_SET_IN_FORCE_PRODUCTS_REQUEST = 'state/api/products/POST_SET_IN_FORCE_PRODUCTS_REQUEST';
export const POST_SET_IN_FORCE_PRODUCTS_SUCCESS = 'state/api/products/POST_SET_IN_FORCE_PRODUCTS_SUCCESS';
export const POST_SET_IN_FORCE_PRODUCTS_ERROR = 'state/api/products/POST_SET_IN_FORCE_PRODUCTS_ERROR';

export const GET_POLICY_BY_ID_REQUEST = 'state/api/policies/GET_POLICY_BY_ID_REQUEST';
export const GET_POLICY_BY_ID_SUCCESS = 'state/api/policies/GET_POLICY_BY_ID_SUCCESS';
export const GET_POLICY_BY_ID_ERROR = 'state/api/policies/GET_POLICY_BY_ID_ERROR';

export const CANCEL_QUOTE_BY_ID_REQUEST = 'state/api/policies/CANCEL_QUOTE_BY_ID_REQUEST';
export const CANCEL_QUOTE_BY_ID_SUCCESS = 'state/api/policies/CANCEL_QUOTE_BY_ID_SUCCESS';
export const CANCEL_QUOTE_BY_ID_ERROR = 'state/api/policies/CANCEL_QUOTE_BY_ID_ERROR';

export const CANCEL_POLICY_BY_ID_REQUEST = 'state/api/policies/CANCEL_POLICY_BY_ID_REQUEST';
export const CANCEL_POLICY_BY_ID_SUCCESS = 'state/api/policies/CANCEL_POLICY_BY_ID_SUCCESS';
export const CANCEL_POLICY_BY_ID_ERROR = 'state/api/policies/CANCEL_POLICY_BY_ID_ERROR';

export const GET_AGENT_CONFIGURATIONS_REQUEST = 'state/api/configurations/GET_AGENT_CONFIGURATIONS_REQUEST';
export const GET_AGENT_CONFIGURATIONS_SUCCESS = 'state/api/configurations/GET_AGENT_CONFIGURATIONS_SUCCESS';
export const GET_AGENT_CONFIGURATIONS_ERROR = 'state/api/configurations/GET_AGENT_CONFIGURATIONS_ERROR';

export const GET_PUBLIC_CONFIGURATION_REQUEST = 'state/api/configurations/GET_PUBLIC_CONFIGURATION_REQUEST';
export const GET_PUBLIC_CONFIGURATION_SUCCESS = 'state/api/configurations/GET_PUBLIC_CONFIGURATION_SUCCESS';
export const GET_PUBLIC_CONFIGURATION_ERROR = 'state/api/configurations/GET_PUBLIC_CONFIGURATION_ERROR';

export const SET_AGENT_TYPE = 'state/api/configurations/SET_AGENT_TYPE';
export const SET_AGENT_FIRMS = 'state/api/configurations/SET_AGENT_FIRMS';

export const POST_SEND_REFERRAL_REQUEST = 'state/api/sales-policies/POST_SEND_REFERRAL_REQUEST';
export const POST_SEND_REFERRAL_SUCCESS = 'state/api/sales-policies/POST_SEND_REFERRAL_SUCCESS';
export const POST_SEND_REFERRAL_ERROR = 'state/api/sales-policies/POST_SEND_REFERRAL_ERROR';

export const CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_REQUEST =
  'state/api/policies/CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_REQUEST';
export const CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_SUCCESS =
  'state/api/policies/CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_SUCCESS';
export const CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_ERROR =
  'state/api/policies/CANCEL_BASKET_WITH_POLICIES_FOR_UUIDS_BASKET_NO_ERROR';
export const POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_REQUEST =
  'state/api/products/POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_REQUEST';
export const POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_SUCCESS =
  'state/api/products/POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_SUCCESS';
export const POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_ERROR =
  'state/api/products/POST_SET_IN_FORCE_UUIDS_AND_BASKET_NO_ERROR';

export const PUT_QUOTE_ON_POLICY_REQUEST = 'state/api/products/PUT_QUOTE_ON_POLICY_REQUEST';
export const PUT_QUOTE_ON_POLICY_SUCCESS = 'state/api/products/PUT_QUOTE_ON_POLICY_SUCCESS';
export const PUT_QUOTE_ON_POLICY_ERROR = 'state/api/products/PUT_QUOTE_ON_POLICY_ERROR';

export const POST_CREATE_QUOTE_ON_POLICY_REQUEST = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_POLICY_REQUEST';
export const POST_CREATE_QUOTE_ON_POLICY_SUCCESS = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_POLICY_SUCCESS';
export const POST_CREATE_QUOTE_ON_POLICY_ERROR = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_POLICY_ERROR';

export const POST_CREATE_QUOTE_ON_RENEWAL_REQUEST = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_RENEWAL_REQUEST';
export const POST_CREATE_QUOTE_ON_RENEWAL_SUCCESS = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_RENEWAL_SUCCESS';
export const POST_CREATE_QUOTE_ON_RENEWAL_ERROR = 'state/api/sales-policies/POST_CREATE_QUOTE_ON_RENEWAL_ERROR';
