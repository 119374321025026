import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FrownOutlined } from '@ant-design/icons';

const ErrorFiller = ({ httpStatusCode }) => {
  const { t } = useTranslation();

  const statusCode = Math.max.apply(
    Math,
    httpStatusCode.map((status) => status)
  );

  const statusText =
    t(`errorHandling.httpStatusCodeMetaData.${httpStatusCode}`, '') ||
    t('errorHandling.httpStatusCodeMetaData.default');
  const message =
    t(`errorHandling.httpStatusCodeMessage.${httpStatusCode}`, '') || t('errorHandling.httpStatusCodeMessage.default');

  // notification.destroy();
  return (
    <div style={{ padding: '32px', textAlign: 'center', color: '#8db2bf', fontWeight: '600' }}>
      <FrownOutlined style={{ color: '#0f8dff', fontSize: '33px', fontWeight: '100' }} />
      <div style={{ marginTop: '11px', color: '#000' }}>
        {statusText} ({statusCode})
      </div>
      <div style={{ marginTop: '5px' }}>{message}</div>
    </div>
  );
};

export default ErrorFiller;

ErrorFiller.propTypes = {
  httpStatusCode: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
};
