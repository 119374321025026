"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAgentFirmIdHeader = void 0;
var parseJSONifiedStringOrGetRaw_1 = require("./parseJSONifiedStringOrGetRaw");
var getAgentFirmIdHeader = function (firmId) {
    var agentFirmId = localStorage.getItem('agent_firm_id');
    if (!agentFirmId && firmId) {
        agentFirmId = firmId;
    }
    return agentFirmId && {
        'X-agent-firm-id': (0, parseJSONifiedStringOrGetRaw_1.parseJSONifiedStringOrGetRaw)(agentFirmId)
    };
};
exports.getAgentFirmIdHeader = getAgentFirmIdHeader;
