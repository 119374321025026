import AgentApiConnector from '@tia/agent-api-connector';
import { getLanguage } from './language';
import { agentApiEndpointAtom } from '../atoms/atoms';
import { jotaiStore } from '../root';

/**
 * @type AgentApiConnector
 */
let _apiConnector;

export const apiConnector = () => {
  if (_apiConnector) {
    return _apiConnector;
  }

  const baseUrl = jotaiStore.get(agentApiEndpointAtom);

  if (!baseUrl) {
    throw Error('No base-url found. Check localStorage: "agent_endpoint"');
  }

  _apiConnector = new AgentApiConnector('tiaagent_access_token', baseUrl);
  _apiConnector.setLanguage(getLanguage());

  return _apiConnector;
};
