/**
 * Get all parameter from url
 * @returns object consisting key, value pairs based on search query passed from url
 */
const getSearchParams = () => {
  const emptyObject = {};

  const href = window.location.href.split('?')[1];

  if (href) {
    href.split('&').forEach((element) => {
      emptyObject[element.split('=')[0]] = element.split('=')[1];
    });
  }

  return emptyObject;
};

export default getSearchParams;
