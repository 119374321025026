import randomKey from './randomKey';

/**
 * Show toast message
 * @param message Message showed in a toast
 * @param type success | warning | info | error
 * @param timer (Optional) Amount of time the toast message should be present. Default time is 2500ms
 */
const showToastMessage = ({ message, type, timer = 2500 }) => {
  const mountPoint = document.querySelector('#toast-root');

  if (!mountPoint) {
    throw new Error('Mountpoint for toast message is not found. Please add Html element #toast-root');
  }

  const randomId = randomKey();
  const toast = document.createElement('tia-toast');

  toast.setAttribute('id', randomId);
  toast.setAttribute('message', message);
  toast.setAttribute('type', type);
  toast.setAttribute('timer', `${timer}`);
  toast.setAttribute('visible', 'true');
  toast.setAttribute('closeable', 'true');
  toast.setAttribute('textonly', 'false');
  toast.style.zIndex = 10;
  mountPoint.appendChild(toast);
  setTimeout(() => toast.remove(), timer + 150);
};

export default showToastMessage;
