"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appendDefaultParams = exports.appendParam = void 0;
var appendParam = function (params, key, param) {
    if (param) {
        params.append(key, param);
    }
};
exports.appendParam = appendParam;
var appendDefaultParams = function (params, defaultApiParams) {
    params.append('page', defaultApiParams.page);
    params.append('size', defaultApiParams.size);
};
exports.appendDefaultParams = appendDefaultParams;
