export const featureFlags = {
  darkModeToggle: 'darkModeToggle',
  customWidget: {
    enabled: 'customWidget.enabled',
  },
  customer: {
    relationWidget: {
      allowToCreateNewRelation: 'customer.relationWidget.allowToCreateNewRelation',
      allowToEditRelation: 'customer.relationWidget.allowToEditRelation',
    },
    documentsWidget: {
      customer: {
        view: {
          widget: 'customer.documentsWidget.customer.view.widget',
          uploadButton: 'customer.documentsWidget.customer.view.uploadButton',
        },
      },
      policy: {
        view: {
          widget: 'customer.documentsWidget.policy.view.widget',
          pdfSelectionAndMerge: 'customer.documentsWidget.policy.view.pdfSelectionAndMerge',
          mergeButton: 'customer.documentsWidget.policy.view.mergeButton',
          uploadButton: 'customer.documentsWidget.policy.view.uploadButton',
        },
        edit: {
          widget: 'customer.documentsWidget.policy.edit.widget',
          pdfSelectionAndMerge: 'customer.documentsWidget.policy.edit.pdfSelectionAndMerge',
          mergeButton: 'customer.documentsWidget.policy.edit.mergeButton',
          uploadButton: 'customer.documentsWidget.policy.edit.uploadButton',
        },
      },
      basket: {
        edit: {
          widget: 'customer.documentsWidget.basket.edit.widget',
          pdfSelectionAndMerge: 'customer.documentsWidget.basket.edit.pdfSelectionAndMerge',
          mergeButton: 'customer.documentsWidget.basket.edit.mergeButton',
          uploadButton: 'customer.documentsWidget.basket.edit.uploadButton',
        },
      },
    },
    createCustomerDetail: {
      customUiWidget: 'customer.createCustomerDetail.customUiWidget',
    },
    createOpportunity: {
      agentSalesFlowWidget: 'customer.createOpportunity.agentSalesFlowWidget',
      opportunityBasket: 'customer.createOpportunity.opportunityBasket',
      customerDetail: 'customer.createOpportunity.customerDetail',
      createPaymentDetail: 'customer.createOpportunity.createPaymentDetail',
      policies: 'customer.createOpportunity.policies',
      searchClauses: 'customer.createOpportunity.searchClauses',
      riskToggleOnLeft: 'customer.createOpportunity.riskToggleOnLeft',
      datepicker: 'customer.createOpportunity.datepicker',
      basket: {
        setBasketInForce: 'customer.createOpportunity.basket.setBasketInForce',
        setInForce: 'customer.createOpportunity.basket.setInForce',
        print: 'customer.createOpportunity.basket.print',
        printAgent: 'customer.createOpportunity.basket.printAgent',
        printAgentFirm: 'customer.createOpportunity.basket.printAgentFirm',
        printCustomer: 'customer.createOpportunity.basket.printCustomer',
        bundleDiscount: 'customer.createOpportunity.basket.bundleDiscount',
        confirmOffer: 'customer.createOpportunity.basket.confirmOffer',
        duplicate: 'customer.createOpportunity.basket.duplicate',
        cancel: 'customer.createOpportunity.basket.cancel',
      },
    },
    editOpportunity: {
      agentSalesFlowWidget: 'customer.editOpportunity.agentSalesFlowWidget',
      opportunityBasket: 'customer.editOpportunity.opportunityBasket',
      customerDetail: 'customer.editOpportunity.customerDetail',
      createPaymentDetail: 'customer.editOpportunity.createPaymentDetail',
      searchClauses: 'customer.editOpportunity.searchClauses',
      riskToggleOnLeft: 'customer.editOpportunity.riskToggleOnLeft',
      datepicker: 'customer.editOpportunity.datepicker',
      basket: {
        setBasketInForce: 'customer.editOpportunity.basket.setBasketInForce',
        setInForce: 'customer.editOpportunity.basket.setInForce',
        print: 'customer.editOpportunity.basket.print',
        printAgent: 'customer.editOpportunity.basket.printAgent',
        printAgentFirm: 'customer.editOpportunity.basket.printAgentFirm',
        printCustomer: 'customer.editOpportunity.basket.printCustomer',
        bundleDiscount: 'customer.editOpportunity.basket.bundleDiscount',
        confirmOffer: 'customer.editOpportunity.basket.confirmOffer',
        duplicate: 'customer.editOpportunity.basket.duplicate',
        cancel: 'customer.editOpportunity.basket.cancel',
      },
    },
    policyView: {
      customerDetail: 'customer.policyView.customerDetail',
      createPaymentDetail: 'customer.policyView.createPaymentDetail',
      viewPolicyTimeline: 'customer.policyView.viewPolicyTimeline',
      agentSalesFlowWidget: 'customer.policyView.agentSalesFlowWidget',
      showQuoteOnPolicyButtonSalesWidget: 'customer.policyView.showQuoteOnPolicyButtonSalesWidget',
      showQuoteOnRenewalButtonSalesWidget: 'customer.policyView.showQuoteOnRenewalButtonSalesWidget',
      showEditPolicyButtonSalesWidget: 'customer.policyView.showEditPolicyButtonSalesWidget',
      showNewClaimButton: 'customer.policyView.showNewClaimButton',
      searchClauses: 'customer.policyView.searchClauses',
      riskToggleOnLeft: 'customer.policyView.riskToggleOnLeft',
    },
    editQuote: {
      agentSalesFlowWidget: 'customer.editQuote.agentSalesFlowWidget',
      customerDetail: 'customer.editQuote.customerDetail',
      createPaymentDetail: 'customer.editQuote.createPaymentDetail',
      searchClauses: 'customer.editQuote.searchClauses',
      riskToggleOnLeft: 'customer.editQuote.riskToggleOnLeft',
      datepicker: 'customer.editQuote.datepicker',

    },
    editCustomerDetails: {
      customUiWidget: 'customer.editCustomerDetails.customUiWidget',
    },
    createQuote: {
      agentSalesFlowWidget: 'customer.createQuote.agentSalesFlowWidget',
      customerDetail: 'customer.createQuote.customerDetail',
      createPaymentDetail: 'customer.createQuote.createPaymentDetail',
      policies: 'customer.createQuote.policies',
      searchClauses: 'customer.createQuote.searchClauses',
      riskToggleOnLeft: 'customer.createQuote.riskToggleOnLeft',
      datepicker: 'customer.createQuote.datepicker',
    },
    customerView: {
      customerDetail: 'customer.customerView.customerDetail',
      createPaymentDetail: 'customer.customerView.createPaymentDetail',
      policies: 'customer.customerView.policies',
      quotes: 'customer.customerView.quotes',
      newQuoteButton: 'customer.customerView.newQuoteButton',
      opportunities: 'customer.customerView.opportunities',
      opportunityPolicyActions: {
        setInForce: 'customer.customerView.opportunityPolicyActions.setInForce',
        print: 'customer.customerView.opportunityPolicyActions.print',
        printAgent: 'customer.customerView.opportunityPolicyActions.printAgent',
        printAgentFirm: 'customer.customerView.opportunityPolicyActions.printAgentFirm',
        printCustomer: 'customer.customerView.opportunityPolicyActions.printCustomer',
        addNoteToQuote: 'customer.customerView.opportunityPolicyActions.addNoteToQuote',
      },
      policyActions: {
        view: 'customer.customerView.policyActions.view',
        cancel: 'customer.customerView.policyActions.cancel',
        addNoteToPolicy: 'customer.customerView.policyActions.addNoteToPolicy',
      },
      quoteActions: {
        addNoteToQuote: 'customer.customerView.quoteActions.addNoteToQuote',
        edit: 'customer.customerView.quoteActions.edit',
        cancel: 'customer.customerView.quoteActions.cancel',
        cancelQuoteOnPolicy: 'customer.customerView.quoteActions.cancelQuoteOnPolicy',
        cancelQuoteOnRenewal: 'customer.customerView.quoteActions.cancelQuoteOnRenewal',
        sendTo: 'customer.customerView.quoteActions.sendTo',
        sendToAgent: 'customer.customerView.quoteActions.sendToAgent',
        sendToAgentFirm: 'customer.customerView.quoteActions.sendToAgentFirm',
        sendToCustomer: 'customer.customerView.quoteActions.sendToCustomer',
        setInForce: 'customer.customerView.quoteActions.setInForce',
        acceptQuoteOnPolicy: 'customer.customerView.quoteActions.acceptQuoteOnPolicy',
        acceptQuoteOnRenewal: 'customer.customerView.quoteActions.acceptQuoteOnRenewal',
      },
      opportunityActions: {
        print: 'customer.customerView.opportunityActions.print',
        printAgent: 'customer.customerView.opportunityActions.printAgent',
        printAgentFirm: 'customer.customerView.opportunityActions.printAgentFirm',
        printCustomer: 'customer.customerView.opportunityActions.printCustomer',
        setInForce: 'customer.customerView.opportunityActions.setInForce',
      },
      viewRelations: 'customer.customerView.viewRelations',
      paymentTab: 'customer.customerView.paymentTab',
    },
  },
  customers: {
    customersView: {
      recentCustomersWidget: 'customers.customersView.recentCustomersWidget',
      partyTable: 'customers.customersView.partyTable',
    },
  },
  crm: {
    crmView: {
      crmWidget: 'crm.crmView.crmWidget',
    },
  },
};
